import { z } from "zod";

export const CustomerLeadSchema = z
  .object({
    segmentId: z.string().min(1),
    email: z.string().nullable().optional(),
    firstName: z.string().nullable().optional(),
    lastName: z.string().nullable().optional(),
    leadSource: z.string().nullable().optional(),
    createdTs: z.string().transform((str) => new Date(str)),
    updatedTs: z
      .string()
      .nullable()
      .optional()
      .transform((str) => (str ? new Date(str) : null)),
  })
  .strict();

export class InvalidCustomerLeadError extends Error {
  constructor(public errors: z.ZodError) {
    super("Invalid CustomerLead");
    this.name = "InvalidCustomerLeadError";
  }
}

export class EmptyCustomerLeadError extends Error {
  constructor() {
    super("Empty CustomerLead");
    this.name = "EmptyCustomerLeadError";
  }
}
