import * as React from "react";
import * as Avatar from "@radix-ui/react-avatar";
import { cn } from "../utils";
const AvatarRoot = React.forwardRef<
  React.ElementRef<typeof Avatar.Root>,
  React.ComponentPropsWithoutRef<typeof Avatar.Root>
>(({ className, ...props }, ref) => (
  <Avatar.Root ref={ref} className={cn("rounded-full", className)} {...props} />
));

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof Avatar.Image>,
  React.ComponentPropsWithoutRef<typeof Avatar.Image>
>(({ className, ...props }, ref) => (
  <Avatar.Image
    ref={ref}
    className={cn("rounded-full", className)}
    {...props}
  />
));
const AvatarFallback = Avatar.Fallback;

export { AvatarRoot, AvatarImage, AvatarFallback };
