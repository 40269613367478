import React from "react";
import { cn } from "@yardzen/next-client-util";

export const H3: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({
  children,
  className,
  ...props
}) => (
  <h3
    className={cn(
      "font-display scroll-m-20 text-2xl tracking-tight",
      className,
    )}
    {...props}
  >
    {children}
  </h3>
);
