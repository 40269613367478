// Got these prices from https://docs.google.com/spreadsheets/d/1XVR3meIa-Xt2yw921x90dRxQ0FGdmoyLlThFjxwh_IE/edit?gid=1809411541#gid=1809411541
// Added 0s for converting to cents

type CostPercentiles = {
  cost_25p: number;
  cost_50p: number;
  cost_75p: number;
};

export const hasuraProjectElementsCostsMap: Record<string, CostPercentiles> = {
  "BBQ Area": { cost_25p: 54995, cost_50p: 59999, cost_75p: 99900 },
  Deck: { cost_25p: 1728000, cost_50p: 2916000, cost_75p: 4924800 },
  Fencing: { cost_25p: 300000, cost_50p: 738000, cost_75p: 1700000 },
  "Fire Pit": { cost_25p: 134700, cost_50p: 400000, cost_75p: 752000 },
  Fountain: { cost_25p: 232900, cost_50p: 366500, cost_75p: 479500 },
  "Hot Tub": { cost_25p: 929999, cost_50p: 1149900, cost_75p: 1249900 },
  Other: { cost_25p: 706575, cost_50p: 1575595, cost_75p: 3240933 },
  "Outdoor Kitchen": { cost_25p: 807275, cost_50p: 1372600, cost_75p: 2000000 },
  "Outdoor Lighting": { cost_25p: 189875, cost_50p: 376487, cost_75p: 646793 },
  "Outdoor Play Area": { cost_25p: 89900, cost_50p: 209500, cost_75p: 369790 },
  "Outdoor Shower": { cost_25p: 600000, cost_50p: 896800, cost_75p: 1000000 },
  Pathways: { cost_25p: 1069800, cost_50p: 2263000, cost_75p: 4386000 },
  Driveway: { cost_25p: 1069800, cost_50p: 2263000, cost_75p: 4386000 },
  Patio: { cost_25p: 356400, cost_50p: 836000, cost_75p: 1777600 },
  Pergola: { cost_25p: 599900, cost_50p: 1123900, cost_75p: 1749900 },
  Planting: { cost_25p: 618700, cost_50p: 1177800, cost_75p: 2158525 },
  Pool: { cost_25p: 6000000, cost_50p: 8000000, cost_75p: 15000000 },
  "Retaining Wall": { cost_25p: 630000, cost_50p: 1215000, cost_75p: 2430000 },
  Roofing: { cost_25p: 68600, cost_50p: 359900, cost_75p: 1000000 },
  Trees: { cost_25p: 25500, cost_50p: 45000, cost_75p: 95500 },
  "Turf Installation": {
    cost_25p: 418200,
    cost_50p: 828000,
    cost_75p: 1624000,
  },
};
