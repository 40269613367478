import { useState, useEffect } from "react";

export function useCountdown(endTime?: number) {
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    const calculateTimeLeft = () => {
      if (!endTime) return;

      const now = Date.now();
      const endDate = endTime * 1000; // Convert to milliseconds
      const distance = endDate - now;

      if (distance <= 0) {
        setCountdown("Expired");
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setCountdown(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    };

    calculateTimeLeft();
    const interval = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(interval);
  }, [endTime]);

  return countdown;
}
