import { getOrCreateSurveyResponse } from "@yardzen/rest-api-client";
import useSWR from "swr";

interface UseSurveyResponseProps {
  buildProjectId: string;
  accountId?: string;
  surveyId?: string;
  userId?: string;
}

export const useSurveyResponse = ({
  buildProjectId,
  accountId,
  surveyId,
  userId,
}: UseSurveyResponseProps) => {
  const { data, error, isLoading } = useSWR(
    // Only fetch if both buildProjectId and accountId are present
    accountId && surveyId && userId
      ? ["survey-responses", accountId, buildProjectId, surveyId]
      : null,
    accountId && surveyId && userId
      ? () =>
          getOrCreateSurveyResponse(accountId, surveyId, {
            buildProjectId,
            userId,
          })
      : null,
  );

  return {
    surveyResponse: data?.data,
    isLoading,
    isError: error,
  };
};
