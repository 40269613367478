"use client";

import * as TogglePrimitive from "@radix-ui/react-toggle";
import { cva, type VariantProps } from "class-variance-authority";
import React from "react";
import { cn } from "../utils";

const toggleVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm transition-colors focus-visible:outline-none focus-visible:ring-2 disabled:pointer-events-none [&_svg]:h-4 [&_svg]:w-4",
  {
    variants: {
      variant: {
        default: cn(
          "bg-brand-moss-greens-20 text-brand-secondary-dark",
          "hover:bg-[#D6F18B]",
          "focus:bg-[#C2DD77]",
          "disabled:bg-[#BFC2AA] disabled:text-brand-primary-dark",
          "data-[state=on]:bg-brand-moss-greens-20 data-[state=on]:text-brand-secondary-dark",
        ),
      },
      size: {
        default: "h-10 px-4",
        sm: "h-8 px-3",
        lg: "h-12 px-6",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export type ToggleProps = React.ComponentPropsWithoutRef<
  typeof TogglePrimitive.Root
> &
  VariantProps<typeof toggleVariants> & {
    asChild?: boolean;
  };

export const Toggle = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  ToggleProps
>(({ className, variant, size, ...props }, ref) => (
  <TogglePrimitive.Root
    ref={ref}
    className={cn(toggleVariants({ variant, size, className }))}
    {...props}
  />
));

Toggle.displayName = TogglePrimitive.Root.displayName;

export { toggleVariants };
