import { IsNotEmpty, validateSync, IsDate } from "class-validator";
import { Expose, plainToInstance, Type } from "class-transformer";
import { IsAfter } from "../../utils/isAfter";

class AvailableSlot {
  @Expose()
  @IsDate()
  @IsNotEmpty()
  @Type(() => Date)
  startTime: Date;

  @Expose()
  @IsDate()
  @IsNotEmpty()
  @Type(() => Date)
  @IsAfter("startTime", { message: "endTime must be after startTime" })
  endTime: Date;

  public static from(availableSlot: AvailableSlot): AvailableSlot {
    const instance = plainToInstance(AvailableSlot, availableSlot, {
      excludeExtraneousValues: true,
      enableCircularCheck: true,
    });

    const errors = validateSync(instance);
    if (errors.length > 0) {
      throw new Error(
        `Validation failed: ${errors.map((error) => Object.values(error.constraints || {})).join(", ")}`,
      );
    }

    return instance;
  }
}

export class AvailableSlots {
  [dateString: string]: AvailableSlot[];

  public static from(data: Record<string, AvailableSlot[]>): AvailableSlots {
    const availableSlots = new AvailableSlots();

    for (const [dateString, slots] of Object.entries(data)) {
      if (!dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
        throw new Error(`Invalid date string: ${dateString}`);
      }

      availableSlots[dateString] = slots.map((slot) =>
        AvailableSlot.from(slot),
      );
    }

    return availableSlots;
  }
}
