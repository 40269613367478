import {
  IsArray,
  IsBoolean,
  IsNotEmpty,
  IsOptional,
  IsString,
  validateSync,
} from "class-validator";
import { Expose, plainToInstance } from "class-transformer";
import { YardzenLegacyWebRole, YardzenLegacyRoles } from "../types";

export class AuthUserEntity {
  public static from(authUser: AuthUserEntity): AuthUserEntity {
    const instance = plainToInstance(AuthUserEntity, authUser, {
      excludeExtraneousValues: true,
      enableCircularCheck: true,
    });

    const errors = validateSync(instance);
    if (errors.length > 0) {
      throw new Error(
        `Validation failed: ${errors.map((error) => Object.values(error.constraints || {})).join(", ")}`,
      );
    }

    return instance;
  }

  @Expose()
  @IsString()
  @IsNotEmpty()
  id!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  email!: string;

  @Expose()
  @IsString()
  @IsOptional()
  name?: string | null;

  @Expose()
  @IsString()
  @IsNotEmpty()
  accountId!: string;

  @Expose()
  @IsArray()
  @IsOptional()
  roles: (YardzenLegacyWebRole | YardzenLegacyRoles)[] = [];

  @Expose()
  @IsBoolean()
  @IsOptional()
  testAccount?: boolean;
}
