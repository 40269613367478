import { faMedal, faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AvatarFallback, AvatarImage, AvatarRoot } from "./Avatar";
import { Badge } from "./Badge";
import { H3 } from "./typography/H3";
import { P } from "./typography/P";
import { B } from "./typography/B";

export interface ContractorCardHeaderProps {
  contractorThumbnailUrl: string;
  contractorHeadshotUrl: string;
  contractorBusinessName: string;
  customerCity?: string;
  customerState?: string;
  isSuperPro?: boolean;
  contractorRating?: number;
  contractorReviewLength?: number;
  contractorFirstName?: string;
  contractorLastName?: string;
}

export function ContractorCardHeader({
  contractorThumbnailUrl,
  contractorHeadshotUrl,
  contractorBusinessName,
  customerCity,
  customerState,
  isSuperPro,
  contractorRating,
  contractorReviewLength,
  contractorFirstName,
  contractorLastName,
}: ContractorCardHeaderProps) {
  const hasRating = contractorRating !== undefined;
  const hasReviews = (contractorReviewLength ?? 0) > 0;
  return (
    <div className="flex h-[110px] flex-row items-center">
      <div className="flex flex-row items-center gap-4">
        <AvatarRoot>
          <AvatarImage
            className="max-h-[70px] w-[70px] max-w-[70px] rounded-lg object-contain"
            src={
              contractorHeadshotUrl && contractorHeadshotUrl !== ""
                ? contractorHeadshotUrl
                : contractorThumbnailUrl
            }
            alt="Avatar"
          />
          <AvatarFallback className="bg-brand-primary-light flex h-[70px] w-[70px] items-center justify-center rounded-lg text-xl">
            {contractorBusinessName.slice(0, 1).toUpperCase()}
          </AvatarFallback>
        </AvatarRoot>
        <div className="flex flex-col">
          <H3>{contractorBusinessName}</H3>
          {(contractorFirstName || contractorLastName) && (
            <P className="text-sm" noSpacing>
              <B>
                {contractorFirstName} {contractorLastName}
              </B>
            </P>
          )}
          <P className="text-system-grayscale-50 text-sm" noSpacing>
            Serves {customerCity}, {customerState}
          </P>
          <div className="mt-2 flex flex-row items-center gap-2">
            {isSuperPro && (
              <Badge
                color="primary"
                hover={false}
                className="flex flex-row items-center gap-1 rounded-md px-2 py-1"
              >
                <FontAwesomeIcon icon={faMedal} />
                <P noSpacing className="text-xs leading-none">
                  Super Pro
                </P>
              </Badge>
            )}
            {hasRating && hasReviews && (
              <div className="flex flex-row items-center gap-1">
                <FontAwesomeIcon icon={faStar} size="xs" />
                <P noSpacing className="text-xs leading-none">
                  {contractorRating?.toFixed(1)} ({contractorReviewLength}{" "}
                  Reviews)
                </P>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
