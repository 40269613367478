import { AxiosResponse } from "axios";
import { apiClient } from "../base-client";
import { CreateSiteWalkDto, SiteWalkPublic } from "@yardzen/api-dtos";

const baseUrl = "accounts";

export const createSiteWalk = (
  accountId: string,
  buildProjectId: string,
  body: CreateSiteWalkDto,
): Promise<AxiosResponse<void>> => {
  return apiClient.post(
    `${baseUrl}/${accountId}/build-projects/${buildProjectId}/site-walks`,
    body,
  );
};

export const getSiteWalkPublic = async (
  siteWalkId: string,
  buildProjectId: string,
): Promise<AxiosResponse<SiteWalkPublic>> => {
  const response = await apiClient.get(
    `public/build-projects/${buildProjectId}/site-walks/${siteWalkId}`,
  );

  return {
    ...response,
    data: SiteWalkPublic.from(response.data),
  };
};

export const cancelSiteWalk = async (
  buildProjectId: string,
  siteWalkId: string,
): Promise<AxiosResponse<void>> => {
  return apiClient.post(
    `public/build-projects/${buildProjectId}/site-walks/${siteWalkId}/cancel`,
  );
};
