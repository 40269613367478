import useSWR from "swr";
import { getConversationBy } from "@yardzen/rest-api-client";
import { FindConversationDto } from "@yardzen/api-dtos";

export const useConversations = (
  accountId: string,
  query?: FindConversationDto,
) => {
  const cacheKey = accountId ? ["/conversations", accountId, query] : null;

  const { data, error, isLoading } = useSWR(cacheKey, () =>
    getConversationBy(accountId, query),
  );

  return {
    conversation: data?.data,
    isLoading,
    isError: error,
  };
};
