import { Message } from "@yardzen/api-dtos";
import { AxiosResponse } from "axios";
import { apiClient } from "../base-client";
import { CreateMessageDto } from "@yardzen/api-dtos";

const baseUrl = "accounts";

export async function createMessage(
  accountId: string,
  conversationId: string,
  body: CreateMessageDto,
): Promise<AxiosResponse<Message>> {
  return apiClient.post(
    `${baseUrl}/${accountId}/conversations/${conversationId}/messages`,
    body,
  );
}
