import { SegmentFlows } from "@yardzen/next-client-util";
import { cn } from "../../utils";
import { Link } from "../Link";

interface AnimatedLinkProps {
  href: string;
  label: string;
  className?: string;
}

export function AnimatedLink({ href, label, className }: AnimatedLinkProps) {
  return (
    <Link
      href={href}
      className={cn(
        "hover:text-brand-primary-darker focus:text-brand-primary-darker group relative flex items-center p-0 font-semibold text-gray-900 no-underline transition-colors duration-300 hover:no-underline focus:outline-none",
        className,
      )}
      tracking={{
        button_name: label,
        button_content: label,
        flow_name: SegmentFlows.TOP_NAV,
      }}
      aria-label={`Navigate to ${label}`}
    >
      <span className="relative">
        {label}
        <span className="bg-brand-primary-darker absolute bottom-[-2px] left-0 h-[1px] w-0 transition-all duration-300 ease-out group-hover:w-full group-focus:w-full"></span>
      </span>
    </Link>
  );
}
