import React from "react";

const Ellipsis = () => {
  return (
    <div className="flex flex-row gap-2">
      <div
        className="bg-system-grayscale-100 animate-wave h-[5px] w-[5px] rounded-full"
        style={{ animationDelay: "0s" }}
      ></div>
      <div
        className="bg-system-grayscale-100 animate-wave h-[5px] w-[5px] rounded-full"
        style={{ animationDelay: "0.2s" }}
      ></div>
      <div
        className="bg-system-grayscale-100 animate-wave h-[5px] w-[5px] rounded-full"
        style={{ animationDelay: "0.4s" }}
      ></div>
    </div>
  );
};

export { Ellipsis };
