import { Expose, plainToInstance } from "class-transformer";
import {
  IsBoolean,
  IsDate,
  IsJSON,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
} from "class-validator";

export class SurveyQuestion {
  public static from(surveyQuestion: SurveyQuestion) {
    return plainToInstance(SurveyQuestion, surveyQuestion, {
      excludeExtraneousValues: true,
    });
  }

  @Expose()
  @IsString()
  @IsNotEmpty()
  id!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  text!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  questionKey!: string;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  section!: number;

  @Expose()
  @IsNumber()
  @IsNotEmpty()
  index!: number;

  @Expose()
  @IsJSON()
  @IsNotEmpty()
  format!: string;

  @Expose()
  @IsBoolean()
  @IsNotEmpty()
  required!: boolean;

  @Expose()
  @IsString()
  @IsNotEmpty()
  surveyId!: string;

  @Expose()
  @IsString()
  @IsOptional()
  description?: string | null;

  @Expose()
  @IsDate()
  createdTs!: Date;

  @Expose()
  @IsDate()
  updatedTs?: Date | null;
}
