import { forwardRef } from "react";
import { cn } from "@yardzen/next-client-util";
import { cva, type VariantProps } from "class-variance-authority";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-light-svg-icons/faPencil";

const companyLogoVariants = cva("relative", {
  variants: {
    variant: {
      default:
        "flex flex-shrink-0 items-center justify-center overflow-hidden rounded-full rounded-md border border-solid border-[primary-light] shadow-sm",
      editable: "transition-opacity hover:opacity-70 cursor-pointer",
    },
    size: {
      default: "w-[50px] h-[50px]",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});

type CompanyLogoProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof companyLogoVariants> & {
    fallbackName: string;
    alt: string;
    thumbnailUrl?: string;
    className?: string;
    size?: string;
    darkText?: boolean;
    variant?: string;
    editable?: boolean;
    onEdit?: () => void;
  };

export const CompanyLogo = forwardRef<HTMLDivElement, CompanyLogoProps>(
  (
    {
      fallbackName,
      alt,
      thumbnailUrl,
      className,
      size,
      darkText,
      variant,
      editable,
      onEdit,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        className={cn(
          companyLogoVariants({
            variant,
            size,
          }),
          `${thumbnailUrl ? "bg-white" : ""}`,
          className,
        )}
      >
        {thumbnailUrl && thumbnailUrl.length > 0 ? (
          <img
            className="h-auto w-full object-cover"
            src={thumbnailUrl}
            alt={alt}
          />
        ) : (
          <span className={darkText ? "text-black" : "text-white"}>
            {fallbackName}
          </span>
        )}
        {editable && (
          <div
            className="absolute right-0 top-0 m-0.5 rounded-sm bg-gray-300 px-0.5 opacity-70"
            onClick={onEdit}
          >
            <FontAwesomeIcon icon={faPencil} className="text-black" size="xs" />
          </div>
        )}
      </div>
    );
  },
);
CompanyLogo.displayName = "CompanyLogo";

export { companyLogoVariants };
