import {
  ValidationArguments,
  ValidationOptions,
  registerDecorator,
} from "class-validator";

export function IsAfter(
  property: string,
  validationOptions?: ValidationOptions,
) {
  return function (object: object, propertyName: string) {
    registerDecorator({
      name: "isAfter",
      target: object.constructor,
      propertyName: propertyName,
      constraints: [property],
      options: {
        message: `$property must be after ${property}`,
        ...validationOptions,
      },
      validator: {
        validate(value: Date, args: ValidationArguments) {
          const [relatedPropertyName] = args.constraints;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const relatedValue = (args.object as any)[
            relatedPropertyName
          ] as Date;
          return relatedValue && value && value > relatedValue;
        },
      },
    });
  };
}
