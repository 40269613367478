export enum SegmentClickTypes {
  BUTTON = "Button",
  NAV = "Nav",
  CONTACT = "Contact",
  FOOTER = "Footer",
  CAROUSEL = "Carousel",
  HYPERLINK = "Hyperlink",
}

export enum SegmentInputTypes {
  TEXT = "Text",
  SELECT = "Select",
  MULTI_SELECT = "Multi Select",
  RADIO_GROUP = "Radio Group",
  CHECKBOX = "Checkbox",
}

export enum SegmentFlowNames {
  BUILD_WITH_YARDZEN = "Build with Yardzen",
  PACKAGES = "Shop Packages",
  PASSWORD_RESET = "Password Reset",
  DEFAULT = "default",
}
