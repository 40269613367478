import {
  Conversation,
  CreateConversationInput,
  FindConversationDto,
} from "@yardzen/api-dtos";
import { AxiosResponse } from "axios";
import { apiClient } from "../base-client";

const baseUrl = "accounts";

export async function createConversation(
  accountId: string,
  body: CreateConversationInput,
): Promise<AxiosResponse<Conversation>> {
  return apiClient.post(`${baseUrl}/${accountId}/conversations`, body);
}

export async function getConversationBy(
  accountId: string,
  query?: FindConversationDto,
): Promise<AxiosResponse<Conversation>> {
  return apiClient.get(`${baseUrl}/${accountId}/conversations/find-by`, {
    params: query,
  });
}
