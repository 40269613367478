import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn } from "../utils";
import { Icon, IconName } from "./Icon";

const alertVariants = cva("", {
  variants: {
    variant: {
      tip: "",
      alert: "",
      default: "",
    },
    layout: {
      row: "flex-row",
      col: "flex-col",
    },
  },
  defaultVariants: {
    variant: "tip",
    layout: "row",
  },
});

type AlertProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof alertVariants> & {
    asChild?: boolean;
    customIcon?: string;
  };

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  (
    {
      children,
      className,
      onClick,
      variant,
      layout,
      asChild = false,
      customIcon,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "div";
    const useIcon = customIcon
      ? customIcon
      : variant === "tip"
        ? "stars"
        : "circle-info";
    const layoutClass = layout ? `flex-${layout}` : "flex-row";
    const justifyClass =
      layoutClass === "flex-col" ? "justify-center" : "justify-start";
    const displayIcon = variant === "default" ? false : true;
    const textClass = layout === "col" ? "text-center" : "text-left";
    return (
      <Comp
        ref={ref}
        className={cn(className, `flex ${justifyClass}`)}
        {...props}
      >
        <div className="border-brand-primary-lighter rounded-md border-2 border-dotted p-4">
          <div className={`flex ${layoutClass} items-center`}>
            {!!displayIcon && (
              <div
                className={`pr-4 ${layoutClass === "flex-col" ? "pb-2" : "pb-0"}`}
              >
                <Icon
                  name={useIcon as IconName}
                  className="text-brand-brown-black h-5 w-5 sm:h-6 sm:w-6"
                />
              </div>
            )}
            <div
              className={`font-display text-brand-brown-black sm:text-md text-sm ${textClass}`}
            >
              {children}
            </div>
          </div>
        </div>
      </Comp>
    );
  },
);
Alert.displayName = "Alert";
