import { Expose, plainToInstance } from "class-transformer";
import { IsDate, IsNotEmpty, IsOptional, IsString } from "class-validator";

export class CustomerLead {
  public static from(customerLead: CustomerLead) {
    return plainToInstance(CustomerLead, customerLead, {
      excludeExtraneousValues: true,
    });
  }

  @Expose()
  @IsString()
  @IsNotEmpty()
  id: string;

  @Expose()
  @IsString()
  @IsOptional()
  email?: string;

  @Expose()
  @IsString()
  @IsOptional()
  firstName?: string;

  @Expose()
  @IsString()
  @IsOptional()
  lastName?: string;

  @Expose()
  @IsString()
  @IsOptional()
  leadSource?: string;

  @Expose()
  @IsString()
  @IsOptional()
  segmentId?: string;

  @Expose()
  @IsDate()
  createdTs: Date;

  @Expose()
  @IsDate()
  updatedTs?: Date;
}
