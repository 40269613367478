import {
  EmptySurveyResponseError,
  InvalidSurveyResponseError,
  SurveySchema,
} from "@yardzen/api-dtos";
import { AxiosError } from "axios";
import { z } from "zod";
import { apiClient } from "../base-client";

/**
 * Fetches a survey by its key
 * @param surveyKey - The unique key of the survey
 * @param params - Optional query parameters
 * @param isPublic - Whether to fetch the public version of the survey
 * @returns The survey data
 * @throws {InvalidSurveyResponseError} When the response doesn't match the expected schema
 * @throws {EmptySurveyResponseError} When the response is missing data
 */
export async function getSurvey(
  surveyKey: string,
  params?: Record<string, string>,
  isPublic = false,
) {
  const queryString = buildQueryString(params);

  try {
    const response = await apiClient.get(
      `survey/${surveyKey}${queryString}${isPublic ? "/public" : ""}`,
    );

    if (!response.data) {
      throw new EmptySurveyResponseError();
    }

    return validateSurveyData(response.data, surveyKey);
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 404) {
      throw new EmptySurveyResponseError();
    }
    throw error;
  }
}

function buildQueryString(params?: Record<string, string>): string {
  if (!params) return "";

  return (
    "?" +
    Object.entries(params)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&")
  );
}

function validateSurveyData(data: unknown, surveyKey: string) {
  try {
    return SurveySchema.parse(data);
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error(
        `Invalid survey for ${surveyKey}:`,
        JSON.stringify(data, null, 2),
      );
      throw new InvalidSurveyResponseError(error);
    }
    throw error;
  }
}
