import { cn } from "../utils";
import { Icon, isIconName } from "./Icon";
import { P } from "./typography/P";

export function ListItem({
  iconName,
  heading,
  subheading,
  className,
  headingClassName,
  subHeadingClassName,
  iconWrapperClassName,
  iconClassName,
  iconSize,
}: {
  iconName?: string;
  heading?: string;
  subheading?: string;
  className?: string;
  headingClassName?: string;
  subHeadingClassName?: string;
  iconWrapperClassName?: string;
  iconClassName?: string;
  iconSize?: "sm" | "md" | "lg";
}) {
  return (
    <div
      className={cn(
        className ? className : "mb-2 flex flex-row items-center gap-2",
      )}
    >
      {iconName && isIconName(iconName) && (
        <div
          className={cn(
            "bg-brand-moss-greens-10 flex h-fit w-fit shrink-0 items-center rounded-full p-2",
            iconWrapperClassName,
          )}
        >
          <Icon
            name={iconName}
            className={cn(
              "text-brand-primary-regular h-[22px] w-[22px]",
              iconClassName,
            )}
            size={iconSize}
          />
        </div>
      )}

      <div className="flex flex-col text-left">
        {heading && (
          <P
            className={cn(
              headingClassName
                ? headingClassName
                : "text-brand-secondary-dark text-xs leading-none md:text-base",
            )}
            noSpacing
          >
            {heading}
          </P>
        )}

        {subheading && (
          <P
            className={cn(
              subHeadingClassName
                ? subHeadingClassName
                : "text-brand-secondary-dark mt-1 text-xs leading-none",
            )}
            noSpacing
          >
            {subheading}
          </P>
        )}
      </div>
    </div>
  );
}
