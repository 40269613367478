import { FindOneContractorProfileDto } from "@yardzen/api-dtos";
import { getContractorProfile } from "@yardzen/rest-api-client";
import useSWR from "swr";

export const useContractorProfile = (
  contractorId: string,
  additionalOptions?: FindOneContractorProfileDto,
) => {
  const { data, error, isLoading } = useSWR(
    contractorId ? `/contractor-profiles/${contractorId}` : null,
    contractorId
      ? () => getContractorProfile(contractorId, additionalOptions)
      : null,
  );

  return {
    contractorProfile: data?.data,
    isLoading,
    isError: error,
  };
};
