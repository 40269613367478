import { forwardRef } from "react";
import { CompanyLogo } from "./CompanyLogo";
import { Eyebrow } from "./Eyebrow";
import { Link } from "./Link";
import { H4 } from "./typography/H4";

import { cn } from "../utils";

type SiteWalkCardProps = React.HTMLAttributes<HTMLDivElement> & {
  businessName: string;
  clientName: string;
  address: string;
  time: string;
  cancelled?: boolean;
  logoSrc?: string;
};

const SiteWalkCard = forwardRef<HTMLDivElement, SiteWalkCardProps>(
  (
    {
      businessName,
      clientName,
      address,
      time,
      logoSrc,
      className,
      cancelled,
      ...props
    },
    ref,
  ) => {
    const encodedAddress = encodeURI(address);
    return (
      <div
        ref={ref}
        className={cn(
          "flex min-w-[362px] flex-col gap-4 rounded-lg bg-white p-4 drop-shadow-md",
          className,
        )}
        {...props}
      >
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-start gap-4">
            <CompanyLogo
              thumbnailUrl={logoSrc ?? ""}
              alt={`${businessName ?? "contractor"} logo`}
              fallbackName={businessName?.[0] ?? ""}
              darkText={true}
            />
            <div className="flex flex-col items-start">
              <H4>
                {businessName} & {clientName}
              </H4>
              <Link
                href={`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`}
                className="text-sm text-gray-500 underline"
              >
                {address}
              </Link>
            </div>
          </div>
          <div>
            <div className="ml-4 flex flex-col justify-center">
              {cancelled ? (
                <Eyebrow variant="destructive" text={"Cancelled"} />
              ) : (
                <Eyebrow text={time} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

SiteWalkCard.displayName = "SiteWalkCard";

export { SiteWalkCard };
