import {
  CreatePipefyBuildStudioPlanCardInput,
  EmptySurveyQuestionResponseError,
  EmptySurveyResponseError,
  InvalidSurveyQuestionError,
  InvalidSurveyQuestionResponseError,
  InvalidSurveyResponseError,
  PackageRecommendationOutput,
  SubmitQuizToPipefyInput,
  SurveyQuestionResponse,
  SurveyQuestionResponseSchema,
  SurveyQuestionSchema,
  SurveyResponse,
  SurveyResponseInput,
  SurveyResponseSchema,
  UpsertSurveyQuestionResponseInput,
  UpsertSurveyQuestionResponsesInput,
  UpsertSurveyQuestionResponsesOutput,
} from "@yardzen/api-dtos";
import { AxiosError, AxiosResponse } from "axios";
import { z } from "zod";
import { apiClient } from "../base-client";

export async function getOrCreateSurveyResponse(
  accountId: string,
  surveyId: string,
  { buildProjectId, userId }: SurveyResponseInput,
): Promise<AxiosResponse<SurveyResponse>> {
  return await apiClient.post(
    `surveys/${surveyId}/survey-responses/findOrCreate`,
    {
      buildProjectId,
      accountId,
      userId,
    },
  );
}

export async function upsertSurveyQuestionResponses(
  accountId: string,
  buildProjectId: string,
  surveyResponseId: string,
  surveyId: string,
  { questionResponses, userId }: UpsertSurveyQuestionResponsesInput,
): Promise<AxiosResponse<SurveyQuestionResponse[]>> {
  return await apiClient.post(
    `surveys/${surveyId}/survey-responses/${surveyResponseId}/survey-question-responses/upsert`,
    { questionResponses, userId, buildProjectId, accountId },
  );
}

export async function findSurveyResponse(
  surveyId: string,
  surveyResponseId: string,
  isPublic = false,
): Promise<AxiosResponse<SurveyResponse>> {
  return await apiClient.get(
    `surveys/${surveyId}/${isPublic ? "public/survey-responses" : "survey-responses"}/${surveyResponseId}`,
  );
}

export async function findRecommendedPackage(
  surveyId: string,
  surveyResponseId: string,
): Promise<AxiosResponse<PackageRecommendationOutput>> {
  return await apiClient.get(
    `surveys/${surveyId}/public/survey-responses/${surveyResponseId}/recommended-package`,
  );
}

export async function createPublicSurveyResponse(
  surveyId: string,
): Promise<AxiosResponse<SurveyResponse>> {
  const surveyResponse = await apiClient.post(
    `surveys/${surveyId}/public/survey-responses`,
  );

  return surveyResponse;
}

export async function updateSurveyResponse(
  surveyId: string,
  surveyResponseId: string,
  input: SurveyResponseInput,
) {
  try {
    const response = await apiClient.put(
      `surveys/${surveyId}/public/survey-responses/${surveyResponseId}`,
      input,
    );

    if (!response.data) {
      throw new EmptySurveyResponseError();
    }

    return validateSurveyResponseData(response.data, surveyId);
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 404) {
      throw new EmptySurveyResponseError();
    }
    throw error;
  }
}

export async function upsertPublicSurveyQuestionResponses(
  surveyId: string,
  surveyResponseId: string,
  questionResponses: UpsertSurveyQuestionResponseInput[],
): Promise<AxiosResponse<UpsertSurveyQuestionResponsesOutput>> {
  const response = await apiClient.post(
    `surveys/${surveyId}/public/survey-responses/${surveyResponseId}/survey-question-responses/upsert`,
    { questionResponses },
  );

  return response;
}

export async function getSurveyQuestionResponse(
  surveyId: string,
  surveyResponseId: string,
  questionId: string,
  isPublic = false,
) {
  try {
    const response = await apiClient.get(
      `surveys/${surveyId}/${isPublic ? "public/survey-responses" : "survey-responses"}/${surveyResponseId}/survey-question/${questionId}`,
      {
        headers: {
          "Cache-Control": "no-store",
        },
      },
    );

    if (!response.data) {
      throw new EmptySurveyQuestionResponseError();
    }

    return validateSurveyQuestionResponseData(response.data, surveyId);
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 404) {
      throw new EmptySurveyQuestionResponseError();
    }
    throw error;
  }
}

export async function findPreviousQuestion(
  surveyId: string,
  surveyResponseId: string,
  surveyQuestionId: string,
) {
  const response = await apiClient.get(
    `surveys/${surveyId}/public/survey-responses/${surveyResponseId}/survey-question/${surveyQuestionId}/previous`,
    {
      headers: {
        "Cache-Control": "no-store",
      },
    },
  );

  if (response.data) return validateSurveyQuestionData(response.data, surveyId);

  return null;
}

export async function findOrCreatePipefyBuildStudioPlanCard(
  surveyId: string,
  accountId: string,
  {
    userId,
    buildProjectId,
    accountManagerEmail,
  }: CreatePipefyBuildStudioPlanCardInput,
): Promise<AxiosResponse> {
  return await apiClient.post(
    `surveys/${surveyId}/survey-responses/find-or-create-pipefy-build-studio-plan-card`,
    {
      accountId,
      userId,
      buildProjectId,
      accountManagerEmail,
    },
  );
}

export async function submitQuizToPipefy(
  surveyId: string,
  accountId: string,
  input: SubmitQuizToPipefyInput,
): Promise<AxiosResponse> {
  return await apiClient.post(
    `surveys/${surveyId}/survey-responses/submit-quiz-to-pipefy`,
    {
      accountId,
      ...input,
    },
  );
}

function validateSurveyResponseData(data: unknown, surveyKey: string) {
  try {
    return SurveyResponseSchema.parse(data);
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error(
        `Invalid survey response for ${surveyKey}:`,
        JSON.stringify(data, null, 2),
      );
      throw new InvalidSurveyResponseError(error);
    }
    throw error;
  }
}

function validateSurveyQuestionResponseData(data: unknown, surveyKey: string) {
  try {
    return SurveyQuestionResponseSchema.parse(data);
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error(
        `Invalid survey question response for ${surveyKey}:`,
        JSON.stringify(data, null, 2),
      );
      throw new InvalidSurveyQuestionResponseError(error);
    }
    throw error;
  }
}

function validateSurveyQuestionData(data: unknown, surveyKey: string) {
  try {
    return SurveyQuestionSchema.parse(data);
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error(
        `Invalid survey question for ${surveyKey}:`,
        JSON.stringify(data, null, 2),
      );
      throw new InvalidSurveyQuestionError(error);
    }
    throw error;
  }
}
