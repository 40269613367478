import { AxiosResponse } from "axios";
import { apiClient } from "../base-client";
import {
  AssignProjectElementsToBuildProjectDto,
  BuildProject,
  CreateBuildProjectDto,
  FindOneBuildProjectDto,
  RemoveProjectElementsFromBuildProjectDto,
  UpdateBuildProjectDto,
  BuildProjectProgress,
} from "@yardzen/api-dtos";

const baseUrl = "accounts";

export const getBuildProject = (
  buildProjectId: string,
  accountId: string,
  additionalOptions?: FindOneBuildProjectDto,
): Promise<AxiosResponse<BuildProject>> => {
  return apiClient.get(
    `${baseUrl}/${accountId}/build-projects/${buildProjectId}`,
    { params: additionalOptions },
  );
};

export async function assignToBuildProject(
  buildProjectId: string,
  accountId: string,
  body: AssignProjectElementsToBuildProjectDto,
): Promise<AxiosResponse<void>> {
  return apiClient.post(
    `${baseUrl}/${accountId}/build-projects/${buildProjectId}/project-elements/assign`,
    body,
  );
}

export async function removeFromBuildProject(
  buildProjectId: string,
  accountId: string,
  body: RemoveProjectElementsFromBuildProjectDto,
): Promise<AxiosResponse<void>> {
  return apiClient.delete(
    `${baseUrl}/${accountId}/build-projects/${buildProjectId}/project-elements/remove`,
    { data: body },
  );
}

export async function updateBuildProject(
  buildProjectId: string,
  accountId: string,
  input: UpdateBuildProjectDto,
): Promise<AxiosResponse<BuildProject>> {
  return apiClient.patch(
    `${baseUrl}/${accountId}/build-projects/${buildProjectId}`,
    input,
  );
}

export async function createBuildProject(
  accountId: string,
  body: CreateBuildProjectDto,
): Promise<AxiosResponse<BuildProject>> {
  return apiClient.post(`${baseUrl}/${accountId}/build-projects`, body);
}

export const getAvailableSteps = (
  accountId: string,
  buildProjectId: string,
): Promise<AxiosResponse<BuildProjectProgress>> => {
  return apiClient.get(
    `${baseUrl}/${accountId}/build-projects/${buildProjectId}/progress`,
  );
};
