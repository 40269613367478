import { Expose, plainToInstance, Type } from "class-transformer";
import {
  IsNotEmpty,
  IsOptional,
  IsString,
  ValidateNested,
} from "class-validator";
import { TimeToBuild } from "../utils/Time.entity";

export class ProjectElement {
  public static from(projectElement: ProjectElement): ProjectElement {
    return plainToInstance(ProjectElement, projectElement, {
      excludeExtraneousValues: true,
      excludePrefixes: ["_"],
    });
  }

  @Expose()
  @IsNotEmpty()
  @IsString()
  id!: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  name!: string;

  @Expose()
  @IsOptional()
  @IsString()
  priceTag?: string | null;

  @Expose()
  @IsOptional()
  @ValidateNested()
  @Type(() => TimeToBuild)
  timeToBuild?: TimeToBuild | null;
}
