import { Expose, plainToInstance } from "class-transformer";
import {
  IsString,
  IsNotEmpty,
  IsDate,
  IsOptional,
  IsBoolean,
} from "class-validator";

export class AccountManager {
  public static from(accountManager: AccountManager) {
    return plainToInstance(AccountManager, accountManager, {
      excludeExtraneousValues: true,
    });
  }

  @Expose()
  @IsString()
  @IsNotEmpty()
  id!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  userId!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  projectManagerId!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  email!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  name?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  consultCallUrl?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  thumbnailMediaId?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  thumbnailMediaUrl?: string;

  @Expose()
  @IsBoolean()
  @IsOptional()
  isActive?: boolean;

  @Expose()
  @IsString()
  @IsOptional()
  slackChannelId?: string;

  @Expose()
  @IsDate()
  createdTs!: Date;

  @Expose()
  @IsDate()
  updatedTs!: Date;
}
