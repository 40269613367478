import { IsBoolean, IsNotEmpty, IsOptional } from "class-validator";
import { IsString } from "class-validator";

export class FindOneSurveyDto {
  @IsString()
  @IsOptional()
  buildProjectId?: string;

  @IsString()
  @IsOptional()
  accountId?: string;

  @IsString()
  @IsNotEmpty()
  surveyKey!: string;

  @IsBoolean()
  @IsOptional()
  isPublic?: boolean;
}
