import { cn } from "@yardzen/next-client-util";
import React from "react";

export const Small: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  children,
  className,
  ...props
}) => (
  <small
    className={cn("font-body text-sm font-medium leading-none", className)}
    {...props}
  >
    {children}
  </small>
);
