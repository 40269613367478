import { Expose, plainToInstance, Type } from "class-transformer";
import {
  IsArray,
  IsBoolean,
  IsDate,
  IsNotEmpty,
  IsString,
  ValidateNested,
} from "class-validator";
import { SurveyQuestion } from "./survey-question.entity";

export class Survey {
  public static from(survey: Survey) {
    return plainToInstance(Survey, survey, {
      excludeExtraneousValues: true,
    });
  }

  @Expose()
  @IsString()
  @IsNotEmpty()
  id!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  surveyKey!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  surveyDesc!: string;

  @Expose()
  @IsBoolean()
  @IsNotEmpty()
  active!: boolean;

  @Expose()
  @IsDate()
  createdTs!: Date;

  @Expose()
  @IsDate()
  updatedTs?: Date | null;

  @Expose()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => SurveyQuestion)
  surveyQuestions: SurveyQuestion[] = [];
}
