import { ConversationRole } from "@prisma/client";
import { Expose, plainToInstance } from "class-transformer";
import { IsEnum, IsNotEmpty, IsOptional, IsString } from "class-validator";

export class ConversationParticipant {
  public static from(conversation: ConversationParticipant) {
    return plainToInstance(ConversationParticipant, conversation, {
      excludeExtraneousValues: true,
    });
  }
  @Expose()
  @IsString()
  @IsNotEmpty()
  id!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  twilioId?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  conversationId!: string;

  @Expose()
  @IsEnum(ConversationRole)
  role!: ConversationRole;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  name?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  userProfileId?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  contractorId?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  logoUrl?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  createdTs!: Date;

  @Expose()
  @IsString()
  @IsNotEmpty()
  updatedTs!: Date;
}
