import React from "react";
import { cn } from "../utils";

interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  width?: string | number;
  height?: string | number;
  shape?: "rectangle" | "circle";
}

function Skeleton({
  className,
  width,
  height,
  shape = "rectangle",
  ...props
}: SkeletonProps) {
  const style: React.CSSProperties = {
    width: width,
    height: height,
  };

  return (
    <div
      className={cn(
        "bg-system-grayscale-20 relative overflow-hidden",
        shape === "circle" ? "rounded-full" : "rounded-md",
        className,
      )}
      style={style}
      {...props}
    >
      <div className="animate-skeleton-pulse from-system-grayscale-20 via-system-grayscale-30 to-system-grayscale-20 absolute inset-0 -translate-x-full bg-gradient-to-r" />
    </div>
  );
}

export { Skeleton };
