import { Expose, plainToInstance } from "class-transformer";
import {
  IsDate,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPositive,
  IsString,
} from "class-validator";
import { ContractorProfile } from "../contractor-profile";

export class Match {
  public static from(
    match: Match & { pipefyDtbCardId?: string; pipefyCardId?: string },
  ) {
    return plainToInstance(
      Match,
      {
        ...match,
        pipefyDtbCardId: match.pipefyDtbCardId ?? match.pipefyCardId,
      },
      {
        excludeExtraneousValues: true,
      },
    );
  }

  @Expose()
  @IsString()
  @IsNotEmpty()
  buildProjectId!: string;

  @Expose()
  @IsDate()
  createdTs!: Date;

  @Expose()
  @IsDate()
  updatedTs!: Date;

  @Expose()
  @IsNumber()
  rank!: number;

  @Expose()
  @IsString()
  @IsNotEmpty()
  contractorId!: string;

  @Expose()
  @IsString()
  @IsOptional()
  pipefyDtbCardId?: string;

  @Expose()
  @IsOptional()
  @IsNumber()
  @IsPositive()
  totalContractValue?: number;

  @Expose()
  @IsOptional()
  contractor?: ContractorProfile;
}
