"use client";

import { TypeBannerSkeleton } from "@contentful/types";
import { faCircleX } from "@fortawesome/pro-light-svg-icons";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons/faArrowRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCountdown } from "@yardzen/hooks";
import { SegmentFlows } from "@yardzen/next-client-util";
import { cva, type VariantProps } from "class-variance-authority";
import { Entry } from "contentful";
import * as React from "react";
import { useEffect, useState } from "react";
import { cn } from "../utils";
import { Button } from "./Button";
import { Link } from "./Link";
import { B } from "./typography/B";
import { Small } from "./typography/Small";
const bannerVariants = cva("", {
  variants: {
    variant: {
      announcement: "bg-brand-tertiary-30",
      promo: "bg-brand-secondary-dark text-white",
    },
  },
});

type BannerProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof bannerVariants> & {
    bannerCopy?: string | null | undefined;
    bannerLink?: string | null | undefined;
    promoCode?: string | null | undefined;
    bannerContent: Entry<
      TypeBannerSkeleton,
      "WITHOUT_UNRESOLVABLE_LINKS",
      string
    > | null;
    onBannerClose: (variant: "announcement" | "promo") => void;
  };

export function Banner({
  promoCode,
  variant,
  bannerContent,
  onBannerClose,
  ...props
}: BannerProps) {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const { code, endTime } = JSON.parse(promoCode || "{}");
  const countdown = useCountdown(endTime);
  const bannerCopy = bannerContent?.fields.copy?.toString() || "";
  const bannerLink = bannerContent?.fields.url?.toString() || "";

  if (!isClient) return null;

  return (
    <div className="sticky top-0 z-50 w-full">
      <Link
        href={variant === "announcement" ? bannerLink : "/packages"}
        id="yz-banner"
        tracking={
          variant === "announcement"
            ? {
                button_content: bannerCopy,
                flow_name: SegmentFlows.BANNER,
                button_name: "Banner Link",
              }
            : {
                button_content: "Welcome Promo Code Banner Link",
                flow_name: SegmentFlows.BANNER,
                button_name: "Welcome Promo Code Banner Link",
              }
        }
      >
        <div
          className={cn(
            bannerVariants({ variant: variant }),
            "flex w-full flex-row items-center justify-center px-12 py-1 text-center ",
          )}
        >
          {variant === "announcement" && (
            <div className="flex flex-row items-center justify-center gap-2 text-center">
              <small>{bannerCopy}</small>
              {bannerLink && bannerCopy && (
                <FontAwesomeIcon icon={faArrowRight} className="h-4 w-4" />
              )}
            </div>
          )}
          {variant === "promo" && (
            <div className="flex flex-col items-center justify-center gap-2 md:flex-row">
              <Small>
                Get your design faster. Use code <B>{code}</B>* - a $395 value.
                Offer expires in
              </Small>
              <div className="h-6 w-28 rounded-md bg-white px-2 text-black">
                <small>{countdown}</small>
              </div>
            </div>
          )}

          <Button
            size="icon"
            variant="ghost"
            className="absolute right-0 mr-0 hover:h-6 sm:mr-4"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onBannerClose(variant ?? "announcement");
            }}
            tracking={{
              button_content: "Close Banner",
              flow_name: SegmentFlows.BANNER,
              button_name: "Close Banner - " + variant,
            }}
          >
            <FontAwesomeIcon
              size="lg"
              icon={faCircleX}
              color={variant === "promo" ? "white" : "black"}
            />
          </Button>
        </div>
      </Link>
    </div>
  );
}
