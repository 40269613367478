import { forwardRef } from "react";
import { CompanyLogo } from "./CompanyLogo";
import { P } from "./typography/P";
import { H3 } from "./typography/H3";

import { cn } from "../utils";

type ContractorReviewCardProps = React.HTMLAttributes<HTMLDivElement> & {
  businessName: string;
  pointOfContact: string;
  location: string;
  logoSrc?: string;
};

const ContractorReviewCard = forwardRef<
  HTMLDivElement,
  ContractorReviewCardProps
>(
  (
    { businessName, pointOfContact, location, logoSrc, className, ...props },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={cn(
          "flex min-w-[362px] flex-col gap-4 rounded-lg bg-white p-4 drop-shadow-md",
          className,
        )}
        {...props}
      >
        <div className="flex flex-row items-start gap-4">
          <CompanyLogo
            thumbnailUrl={logoSrc ?? ""}
            alt={`${businessName ?? "contractor"} logo`}
            fallbackName={businessName?.[0] ?? ""}
            darkText={true}
          />
          <div className="flex flex-col">
            <P variant="subdued">Contractor</P>
            <H3>{businessName}</H3>
          </div>
        </div>
        <div className="flex flex-row items-start gap-8">
          <div className="flex flex-col">
            <P variant="subdued">Point of Contact</P>
            <H3>{pointOfContact}</H3>
          </div>
          <div className="flex flex-col">
            <P variant="subdued">Location</P>
            <H3>{location}</H3>
          </div>
        </div>
      </div>
    );
  },
);

ContractorReviewCard.displayName = "ContractorReviewCard";

export { ContractorReviewCard };
