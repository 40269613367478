"use client";

import { Slot } from "@radix-ui/react-slot";
import { analytics } from "@yardzen/next-segment";
import { ClickedEvent } from "@yardzen/segmentv2";
import { cva, type VariantProps } from "class-variance-authority";
import React from "react";
import { SegmentClickTypes } from "../types";
import { cn } from "../utils";

const buttonVariants = cva(
  [
    "[&_svg]:duration-300",
    "[&_svg]:h-4",
    "[&_svg]:transition-all",
    "[&_svg]:w-4",
    "border",
    "cursor-pointer",
    "disabled:cursor-not-allowed",
    "duration-300",
    "ease-in-out",
    "focus-visible:outline-none",
    "focus-visible:ring-2",
    "focus-visible:ring-douglas-fir",
    "font-medium",
    "gap-2",
    "hover:shadow-[0px_2px_8px_0px_rgba(0,0,0,0.16)]",
    "inline-flex",
    "items-center",
    "justify-center",
    "no-underline",
    "hover:no-underline",
    "px-6",
    "py-2",
    "rounded-full",
    "transition-all",
  ],
  {
    variants: {
      variant: {
        default:
          "bg-brand-primary-dark hover:bg-brand-primary-light focus-visible:bg-brand-primary-light border-transparent text-system-grayscale-00 disabled:bg-brand-primary-dark/50",
        secondary:
          "bg-system-grayscale-00 text-system-grayscale-70 border-transparent hover:bg-system-grayscale-10 active:bg-system-grayscale-30 active:text-system-grayscale-70 disabled:bg-system-grayscale-10 disabled:text-system-grayscale-30",
        outline:
          "border-brand-primary-dark text-brand-primary-dark focus-visible:border-[#3B482F]/30 active:border-brand-primary-dark/70 active:text-brand-primary-dark/70 disabled:border-system-grayscale-30 disabled:text-system-grayscale-30 bg-transparent",
        ghost:
          "text-brand-primary-dark active:text-brand-primary-dark/70 border-none bg-transparent outline-none hover:border hover:bg-system-grayscale-00/30 focus-visible:ring-2 disabled:text-system-grayscale-50 disabled:hover:bg-transparent disabled:hover:border-none",
        destructive:
          "bg-system-detrimental-regular text-system-grayscale-00 hover:bg-system-detrimental-dark border-transparent disabled:bg-system-detrimental-light disabled:hover:bg-system-detrimental-light",
        success:
          "bg-system-success-regular text-system-grayscale-00 hover:bg-system-success-dark border-transparent disabled:bg-system-success-light disabled:hover:bg-system-success-light",
        warning:
          "bg-system-warning-regular text-system-grayscale-00 hover:bg-system-warning-dark border-transparent disabled:bg-system-warning-light disabled:hover:bg-system-warning-light",
        zest: "bg-brand-moss-greens-10 text-brand-primary-darker hover:bg-brand-moss-greens-20 active:bg-brand-moss-greens-20 border-transparent disabled:bg-system-grayscale-10 disabled:text-system-grayscale-30",
      },
      size: {
        default: "min-h-10 px-6 py-2 text-base",
        sm: "h-8 px-4 text-sm",
        lg: "h-12 px-8 text-lg",
        icon: "h-10 w-10 p-2",
        dot: "h-2 w-2 p-0",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    tracking?: Omit<ClickedEvent, "click_type"> & {
      click_type?: SegmentClickTypes;
    };
    waitForTracking?: boolean;
    asChild?: boolean;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
  };

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      onClick,
      waitForTracking,
      tracking,
      variant,
      size,
      asChild = false,
      startIcon,
      endIcon,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
      if (tracking) {
        const promise = analytics.trackClicked({
          ...tracking,
          button_content: tracking.button_content || String(children),
          click_type: tracking.click_type || SegmentClickTypes.BUTTON,
        });
        if (waitForTracking) {
          await promise;
        }
      }
      onClick?.(e);
    };

    return (
      <Comp
        ref={ref}
        onClick={handleClick}
        className={cn(buttonVariants({ variant, size, className }))}
        {...props}
      >
        {asChild ? (
          children
        ) : (
          <>
            {startIcon}
            {children}
            {endIcon}
          </>
        )}
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { buttonVariants };
