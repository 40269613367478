import React, { ComponentProps, forwardRef } from "react";
import { H6 } from "../components/typography/H6";
import { P } from "../components/typography/P";
import { cn } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface Amenity {
  id: string;
  icon: any;
  displayName: string;
}

interface AmenityCardProps extends ComponentProps<"div"> {
  title: string;
  amenities?: Amenity[];
  children?: React.ReactNode;
  className?: string;
}

const AmenityCard = forwardRef<HTMLDivElement, AmenityCardProps>(
  ({ title, amenities, children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        className={cn(
          "border-system-grayscale-30 bg-brand-beige-lighter rounded-lg border-[1px] border-dashed p-4",
          className,
        )}
      >
        <H6>{title}</H6>
        {amenities && amenities.length > 0 && (
          <div className="mt-2 flex flex-wrap items-center gap-x-2 gap-y-1">
            {amenities.map((amenity) => (
              <div key={amenity.id} className="flex items-center gap-1">
                <FontAwesomeIcon icon={amenity.icon} width={16} height={16} />
                <P className="text-brand-secondary-dark text-[11px]" noSpacing>
                  {amenity.displayName}
                </P>
              </div>
            ))}
          </div>
        )}
        {children && (
          <P
            className="text-brand-secondary-dark mt-2 text-sm opacity-75"
            noSpacing
          >
            {children}
          </P>
        )}
      </div>
    );
  },
);

AmenityCard.displayName = "AmenityCard";

export { AmenityCard };
