import { AvailableSlots } from "@yardzen/api-dtos";
import { AxiosResponse } from "axios";
import { apiClient } from "../base-client";

const baseUrl = "contractors";

export async function getAvailableSlots(
  contractorId: string,
  from: Date,
  to: Date,
): Promise<
  AxiosResponse<Record<string, { startTime: Date; endTime: Date }[]>>
> {
  const response = await apiClient.get(
    `${baseUrl}/${contractorId}/available-slots`,
    {
      params: {
        from: from.toISOString(),
        to: to.toISOString(),
      },
    },
  );

  return {
    ...response,
    data: AvailableSlots.from(response.data),
  };
}
