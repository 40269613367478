import { getAvailableSteps } from "@yardzen/rest-api-client";
import { useMemo } from "react";
import useSWR from "swr";

export const useBuildStudioProgress = (
  accountId: string,
  buildProjectId: string,
) => {
  const { data, error, isLoading, mutate } = useSWR(
    accountId && buildProjectId
      ? `${accountId}/build-projects/${buildProjectId}/progress`
      : null,
    () => getAvailableSteps(accountId, buildProjectId),
    {
      revalidateOnFocus: false,
    },
  );

  return useMemo(
    () => ({
      availableSteps: data?.data,
      isLoading,
      isError: error,
      revalidate: mutate,
    }),
    [data?.data, isLoading, error, mutate],
  );
};
