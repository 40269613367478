import React from "react";
import cn from "classnames";

/**
 * Props for the Stepper component.
 */
interface StepperProps {
  /** The total number of steps. */
  steps: number;
  /** The current active step (1-based index). */
  currentStep: number;
}

/**
 * A Stepper component that displays a progress bar with multiple steps.
 *
 * @param {number} steps - The total number of steps in the stepper.
 * @param {number} currentStep - The current active step (1-based index).
 * @returns {JSX.Element} A React component representing a multi-step progress bar.
 */
export function Stepper({ steps, currentStep }: StepperProps) {
  const stepsArray = React.useMemo(
    () => Array.from({ length: steps }, (_, index) => index + 1),
    [steps],
  );

  return (
    <div className="bg-tertiary-50 flex h-[5px] w-full justify-around gap-[4px]">
      {stepsArray.map((step) => (
        <div
          key={step}
          className="bg-brand-moss-greens-10 flex flex-1 items-center overflow-hidden rounded-[1px]"
        >
          <div
            className={cn(
              "bg-brand-primary-darker h-full transition-all duration-300 ease-in-out",
              currentStep >= step ? "w-full" : "w-0",
            )}
          />
        </div>
      ))}
    </div>
  );
}
