"use client";
import { customImageLoader } from "@yardzen/next-client-util";
import { ClickedEvent } from "@yardzen/segment";
import Image from "next/image";
import { useState } from "react";
import { SegmentClickTypes } from "../types";
import { cn } from "../utils";
import { AvatarImage, AvatarRoot } from "./Avatar";
import { Card, CardFooter } from "./Card";
import { Link } from "./Link";
import { H5 } from "./typography/H5";

export function ImageWithAvatarCard({
  imageUrl,
  imageAlt,
  avatarUrl,
  avatarAlt,
  linkUrl,
  linkText,
  cardHeading,
  cardSubheading,
  className,
  tracking,
  pricing,
  strikeThroughPrice,
}: {
  imageUrl: string;
  imageAlt: string;
  avatarUrl: string;
  avatarAlt: string;
  linkUrl?: string;
  linkText?: string;
  cardHeading?: string;
  cardSubheading?: string;
  className?: string;
  tracking?: Omit<ClickedEvent, "click_type"> & {
    click_type?: SegmentClickTypes;
  };
  pricing?: string;
  strikeThroughPrice?: string;
}) {
  const [imageError, setImageError] = useState(false);

  const optimizedAvatarUrl = customImageLoader({
    src: avatarUrl,
    width: 150,
    quality: 75,
  });

  return (
    <Card
      className={cn(
        "font-body flex flex-col overflow-hidden rounded-2xl",
        className,
      )}
      aria-label={cardHeading}
    >
      <div className="relative pb-[66%] md:pb-[50%]">
        {!imageError ? (
          <>
            <Image
              src={imageUrl}
              alt={imageAlt}
              className="absolute inset-0 h-full w-full object-cover"
              width={400}
              height={300}
              onError={() => setImageError(true)}
              loading="lazy"
              loader={customImageLoader}
            />
            {avatarUrl && (
              <AvatarRoot className="absolute left-3 top-3 aspect-square w-[20%]">
                <AvatarImage
                  src={optimizedAvatarUrl}
                  alt={avatarAlt}
                  className="h-full w-full"
                />
              </AvatarRoot>
            )}
          </>
        ) : (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-200 text-gray-500">
            Image not available
          </div>
        )}
      </div>

      <CardFooter className="bg-brand-secondary-lighter flex h-full gap-2 px-10 py-6">
        <div className="text-brand-secondary-dark flex w-full flex-col items-center justify-between">
          {cardHeading && (
            <H5 className="text-brand-secondary-dark mb-2 tracking-normal">
              {cardHeading}
            </H5>
          )}
          {cardSubheading && (
            <div className="text-brand-brown-black mb-4 text-base">
              {cardSubheading}
            </div>
          )}
          {pricing && (
            <div className="text-brand-secondary-dark flex items-center pb-2 text-base">
              {strikeThroughPrice ? (
                <>
                  <span className="text-system-grayscale-50 pr-2 line-through">
                    ${pricing}
                  </span>
                  <span className="font-bold">${strikeThroughPrice}</span>
                </>
              ) : (
                <span className="font-bold">${pricing}</span>
              )}
            </div>
          )}
          {linkUrl && linkText && (
            <Link
              href={linkUrl}
              className="text-brand-secondary-dark text-base underline"
              tracking={{
                button_content: tracking?.button_content ?? linkText,
                flow_name: tracking?.flow_name ?? "",
                button_name: tracking?.button_name ?? linkUrl,
              }}
            >
              {linkText}
            </Link>
          )}
        </div>
      </CardFooter>
    </Card>
  );
}
