import { Survey } from "../survey";
import { IsBoolean, IsNotEmpty, IsOptional, IsString } from "class-validator";

export class FindSurveyInput implements Pick<Survey, "surveyKey"> {
  @IsString()
  @IsNotEmpty()
  surveyKey!: string;
}

export class SurveyQuestionsInput {
  @IsBoolean()
  @IsOptional()
  onlyActiveQuestions?: boolean | null;
}
