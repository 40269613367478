"use client";

import { TypeNavigationSkeleton } from "@contentful/types";
import { SegmentFlows } from "@yardzen/next-client-util";
import { cva } from "class-variance-authority";
import { Entry } from "contentful";
import { usePathname } from "next/navigation";
import { useState } from "react";
import { cn } from "../../utils";
import { Link } from "../Link";
import { NavLinks } from "./NavLinks";
import { NavSheet, NavSheetFullScreen } from "./NavSheet";
import { UserActions, UserActionsFloating } from "./UserActions";

const navigationVariants = cva("shadow-md", {
  variants: {
    variant: {
      default: "top-0 z-50 left-0 right-0 bg-white sticky",
      floating:
        "bg-system-grayscale-00 left-0 right-0 top-0 z-50 mx-4 mt-2 rounded-lg bg-opacity-80 backdrop-blur-[64px]",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export function Navigation({
  links,
  menuLinks,
  loggedIn,
  initialUnreadCount,
  fetchUnreadCount,
  variant: propVariant,
  contentfulNavigation,
  designProfileQuizEnabled,
}: {
  links: Array<{ fields: { title: string; url: string } }>;
  menuLinks: Array<{ fields: { title: string; url: string } }>;
  loggedIn: boolean;
  initialUnreadCount?: number;
  fetchUnreadCount?: () => Promise<number>;
  variant?: "default" | "floating";
  contentfulNavigation?: Entry<
    TypeNavigationSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    string
  >;
  designProfileQuizEnabled?: boolean;
}) {
  const [unreadCount, setUnreadCount] = useState(initialUnreadCount);
  const pathname = usePathname();

  const activeNavVariant =
    propVariant ??
    (pathname === "/" ||
    pathname === "/home" ||
    pathname === "/packages" ||
    pathname === "/how-it-works" ||
    pathname === "/full-yard" ||
    pathname === "/front-yard" ||
    pathname === "/back-yard" ||
    pathname === "/curb-appeal" ||
    pathname === "/outdoor-transformation" ||
    pathname === "/botanical" ||
    pathname === "/premium" ||
    pathname === "/studio-mcgee" ||
    pathname.startsWith("/design-consultation")
      ? "floating"
      : "default");
  const isDesignConsultation = pathname.startsWith("/design-consultation");
  const contentfulLinks = contentfulNavigation?.fields.links;
  const contentfulMenuLinks = contentfulNavigation?.fields.menuLinks;
  const contentfulLoginButton = contentfulNavigation?.fields.loginButton;
  const contentfulCtaButton = contentfulNavigation?.fields.ctaButton;

  // temporarily disable due to cookie issues.
  // https://yardzen.atlassian.net/browse/EN-2431
  // long term fix:
  // https://yardzen.atlassian.net/browse/EN-2514
  // useEffect(() => {
  //   const intervalId = setInterval(async () => {
  //     const newCount = fetchUnreadCount ? await fetchUnreadCount() : 0;
  //     setUnreadCount(newCount);
  //   }, 10000);

  //   return () => clearInterval(intervalId);
  // }, [fetchUnreadCount]);

  return (
    <nav
      id="yz-nav-bar"
      className={cn(
        navigationVariants({ variant: activeNavVariant }),
        "justify-center",
      )}
    >
      <div className="mx-auto grid grid-cols-2 gap-4 px-6 py-2 md:grid-cols-[1fr_auto_1fr]">
        <div className="hidden h-full items-center gap-4 md:flex">
          {activeNavVariant === "floating" ? (
            <NavSheetFullScreen
              fallbackLinks={menuLinks}
              contentfulLinks={contentfulMenuLinks}
              side="top"
            />
          ) : (
            <NavSheet
              fallbackLinks={menuLinks}
              contentfulLinks={contentfulMenuLinks}
              side="left"
            />
          )}
          <div className="hidden h-full w-full lg:flex">
            <NavLinks fallbackLinks={links} contentfulLinks={contentfulLinks} />
          </div>
        </div>

        <div className="flex items-center justify-start md:justify-center">
          <Link
            href="https://yardzen.com"
            className="font-display h-auto px-0 text-[40px] hover:no-underline max-[415px]:text-[32px] md:mx-6"
            tracking={{
              button_content: "Yardzen",
              button_name: "Nav Yardzen Logo",
              flow_name: SegmentFlows.TOP_NAV,
            }}
          >
            Yardzen
          </Link>
        </div>

        <div className="flex h-full flex-row items-center justify-end gap-0 min-[430px]:gap-4">
          {activeNavVariant === "floating" ? (
            <UserActionsFloating
              isDesignConsultation={isDesignConsultation}
              loggedIn={loggedIn}
              unreadMessageCount={unreadCount}
              contentfulCtaButton={contentfulCtaButton}
              contentfulLoginButton={contentfulLoginButton}
              isDesignProfileQuizEnabled={designProfileQuizEnabled}
            />
          ) : (
            <UserActions
              isDesignConsultation={isDesignConsultation}
              loggedIn={loggedIn}
              contentfulCtaButton={contentfulCtaButton}
              contentfulLoginButton={contentfulLoginButton}
              unreadMessageCount={unreadCount}
            />
          )}
          {!loggedIn && (
            <div className="md:hidden">
              {activeNavVariant === "floating" ? (
                <NavSheetFullScreen
                  fallbackLinks={menuLinks}
                  contentfulLinks={contentfulMenuLinks}
                  side="right"
                />
              ) : (
                <NavSheet
                  fallbackLinks={menuLinks}
                  contentfulLinks={contentfulMenuLinks}
                  side="right"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}
