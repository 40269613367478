"use client";
import { faHeart } from "@fortawesome/pro-light-svg-icons/faHeart";
import { faHeart as faHeartSolid } from "@fortawesome/pro-solid-svg-icons/faHeart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Checkbox from "@radix-ui/react-checkbox";
import { analytics } from "@yardzen/next-segment";
import { ClickedEvent } from "@yardzen/segmentv2";
import * as React from "react";
import { SegmentClickTypes } from "../types";
import { cn } from "../utils";
import { Card, CardFooter } from "./Card";

export const StyleCardCheckbox = React.forwardRef<
  React.ElementRef<typeof Checkbox.Root>,
  React.ComponentPropsWithoutRef<typeof Checkbox.Root> & {
    image: React.ReactNode;
    tracking?: Omit<ClickedEvent, "click_type"> & {
      click_type?: SegmentClickTypes;
    };
    waitForTracking?: boolean;
  }
>(
  (
    { image, children, className, tracking, waitForTracking, ...props },
    ref,
  ) => {
    const [checked, setChecked] = React.useState<boolean | "indeterminate">(
      props.defaultChecked || false,
    );
    const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
      if (tracking) {
        //The next checked state is the inverse of the current state since it hasn't been updated yet
        const nextChecked = !checked;
        const promise = analytics.trackClicked({
          ...tracking,
          click_type: tracking.click_type || SegmentClickTypes.BUTTON,
          ...(nextChecked ? { liked: true } : { liked: false }),
        });
        if (waitForTracking) {
          await promise;
        }
      }
      props.onClick?.(e);
    };
    return (
      <Checkbox.Root
        ref={ref}
        checked={checked}
        onCheckedChange={setChecked}
        className={cn(
          "focus-visible:ring-system-blue group relative block rounded-md focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2",
          className,
        )}
        {...props}
        onClick={handleClick}
      >
        <Card
          role="presentation"
          className="ring-system-grayscale-30 data-[state=checked]:ring-system-success-regular hover:bg-brand-moss-greens-20 font-body relative block cursor-pointer overflow-hidden ring-1"
        >
          <div className="relative aspect-video">
            {React.cloneElement(image as React.ReactElement<any>, {
              className: "h-full w-full object-cover",
            })}
          </div>
          <CardFooter className="flex items-center justify-end px-4 py-2">
            {children && (
              <span className="mr-auto text-sm capitalize text-gray-600">
                {children}
              </span>
            )}
            <div className="relative h-5 w-5">
              {checked === false && (
                <FontAwesomeIcon icon={faHeart} className="h-5 w-5" />
              )}
              {checked === true && (
                <Checkbox.Indicator asChild>
                  <FontAwesomeIcon icon={faHeartSolid} className="h-5 w-5" />
                </Checkbox.Indicator>
              )}
            </div>
          </CardFooter>
        </Card>
      </Checkbox.Root>
    );
  },
);

StyleCardCheckbox.displayName = "StyleCardCheckbox";
