import { Expose, plainToInstance } from "class-transformer";
import { ProjectElement } from "./project-element.entity";
import { IsNotEmpty, IsNumber, IsOptional, IsString } from "class-validator";

export class DesignProjectElement extends ProjectElement {
  public static override from(
    designProjectElement: DesignProjectElement,
  ): DesignProjectElement {
    return plainToInstance(DesignProjectElement, designProjectElement, {
      excludeExtraneousValues: true,
      excludePrefixes: ["_"],
    });
  }

  @Expose()
  @IsNotEmpty()
  @IsString()
  projectElementId!: string;

  @IsOptional()
  @IsNumber()
  _amount?: number | null;

  @IsOptional()
  @IsNumber()
  _totalCost?: number | null;
}
