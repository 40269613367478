import { Expose, Type } from "class-transformer";
import { Survey } from "../survey";
import {
  IsArray,
  IsBoolean,
  IsInt,
  IsNotEmpty,
  IsObject,
  IsOptional,
  ValidateNested,
  IsString,
} from "class-validator";

export class CreateSurveyInput
  implements Pick<Survey, "surveyDesc" | "surveyKey">
{
  @Expose()
  @IsString()
  @IsNotEmpty()
  surveyKey!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  surveyDesc!: string;

  @Expose()
  @IsBoolean()
  @IsOptional()
  active?: boolean;

  @Expose()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => CreateSurveyQuestionInput)
  surveyQuestions!: CreateSurveyQuestionInput[];
}

export class CreateSurveyQuestionInput {
  @Expose()
  @IsString()
  @IsNotEmpty()
  text!: string;

  @Expose()
  @IsBoolean()
  @IsOptional()
  required?: boolean;

  @Expose()
  @IsString()
  @IsNotEmpty()
  questionKey!: string;

  @Expose()
  @IsObject()
  @IsNotEmpty()
  format!: Record<string, any>;

  @Expose()
  @IsInt()
  @IsNotEmpty()
  section!: number;

  @Expose()
  @IsInt()
  @IsNotEmpty()
  index!: number;
}
