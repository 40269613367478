import { Expose } from "class-transformer";
import { IsOptional, IsString } from "class-validator";
import { SurveyResponse } from "./survey-response.entity";

export class SurveyResponseInput
  implements
    Omit<
      SurveyResponse,
      | "id"
      | "createdTs"
      | "updatedTs"
      | "completed"
      | "surveyQuestionResponses"
      | "surveyId"
    >
{
  @Expose()
  @IsString()
  @IsOptional()
  buildProjectId?: string;

  @Expose()
  @IsString()
  @IsOptional()
  accountId?: string;

  @Expose()
  @IsString()
  @IsOptional()
  segmentId?: string;

  @Expose()
  @IsString()
  @IsOptional()
  userId?: string;
}
