import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from "contentful";
import type { TypeCtaSkeleton } from "./TypeCta";
import type { TypeImageSkeleton } from "./TypeImage";

export interface TypeGenericSectionFields {
  id: EntryFieldTypes.Symbol;
  title: EntryFieldTypes.Symbol;
  subtitle?: EntryFieldTypes.Symbol;
  CTAs?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeCtaSkeleton>>;
  desktopBackground?: EntryFieldTypes.EntryLink<TypeImageSkeleton>;
  mobileBackground?: EntryFieldTypes.EntryLink<TypeImageSkeleton>;
}

export type TypeGenericSectionSkeleton = EntrySkeletonType<
  TypeGenericSectionFields,
  "genericSection"
>;
export type TypeGenericSection<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode = LocaleCode,
> = Entry<TypeGenericSectionSkeleton, Modifiers, Locales>;

export function isTypeGenericSection<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode,
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>,
): entry is TypeGenericSection<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === "genericSection";
}
