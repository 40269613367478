import { Expose } from "class-transformer";
import { IsNotEmpty, IsOptional, IsString } from "class-validator";

export class CreatePipefyBuildStudioPlanCardInput {
  @Expose()
  @IsString()
  @IsNotEmpty()
  userId!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  buildProjectId!: string;

  @Expose()
  @IsString()
  @IsOptional()
  accountManagerEmail?: string;
}
