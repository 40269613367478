import React, { forwardRef } from "react";
import * as VisuallyHiddenPrimitive from "@radix-ui/react-visually-hidden";

export const VisuallyHidden = forwardRef<
  React.ElementRef<typeof VisuallyHiddenPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof VisuallyHiddenPrimitive.Root>
>(({ children, ...props }, ref) => (
  <VisuallyHiddenPrimitive.Root ref={ref} {...props}>
    {children}
  </VisuallyHiddenPrimitive.Root>
));

VisuallyHidden.displayName = "VisuallyHidden";
