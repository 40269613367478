"use client";

import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons/faArrowLeft";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons/faArrowRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClickedEvent } from "@yardzen/segment";
import useEmblaCarousel, {
  type UseEmblaCarouselType,
} from "embla-carousel-react";
import * as React from "react";
import { SegmentClickTypes } from "../types";
import { cn } from "../utils";
import { Button } from "./Button";

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

type CarouselProps = {
  opts?: CarouselOptions;
  plugins?: CarouselPlugin;
  orientation?: "horizontal" | "vertical";
  setApi?: (api: CarouselApi) => void;
};

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0];
  api: ReturnType<typeof useEmblaCarousel>[1];
  scrollPrev: () => void;
  scrollNext: () => void;
  canScrollPrev: boolean;
  canScrollNext: boolean;
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
} & CarouselProps;

const CarouselContext = React.createContext<CarouselContextProps | null>(null);

function useCarousel() {
  const context = React.useContext(CarouselContext);
  if (!context) {
    throw new Error("useCarousel must be used within a <Carousel />");
  }
  return context;
}

const Carousel = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(
  (
    {
      orientation = "horizontal",
      opts,
      setApi,
      plugins,
      className,
      children,
      ...props
    },
    ref,
  ) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        axis: orientation === "horizontal" ? "x" : "y",
      },
      plugins,
    );
    const [canScrollPrev, setCanScrollPrev] = React.useState(false);
    const [canScrollNext, setCanScrollNext] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const onSelect = React.useCallback((api: CarouselApi) => {
      if (!api) return;

      setCanScrollPrev(api.canScrollPrev());
      setCanScrollNext(api.canScrollNext());
    }, []);

    const scrollPrev = React.useCallback(() => {
      api?.scrollPrev();
    }, [api]);

    const scrollNext = React.useCallback(() => {
      api?.scrollNext();
    }, [api]);

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "ArrowLeft") {
          event.preventDefault();
          scrollPrev();
        } else if (event.key === "ArrowRight") {
          event.preventDefault();
          scrollNext();
        }
      },
      [scrollPrev, scrollNext],
    );

    React.useEffect(() => {
      if (!api || !setApi) return;
      setApi(api);
    }, [api, setApi]);

    React.useEffect(() => {
      if (!api) return;

      onSelect(api);
      api.on("select", onSelect);
      api.on("reInit", onSelect);

      return () => {
        api.off("select", onSelect);
        api.off("reInit", onSelect);
      };
    }, [api, onSelect]);

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api: api,
          opts,
          orientation: orientation || "horizontal",
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
          selectedIndex,
          setSelectedIndex,
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          className={cn("relative", className)}
          role="region"
          aria-roledescription="carousel"
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    );
  },
);
Carousel.displayName = "Carousel";

const CarouselPreview = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    renderPreview: (slideIx: number) => React.ReactNode;
  }
>(({ className, renderPreview, ...props }, ref) => {
  const { selectedIndex } = useCarousel();

  return (
    <div ref={ref} className={cn("", className)} {...props}>
      {renderPreview(selectedIndex)}
    </div>
  );
});
CarouselPreview.displayName = "CarouselPreview";

const CarouselContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    contentClassName?: string;
  }
>(({ className, contentClassName, ...props }, ref) => {
  const { carouselRef, orientation } = useCarousel();

  return (
    <div ref={carouselRef} className={cn("overflow-hidden", contentClassName)}>
      <div
        ref={ref}
        className={cn(
          "flex",
          orientation === "horizontal" ? "-ml-4" : "-mt-4 flex-col",
          className,
        )}
        role="region"
        aria-live="polite"
        {...props}
      />
    </div>
  );
});
CarouselContent.displayName = "CarouselContent";

const CarouselItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    focusable?: boolean;
  }
>(({ className, focusable, onClick, ...props }, ref) => {
  const { orientation, api, setSelectedIndex } = useCarousel();

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (focusable && api) {
        const slideNodes = api.slideNodes();
        const clickedNode = event.currentTarget;
        const index = slideNodes.findIndex((node) => node === clickedNode);
        setSelectedIndex(index);

        if (index !== -1) {
          api.scrollTo(index);
        }
      }

      onClick?.(event);
    },
    [api, focusable, onClick, setSelectedIndex],
  );

  return (
    <div
      ref={ref}
      role="group"
      aria-roledescription="slide"
      className={cn(
        "min-w-0 shrink-0 grow-0 basis-full",
        orientation === "horizontal" ? "pl-4" : "pt-4",
        focusable && "cursor-pointer",
        className,
      )}
      {...props}
      onClick={handleClick}
    />
  );
});
CarouselItem.displayName = "CarouselItem";

const CarouselPrevious = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button> & {
    icon?: IconDefinition;
  }
>(
  (
    {
      className,
      variant = "outline",
      icon = faArrowLeft,
      size = "icon",
      ...props
    },
    ref,
  ) => {
    const { orientation, scrollPrev, canScrollPrev } = useCarousel();

    return (
      <Button
        ref={ref}
        variant={variant}
        size={size}
        className={cn(
          "absolute h-8 w-8 rounded-full",
          orientation === "horizontal"
            ? "-left-12 top-1/2 -translate-y-1/2"
            : "-top-12 left-1/2 -translate-x-1/2 rotate-90",
          className,
        )}
        disabled={!canScrollPrev}
        onClick={scrollPrev}
        {...props}
      >
        <FontAwesomeIcon icon={icon} />
        <span className="sr-only">Previous slide</span>
      </Button>
    );
  },
);
CarouselPrevious.displayName = "CarouselPrevious";

const CarouselNext = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button> & {
    icon?: IconDefinition;
  }
>(
  (
    {
      className,
      variant = "outline",
      icon = faArrowRight,
      size = "icon",
      ...props
    },
    ref,
  ) => {
    const { orientation, scrollNext, canScrollNext } = useCarousel();

    return (
      <Button
        ref={ref}
        variant={variant}
        size={size}
        className={cn(
          "absolute h-8 w-8 rounded-full",
          orientation === "horizontal"
            ? "-right-12 top-1/2 -translate-y-1/2"
            : "-bottom-12 left-1/2 -translate-x-1/2 rotate-90",
          className,
        )}
        disabled={!canScrollNext}
        onClick={scrollNext}
        {...props}
      >
        <FontAwesomeIcon icon={icon} />
        <span className="sr-only">Next slide</span>
      </Button>
    );
  },
);
CarouselNext.displayName = "CarouselNext";

const CarouselDots = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    count: number;
    dotClassName?: string;
    selectedDotClassName?: string;
    tracking?: Omit<ClickedEvent, "click_type"> & {
      click_type?: SegmentClickTypes;
    };
  }
>(
  (
    {
      className,
      dotClassName,
      selectedDotClassName,
      count,
      tracking,
      ...props
    },
    ref,
  ) => {
    const { api } = useCarousel();
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    React.useEffect(() => {
      if (!api) return;
      setSelectedIndex(api.selectedScrollSnap());
      const onSelect = () => setSelectedIndex(api.selectedScrollSnap());
      api.on("select", onSelect);

      return () => {
        api.off("select", onSelect);
      };
    }, [api]);

    return (
      <div
        ref={ref}
        className={cn("mt-4 flex w-full justify-center gap-2", className)}
        {...props}
      >
        {Array.from({ length: count }).map((_, index) => (
          <Button
            key={index}
            variant={selectedIndex === index ? "default" : "outline"}
            size="dot"
            className={cn(
              "border-primary rounded-full",
              selectedIndex === index ? selectedDotClassName : dotClassName,
            )}
            onClick={() => {
              api?.scrollTo(index);
              setSelectedIndex(index);
            }}
            {...tracking}
          />
        ))}
      </div>
    );
  },
);
CarouselDots.displayName = "CarouselDots";

export {
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  CarouselPreview,
  type CarouselApi,
};
