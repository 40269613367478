import { Media } from "@prisma/client";
import { Expose, plainToInstance, Type } from "class-transformer";
import {
  IsArray,
  IsBoolean,
  IsDate,
  IsEmail,
  IsInt,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  validateSync,
} from "class-validator";
import { ContractorAttribute } from "./contractor-attribute.enum";
import { ContractorDocument } from "./contractor-document.entity";
import { ContractorReview } from "./contractor-review.entity";

export class ContractorProfile {
  public static from(contractorProfile: ContractorProfile) {
    const instance = plainToInstance(ContractorProfile, contractorProfile, {
      excludeExtraneousValues: true,
      enableCircularCheck: true,
    });

    instance.promotionalMessageStartDate = instance.promotionalMessageStartDate
      ? new Date(instance.promotionalMessageStartDate)
      : undefined;
    instance.promotionalMessageEndDate = instance.promotionalMessageEndDate
      ? new Date(instance.promotionalMessageEndDate)
      : undefined;

    const errors = validateSync(instance);
    if (errors.length > 0) {
      throw new Error(
        `Validation failed: ${errors.map((error) => Object.values(error.constraints || {})).join(", ")}`,
      );
    }

    return instance;
  }

  @Expose()
  @IsString()
  @IsNotEmpty()
  contractorId!: string;

  @Expose()
  @IsString()
  @IsOptional()
  userId?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsEmail()
  @IsOptional()
  email?: string;

  @Expose()
  @IsArray()
  @IsOptional()
  media?: Media[];

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  businessName?: string;

  @Expose()
  @IsString()
  @IsOptional()
  headshotMediaUrl?: string;

  @Expose()
  @IsString()
  @IsOptional()
  thumbnailMediaUrl?: string;

  @Expose()
  @IsArray()
  @IsOptional()
  galleryImages?: { id: string; uri: string }[];

  @Expose()
  @IsArray()
  @IsOptional()
  videoMedia?: { id: string; uri: string }[];

  @Expose()
  @IsDate()
  @IsNotEmpty()
  @Type(() => Date)
  createdTs!: Date;

  @Expose()
  @IsDate()
  @IsNotEmpty()
  @Type(() => Date)
  updatedTs!: Date;

  @Expose()
  @IsArray()
  @IsOptional()
  attributes?: ContractorAttribute[];

  @Expose()
  @IsString()
  @IsOptional()
  aboutSummary?: string;

  @Expose()
  @IsString()
  @IsOptional()
  whyYardzenLovesSummary?: string;

  @Expose()
  @IsInt()
  @IsOptional()
  yearsInBusiness?: number;

  @Expose()
  @IsString()
  @IsOptional()
  licenseNumber?: string;

  @Expose()
  @IsDate()
  @IsOptional()
  licenseExpirationDate?: Date;

  @IsArray()
  @IsOptional()
  reviews?: ContractorReview[];

  @Expose()
  @IsNumber()
  @IsOptional()
  rating?: number;

  @Expose()
  @IsBoolean()
  active!: boolean;

  @Expose()
  @IsBoolean()
  @IsOptional()
  isTest?: boolean;

  @Expose()
  @IsString()
  @IsOptional()
  stripeConnectedAccountId?: string;

  @Expose()
  @IsInt()
  @IsOptional()
  minSuccessBudget?: number;

  @Expose()
  @IsInt()
  @IsOptional()
  maxSuccessBudget?: number;

  @Expose()
  @IsBoolean()
  @IsOptional()
  yardAiEnabled?: boolean;

  @Expose()
  @IsString()
  @IsOptional()
  city?: string;

  @Expose()
  @IsString()
  @IsOptional()
  state?: string;

  @Expose()
  @IsString()
  @IsOptional()
  firstName?: string;

  @Expose()
  @IsString()
  @IsOptional()
  lastName?: string;

  @Expose()
  @IsString()
  @IsOptional()
  yelpUrl?: string;

  @Expose()
  @IsString()
  @IsOptional()
  googleUrl?: string;

  @Expose()
  @IsNumber()
  @IsOptional()
  latitude?: number;

  @Expose()
  @IsNumber()
  @IsOptional()
  longitude?: number;

  @Expose()
  @IsString()
  @IsOptional()
  promotionalMessage?: string;

  @Expose()
  @IsDate()
  @IsOptional()
  promotionalMessageStartDate?: Date;

  @Expose()
  @IsDate()
  @IsOptional()
  promotionalMessageEndDate?: Date;

  @IsArray()
  @IsOptional()
  abilities?: string[];

  @IsArray()
  @IsOptional()
  documents?: ContractorDocument[];

  @Expose()
  @IsString()
  @IsOptional()
  calendlyUrl?: string;
}
