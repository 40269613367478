export const contractorAbilitiesMap: Record<string, string> = {
  "Botanical Only": "Plants & Lawn",
  "CA Rooms": "Outdoor Rooms",
  Concrete: "Concrete",
  "Decking - Composite": "Composite Decking",
  Exterior: "Paint & Exteriors",
  "Lighting - Low Voltage": "Landscape Lighting",
  Masonry: "Stone & Brick",
  "Metal Work": "Metal Features",
  "Outdoor Kitchens": "Custom Kitchens",
  "Outdoor Showers": "Outdoor Showers",
  Pavers: "Pavers",
  "Pergolas - Composite, Metal, Kits": "Pergolas",
  "Pools/Spas - Custom": "Custom Pools",
  "Pools/Spas - Pre Fab": "Prefabricated Pools",
  "Sheds/ADUs": "Sheds & ADUs",
  Turf: "Turf",
  "Water Features/Ponds": "Custom Water Features",
  Woodwork: "Wood Features",
};
