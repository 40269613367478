"use client";

import { analytics } from "@yardzen/next-segment";
import { ClickedEvent } from "@yardzen/segmentv2";
import { cva, type VariantProps } from "class-variance-authority";
import React from "react";
import { SegmentClickTypes } from "..";
import { cn } from "../utils";

const linkVariants = cva(
  "transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default: "text-primary hover:underline",
        button:
          "shadow-douglas-fir focus:ring-douglas-fir rounded-full border px-6 transition-all duration-300 ease-in-out focus:shadow-lg focus:outline-none focus:ring-2 inline-flex items-center justify-center bg-brand-primary-darker hover:bg-black-elderberry/90 border-transparent text-white",
        nav: "text-black-elderberry hover:text-brand-primary-dark",
        zest: "bg-brand-moss-greens-10 text-brand-primary-darker hover:bg-brand-moss-greens-20 active:bg-brand-moss-greens-20 border-transparent disabled:bg-system-grayscale-10 disabled:text-system-grayscale-30",
        outline:
          "flex items-center justify-center border rounded-full border-brand-primary-dark text-brand-primary-dark focus-visible:border-[#3B482F]/30 active:border-brand-primary-dark/70 active:text-brand-primary-dark/70 disabled:border-system-grayscale-30 disabled:text-system-grayscale-30 bg-transparent",
      },
      size: {
        default: "",
        icon: "h-6 w-6 p-0 inline-flex items-center justify-center rounded-full",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface LinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof linkVariants> {
  tracking?: Omit<ClickedEvent, "click_type"> & {
    click_type?: SegmentClickTypes;
  };
  waitForTracking?: boolean;
}

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      className,
      variant,
      size,
      tracking,
      waitForTracking,
      href,
      children,
      ...props
    },
    ref,
  ) => {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        onClick={async (e) => {
          e.preventDefault();

          if (tracking) {
            const promise = analytics.trackClicked({
              ...tracking,
              button_content: tracking.button_content || String(children),
              click_type: tracking.click_type || SegmentClickTypes.HYPERLINK,
            });
            if (waitForTracking) {
              await promise;
            }
          }

          if (props.target === "_blank") {
            window.open(href, "_blank");
          } else if (href?.startsWith("#")) {
            window.location.hash = href;
          } else {
            window.location.href = href || "";
          }
        }}
        className={cn(linkVariants({ variant, size, className }))}
        ref={ref}
        href={href}
        {...props}
      >
        {children}
      </a>
    );
  },
);
Link.displayName = "Link";

export { Link, linkVariants };
