import useSWR, { mutate } from "swr";
import { getBuildProject } from "@yardzen/rest-api-client";
import { FindOneBuildProjectDto } from "@yardzen/api-dtos";

export const useBuildProject = (
  buildProjectId: string,
  accountId?: string,
  additionalOptions?: FindOneBuildProjectDto,
) => {
  // Only fetch if both buildProjectId and accountId are present
  const cacheKey = accountId
    ? [`/build-project/${buildProjectId}`, accountId, additionalOptions]
    : null;
  const { data, error, isLoading, isValidating } = useSWR(
    cacheKey,
    accountId
      ? () => getBuildProject(buildProjectId, accountId, additionalOptions)
      : null,
    {
      revalidateOnFocus: false,
    },
  );

  return {
    buildProject: data?.data,
    isLoading,
    isValidating,
    isError: error,
    revalidate: () => mutate(cacheKey),
  };
};
