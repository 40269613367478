import { Expose } from "class-transformer";
import { IsEmail, IsEnum, IsNotEmpty } from "class-validator";

export enum PackageName {
  BACKYARD = "back-yard",
  FRONT_YARD = "front-yard",
  FULL_YARD = "full-yard",
  PREMIUM = "premium",
  CURB_APPEAL = "curb-appeal",
  OUTDOOR_TRANSFORMATION = "outdoor-transformation",
}

export class PackageRecommendationOutput {
  @Expose()
  @IsEnum(PackageName)
  @IsNotEmpty()
  packageName: PackageName;

  @Expose()
  @IsEmail()
  @IsNotEmpty()
  clientEmail: string;
}
