"use client";

import * as React from "react";
import { AvatarFallback, AvatarImage, AvatarRoot } from "./Avatar";
import { Dialog, DialogContent, DialogTitle } from "./Dialog";
import { VisuallyHidden } from "./VisuallyHidden";
import { Button } from "./Button";
import { H4 } from "../components/typography/H4";
import { cn } from "../utils";
import { P } from "../components/typography/P";

export interface AvatarSuccessDialogProps
  extends React.ComponentProps<typeof Dialog> {
  avatarSrc: string;
  avatarFallback: string;
  title: string;
  align?: "center" | "left";
  children: React.ReactNode;
  signature?: {
    type: "text" | "image";
    content: string; // URL for image, text for string
  };
  primaryCta: {
    label: string;
    onClick: () => void;
  };
  secondaryCta?: {
    label: string;
    onClick: () => void;
  };
  className?: string;
}

export const AvatarSuccessDialog = React.forwardRef<
  HTMLDivElement,
  AvatarSuccessDialogProps
>(
  (
    {
      open,
      onOpenChange,
      avatarSrc,
      avatarFallback,
      title,
      align = "center",
      children,
      signature,
      primaryCta,
      secondaryCta,
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <Dialog open={open} onOpenChange={onOpenChange} {...props}>
        <DialogContent
          ref={ref}
          className={cn("bg-brand-beige-lighter w-[341px]", className)}
        >
          <div className="flex flex-col items-center space-y-6">
            <AvatarRoot className="h-[70px] w-[70px]">
              <AvatarImage
                className="rounded-full object-cover"
                src={avatarSrc}
                alt="Avatar"
              />
              <AvatarFallback>{avatarFallback}</AvatarFallback>
            </AvatarRoot>

            <VisuallyHidden>
              <DialogTitle>{title}</DialogTitle>
            </VisuallyHidden>
            <div
              className={cn(
                "w-full text-center",
                align === "left" && "text-left",
              )}
            >
              <H4 className="text-brand-secondary-dark text-[32px] leading-[120%] tracking-normal">
                {title}
              </H4>
            </div>

            <div
              className={cn(
                "text-brand-brown-black text-center leading-[140%]",
                align === "left" && "text-left",
              )}
            >
              {children}
            </div>

            {signature && (
              <div className="flex flex-col self-start">
                <P className="text-brand-primary-dark text-[11px]">
                  Your signature
                </P>
                {signature.type === "text" ? (
                  <div className="text-brand-primary-dark font-script text-[24px] text-sm italic">
                    {signature.content}
                  </div>
                ) : (
                  <img
                    src={signature.content}
                    alt="Signature"
                    className="max-h-[40px] w-auto"
                  />
                )}
              </div>
            )}

            <div className="flex w-full flex-col items-center gap-4">
              <Button className="w-full py-4" onClick={primaryCta.onClick}>
                {primaryCta.label}
              </Button>

              {secondaryCta && (
                <Button
                  variant="secondary"
                  className="w-full py-4"
                  onClick={secondaryCta.onClick}
                >
                  {secondaryCta.label}
                </Button>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  },
);

AvatarSuccessDialog.displayName = "AvatarSuccessDialog";
