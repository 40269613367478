import { Expose } from "class-transformer";
import {
  IsArray,
  IsEnum,
  IsNotEmpty,
  IsOptional,
  IsString,
} from "class-validator";
import { ConversationRole } from "./conversation-role.enum";

export class ParticipantInput {
  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  userProfileId?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  contractorId?: string;

  @Expose()
  @IsEnum(ConversationRole)
  role!: ConversationRole;
}

export class CreateConversationInput {
  @Expose()
  @IsArray()
  conversationParticipants!: ParticipantInput[];
}
