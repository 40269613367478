import { TypeLinkCardSkeleton } from "@contentful/types";
import { faBars, faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SegmentFlows } from "@yardzen/next-client-util";
import { Entry } from "contentful";
import { forwardRef } from "react";
import {
  Button,
  Link,
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../../";
import { AnimatedLink } from "./AnimatedLink";

interface NavSheetProps {
  fallbackLinks: Array<{
    fields: { title: string; url: string };
  }>;
  contentfulLinks:
    | (
        | Entry<TypeLinkCardSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>
        | undefined
      )[]
    | undefined;

  side?: "top" | "right" | "bottom" | "left";
}

export const NavSheet = forwardRef<HTMLDivElement, NavSheetProps>(
  ({ fallbackLinks, contentfulLinks, side = "right" }, ref) => {
    const links = contentfulLinks ? contentfulLinks : fallbackLinks;
    return (
      <Sheet>
        <SheetTrigger asChild>
          <Button
            variant="outline"
            className="flex items-center !gap-2 !px-2"
            size="sm"
          >
            <span className="size-4">
              <FontAwesomeIcon icon={faBars} className="!size-4 pr-0 md:pr-2" />
            </span>
            <span className="hidden p-0 text-sm leading-none md:block">
              Menu
            </span>
          </Button>
        </SheetTrigger>
        <SheetContent
          side={side}
          className="bg-brand-secondary-lighter flex flex-col gap-0 p-0 md:!max-w-[250px]"
          ref={ref}
        >
          <SheetHeader className="flex-initial">
            <SheetTitle className="sr-only">Navigation Menu</SheetTitle>
            <div className="flex justify-end pr-2 md:pr-0">
              <SheetClose asChild>
                <Button
                  className="relative right-2 top-0 my-0 !p-0"
                  variant="ghost"
                  size="icon"
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    size="lg"
                    className="!h-6 !w-6"
                  />
                </Button>
              </SheetClose>
            </div>
          </SheetHeader>

          <div className="flex flex-initial flex-col gap-3 overflow-x-auto overflow-y-auto px-6">
            <div className="bg-brand-primary-dark visible mb-2 h-[1px] w-full md:hidden" />
            {links &&
              links.map((link, index) => (
                <div
                  key={index}
                  className="flex justify-end py-1 md:justify-start"
                >
                  <AnimatedLink
                    href={link?.fields.url || ""}
                    label={link?.fields.title || ""}
                    className="font-display whitespace-nowrap text-2xl !font-normal max-[420px]:text-xl"
                  />
                </div>
              ))}
          </div>

          <SheetFooter className="flex flex-1 flex-col justify-end p-4 pb-8 md:justify-start">
            <div className="flex flex-col">
              <div className="bg-brand-primary-dark mb-4 h-[1px] w-full md:w-2/3" />
              <SheetDescription className="text-brand-primary-dark pb-4 text-sm md:text-base">
                Wondering if Yardzen is right for your yard?
              </SheetDescription>
              <div className="flex justify-center">
                <Button size="sm" asChild>
                  <Link
                    href="https://calendly.com/d/cqks-48t-ckd/project-consultation-with-yardzen"
                    target="_blank"
                    tracking={{
                      button_name: "Schedule a Free Call",
                      button_content: "Schedule a Free Call",
                      flow_name: SegmentFlows.TOP_NAV,
                    }}
                  >
                    Schedule&nbsp;A Free&nbsp;Call
                  </Link>
                </Button>
              </div>
            </div>
          </SheetFooter>
        </SheetContent>
      </Sheet>
    );
  },
);

NavSheet.displayName = "NavSheet";

export const NavSheetFullScreen = forwardRef<HTMLDivElement, NavSheetProps>(
  ({ fallbackLinks, contentfulLinks, side = "right" }, ref) => {
    const links = contentfulLinks ? contentfulLinks : fallbackLinks;
    return (
      <Sheet>
        <SheetTrigger asChild>
          <Button
            variant="ghost"
            className="flex items-center justify-center !px-2"
            size="lg"
          >
            <span className="flex size-8 items-center justify-center">
              <FontAwesomeIcon icon={faBars} className="!size-6" />
            </span>
          </Button>
        </SheetTrigger>
        <SheetContent
          side={side}
          className="flex h-full w-full flex-col gap-0 bg-white/50 backdrop-blur-[32px] sm:pb-0 sm:pl-12 sm:pr-6 sm:pt-6"
          ref={ref}
          disableOverlay={true}
        >
          <SheetHeader className="flex-initial py-12 sm:p-0">
            <SheetTitle className="sr-only">Navigation Menu</SheetTitle>
            <div className="flex items-center justify-between pr-2 md:pr-0">
              <div className="flex items-center justify-start md:justify-center">
                <Link
                  href="https://yardzen.com"
                  className="font-display h-auto px-0 text-[32px] hover:no-underline md:mx-6"
                  tracking={{
                    button_name: "Yardzen Logo",
                    button_content: "Yardzen",
                    flow_name: SegmentFlows.TOP_NAV,
                  }}
                >
                  Yardzen
                </Link>
              </div>
              <SheetClose asChild>
                <Button
                  className="relative right-2 top-0 my-0 !p-0"
                  variant="ghost"
                  size="icon"
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    size="2xl"
                    className="!h-10 !w-10"
                  />
                </Button>
              </SheetClose>
            </div>
          </SheetHeader>

          <div className="flex flex-1 flex-col gap-4 overflow-x-auto overflow-y-auto sm:px-6">
            {links &&
              links.map((link, index) => (
                <div key={index} className="flex justify-start py-1">
                  <AnimatedLink
                    href={link?.fields.url || ""}
                    label={link?.fields.title || ""}
                    className="text-brand-primary-darker font-display whitespace-nowrap text-5xl !font-normal md:text-6xl"
                  />
                </div>
              ))}
          </div>
        </SheetContent>
      </Sheet>
    );
  },
);

NavSheetFullScreen.displayName = "NavSheetFullScreen";
