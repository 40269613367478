import { Expose, plainToInstance } from "class-transformer";
import { IsDate, IsJSON, IsNotEmpty, IsString } from "class-validator";

export class SurveyQuestionResponse {
  public static from(surveyQuestionResponse: SurveyQuestionResponse) {
    return plainToInstance(SurveyQuestionResponse, surveyQuestionResponse, {
      excludeExtraneousValues: true,
    });
  }

  @Expose()
  @IsString()
  @IsNotEmpty()
  surveyResponseId!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  surveyQuestionId!: string;

  @Expose()
  @IsJSON()
  @IsNotEmpty()
  response!: string;

  @Expose()
  @IsDate()
  createdTs!: Date;

  @Expose()
  @IsDate()
  updatedTs?: Date | null;
}
