import React, { forwardRef, ReactNode } from "react";
import { cn } from "../utils";
import { faBadgePercent } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H5 } from "./typography/H5";

type BaseUtilityCardProps = React.HTMLAttributes<HTMLDivElement>;
type CardStyle = "fill" | "outline";

type UtilityCardProps =
  | (BaseUtilityCardProps & {
      variant?: "default" | "promotional";
      author?: never;
      estimate?: never;
      cardStyle?: never;
    })
  | (BaseUtilityCardProps & {
      variant: "comment";
      author: string;
      estimate?: never;
      cardStyle?: never;
    })
  | (BaseUtilityCardProps & {
      variant: "estimate";
      cardStyle: CardStyle;
      author?: never;
      estimate: ReactNode;
    });

export const UtilityCard = forwardRef<HTMLDivElement, UtilityCardProps>(
  (
    {
      children,
      author,
      estimate,
      className,
      variant = "default",
      cardStyle = "fill",
      ...props
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={cn(
          "text-brand-primary-darker relative flex flex-col justify-center rounded-[8px] p-4 text-sm",
          variant === "promotional" && "bg-brand-greens-light min-h-[87.25px]",
          variant === "comment" &&
            "bg-brand-beige-light text-system-grayscale-70",
          variant === "estimate" &&
            "text-brand-primary-darker border-system-grayscale-100/10 flex-col rounded-[4px] border  p-2 text-center text-[11px]",
          variant === "estimate" && cardStyle === "outline" && "bg-transparent",
          variant === "estimate" &&
            cardStyle === "fill" &&
            "bg-brand-greens-regular/15",
          className,
        )}
        {...props}
      >
        {variant === "promotional" && (
          <FontAwesomeIcon
            icon={faBadgePercent}
            className="text-brand-primary-darker absolute left-[13px] top-[8px] h-[71.25px] w-[71.25px] opacity-5"
          />
        )}
        {children}
        {variant === "comment" && (
          <span className="text-brand-primary-regular mt-[10.42px] text-xs">
            {author}
          </span>
        )}
        {variant === "estimate" && (
          <H5 className="text-brand-secondary-dark">{estimate}</H5>
        )}
      </div>
    );
  },
);

UtilityCard.displayName = "UtilityCard";
