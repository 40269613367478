import React from "react";
import { cn } from "@yardzen/next-client-util";

export const List: React.FC<React.HTMLAttributes<HTMLUListElement>> = ({
  children,
  className,
  ...props
}) => (
  <ul
    className={cn("font-body my-6 ml-6 list-disc [&>li]:mt-2", className)}
    {...props}
  >
    {children}
  </ul>
);
