import React from "react";
import { cn } from "@yardzen/next-client-util";

export const Large: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => (
  <div className={cn("font-body text-lg font-semibold", className)} {...props}>
    {children}
  </div>
);
