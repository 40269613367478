import { Expose } from "class-transformer";
import { IsIn, IsNotEmpty } from "class-validator";
import { plainToInstance } from "class-transformer";

export class TimeToBuild {
  public static from(timeToBuild: TimeToBuild): TimeToBuild {
    return plainToInstance(TimeToBuild, timeToBuild, {
      excludeExtraneousValues: true,
    });
  }

  @Expose()
  @IsNotEmpty()
  time!: number;

  @Expose()
  @IsNotEmpty()
  @IsIn(["days", "weeks"], {
    message: "timeUnit must be either 'days' or 'weeks'",
  })
  timeUnit!: "days" | "weeks";
}
