"use client";
import { faHeart } from "@fortawesome/pro-light-svg-icons/faHeart";
import { faStar } from "@fortawesome/pro-light-svg-icons/faStar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { cn } from "../utils";
import { DesignPackages } from "@yardzen/constants";
import { Button } from "./Button";
import { Card, CardFooter } from "./Card";
import { Link } from "./Link";

export function DesignCard({
  imageUrl,
  tags = [],
  city,
  state,
  isLiked,
  isNew,
  externalId,
  designPackage,
  onLike,
  className,
}: {
  imageUrl: string;
  tags?: string[];
  city?: string;
  state?: string;
  isLiked: boolean;
  isNew: boolean;
  externalId: number;
  designPackage?: string;
  onLike: (id: number) => void;
  className?: string;
}) {
  const [imageError, setImageError] = useState(false);

  const location = [city, state].filter(Boolean).join(", ");

  const getDesignPackageUrl = (designPackage: string) => {
    switch (designPackage) {
      case "front yard":
        return `${process.env.NEXT_PUBLIC_WORDPRESS_BASE_URL}/front-yard`;
      case "back yard":
        return `${process.env.NEXT_PUBLIC_WORDPRESS_BASE_URL}/back-yard`;
      case "curb appeal":
        return `${process.env.NEXT_PUBLIC_WORDPRESS_BASE_URL}/curb-appeal`;
      case "full yard":
        return `${process.env.NEXT_PUBLIC_WORDPRESS_BASE_URL}/full-yard`;
      case "whole home":
        return `${process.env.NEXT_PUBLIC_WORDPRESS_BASE_URL}/outdoor-transformation`;
      case "botanical":
        return `${process.env.NEXT_PUBLIC_WORDPRESS_BASE_URL}/botanical`;
      case "uber premium":
        return `${process.env.NEXT_PUBLIC_WORDPRESS_BASE_URL}/premium`;
      default:
        return `${process.env.NEXT_PUBLIC_WORDPRESS_BASE_URL}/packages`;
    }
  };

  return (
    <Card className={cn("font-body overflow-hidden", className)}>
      <div className="relative pb-[66.67%]">
        {!imageError ? (
          <img
            src={imageUrl}
            alt={`Design render for ${tags.join(", ")}`}
            className="absolute inset-0 h-full w-full object-cover"
            onError={() => setImageError(true)}
          />
        ) : (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-200 text-gray-500">
            Image not available
          </div>
        )}
        {isNew && (
          <div className="bg-brand-tertiary-50 border-brand-primary-darker absolute left-2 top-2 flex items-center rounded-full border px-2 py-1 text-xs font-bold">
            <FontAwesomeIcon icon={faStar} className="mr-1 h-3 w-3" />
            New
          </div>
        )}
      </div>
      <CardFooter className="flex flex-col gap-2 p-4">
        <div className="flex w-full items-center justify-between">
          <div className="text-sm capitalize text-gray-600">{location}</div>
          <Button
            variant={isLiked ? "zest" : "ghost"}
            className="flex h-6 w-10 flex-shrink-0 items-center justify-center rounded-full p-0"
            onClick={() => onLike(externalId)}
            aria-label={isLiked ? "Unlike design" : "Like design"}
          >
            <FontAwesomeIcon
              icon={faHeart}
              className={cn("h-5 w-5", isLiked ? "text-black-elderberry" : "")}
            />
          </Button>
        </div>
        {designPackage && (
          <div className="flex w-full items-start gap-1 text-sm text-gray-600">
            <p>Yardzen Package: </p>
            <Link href={getDesignPackageUrl(designPackage)} target="_blank">
              <p className="capitalize underline">
                {DesignPackages[designPackage] ?? null}
              </p>
            </Link>
          </div>
        )}
      </CardFooter>
    </Card>
  );
}
