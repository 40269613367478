import React, { ComponentProps, forwardRef } from "react";

interface MessagesGroupProps extends ComponentProps<"div"> {
  children: React.ReactNode;
  avatar?: React.ReactNode;
}

const MessagesGroup = forwardRef<HTMLDivElement, MessagesGroupProps>(
  ({ children, avatar, ...props }, ref) => {
    return (
      <div ref={ref} {...props} className="flex flex-row items-start gap-2">
        {avatar}
        <div className="mt-2 flex w-full flex-col gap-4 pr-4 md:gap-6">
          {children}
        </div>
      </div>
    );
  },
);

MessagesGroup.displayName = "MessagesGroup";

export { MessagesGroup };
