import { Expose, plainToInstance } from "class-transformer";
import {
  IsBoolean,
  IsDate,
  IsEmail,
  IsNotEmpty,
  IsOptional,
  IsString,
} from "class-validator";
import { Address } from "../address.entity.ts";

export class UserProfile {
  public static from(info: UserProfile) {
    return plainToInstance(UserProfile, info, {
      excludeExtraneousValues: true,
    });
  }

  @Expose()
  @IsString()
  @IsNotEmpty()
  userId!: string;

  @Expose()
  @IsEmail()
  @IsString()
  @IsOptional()
  email?: string;

  @Expose()
  @IsString()
  @IsOptional()
  firstName?: string;

  @Expose()
  @IsString()
  @IsOptional()
  lastName?: string;

  @Expose()
  @IsString()
  @IsOptional()
  phone?: string;

  @Expose()
  @IsString()
  @IsOptional()
  leadZipcode?: string;

  @Expose()
  @IsBoolean()
  @IsOptional()
  optIn?: boolean;

  @Expose()
  @IsBoolean()
  @IsOptional()
  testAccount?: boolean;

  @Expose()
  @IsDate()
  createdTs!: Date;

  @Expose()
  @IsDate()
  updatedTs!: Date;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  mailingAddressId?: string;

  @Expose()
  @IsOptional()
  mailingAddress?: Address;
}
