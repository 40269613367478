import axios from "axios";
import Cookies from "js-cookie";

const baseURL = process.env.NEXT_PUBLIC_BANYAN_REST_API_URL;

const authTokenCookieName = "yz_v2_token";

const apiClient = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// Read the auth token from the cookie and add it to the request headers
apiClient.interceptors.request.use((config) => {
  const authToken = Cookies.get(authTokenCookieName);
  if (authToken) {
    config.headers["x-yz-auth-v2"] = authToken;
  }
  return config;
});

// Redirect 401 and 403 errors to the login page
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      Cookies.remove(authTokenCookieName);
      if (typeof window !== "undefined") {
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  },
);

export { apiClient };
