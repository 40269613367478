import React from "react";
import { cn } from "../utils";

type Step = {
  label: string;
  defaultIcon?: React.ReactNode;
};

interface LabeledStepperProps {
  steps: Step[];
  currentStep: number;
  activeStepIcon?: React.ReactNode;
  doneStepIcon?: React.ReactNode;
  className?: string;
  stepLabelClassName?: string;
}

/**
 * LabeledStepper component
 *
 * A component that renders a series of steps with labels and icons, indicating the current step and progress.
 *
 * @param {Object} props - The component props
 * @param {Step[]} props.steps - An array of step objects, each containing a label and optionally a default icon
 * @param {number} props.currentStep - The current active step (1-based index)
 * @param {React.ReactNode} [props.activeStepIcon] - Icon to display for the currently active step
 * @param {React.ReactNode} [props.doneStepIcon] - Icon to display for completed steps
 * @param {string} [props.className] - Additional CSS class for the main container
 * @param {string} [props.stepLabelClassName] - Additional CSS class for step labels
 * @returns {JSX.Element} The rendered LabeledStepper component
 */
export function LabeledStepper({
  steps,
  currentStep,
  activeStepIcon,
  doneStepIcon,
  className = "",
  stepLabelClassName = "",
}: LabeledStepperProps) {
  return (
    <div
      className={cn(
        "flex w-full flex-row items-center justify-around",
        className,
      )}
    >
      {steps.map((step, ix) => (
        <React.Fragment key={step.label}>
          <div className="flex flex-col items-center gap-1">
            <span className="flex h-8 items-center">
              {renderIcon(step, ix, currentStep, activeStepIcon, doneStepIcon)}
            </span>
            <span className={cn("text-center", stepLabelClassName)}>
              {step.label}
            </span>
          </div>
          {ix < steps.length - 1 && (
            <div className="h-[1px] w-full bg-gradient-to-r from-white via-green-800 to-white" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

const renderIcon = (
  step: Step,
  stepIX: number,
  currentStep: number,
  activeStepIcon: React.ReactNode,
  doneStepIcon: React.ReactNode,
) => {
  if (currentStep === stepIX + 1) {
    return activeStepIcon;
  }
  if (currentStep > stepIX + 1) {
    return doneStepIcon;
  }
  return step.defaultIcon;
};
