"use client";

import { analytics } from "@yardzen/next-segment";
import { FormFieldFilledEvent } from "@yardzen/segmentv2";
import { forwardRef, InputHTMLAttributes } from "react";
import { cn } from "../utils";

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  tracking?: FormFieldFilledEvent;
  waitForTracking?: boolean;
  supressValueTracking?: boolean;
  error?: boolean;
  hint?: string;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      onBlur,
      tracking,
      supressValueTracking,
      waitForTracking,
      error,
      ...props
    },
    ref,
  ) => {
    return (
      <input
        onBlur={async (e) => {
          if (tracking) {
            const promise = analytics.trackFormFieldFilled({
              ...tracking,
              text_value: supressValueTracking ? "" : e.target.value,
            });
            if (waitForTracking) {
              await promise;
            }
          }
          onBlur?.(e);
        }}
        className={cn(
          "shadow-douglas-fir focus:ring-douglas-fir ring-offset-background focus-visible:ring-ring h-10 whitespace-nowrap rounded-full p-3 text-base font-medium transition-all duration-300 ease-in-out focus:shadow-lg focus:outline-none focus:ring-4 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 md:text-sm dark:bg-white",
          "placeholder:text-system-grayscale-50",
          error && [
            "border-system-detrimental-regular",
            "focus:ring-system-detrimental-regular",
            "text-system-detrimental-extradark",
            "placeholder:text-system-detrimental-regular",
          ],
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);

Input.displayName = "Input";

export { Input };
