import { IsBoolean } from "class-validator";
import { Expose, plainToInstance } from "class-transformer";
import { IsNotEmpty } from "class-validator";

export class BuildProjectProgress {
  public static from(steps: BuildProjectProgress): BuildProjectProgress {
    return plainToInstance(BuildProjectProgress, steps, {
      excludeExtraneousValues: true,
    });
  }

  @Expose()
  @IsBoolean()
  @IsNotEmpty()
  quizCompleted!: boolean;

  @Expose()
  @IsBoolean()
  @IsNotEmpty()
  hasMatches!: boolean;
}
