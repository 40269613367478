import { Expose, plainToInstance } from "class-transformer";
import { IsDate, IsEnum, IsNotEmpty, IsString } from "class-validator";
import { ContractorReviewSource } from "./contractor-review-source.enum";

export class ContractorReview {
  public static from(contractorReview: ContractorReview) {
    return plainToInstance(ContractorReview, contractorReview);
  }

  @Expose()
  @IsString()
  @IsNotEmpty()
  id!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  contractorId!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  author!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  summary!: string;

  @Expose()
  @IsDate()
  reviewedTs!: Date;

  @Expose()
  @IsEnum(ContractorReviewSource)
  source!: ContractorReviewSource;

  @Expose()
  @IsDate()
  createdTs!: Date;

  @Expose()
  @IsDate()
  updatedTs!: Date;
}
