"use client";
import { cn } from "../utils";
import { Button } from "./Button";
import { Card, CardFooter } from "./Card";
import { Link } from "./Link";
import * as React from "react";
import { H1 } from "./typography/H1";

export function DesignCardCTA({
  ctaCopy,
  buttonText,
  url,
  className,
}: {
  ctaCopy: string;
  buttonText: string;
  url: string;
  className?: string;
}) {
  return (
    <Card className={cn("bg-primary-light overflow-hidden", className)}>
      <div className="flex h-full w-full flex-col items-center justify-center gap-4 py-8">
        <H1 className="text-brand-primary-dark m-0 w-2/3 p-4 text-center xl:text-6xl">
          {ctaCopy}
        </H1>

        <Button>
          <Link href={url} className="text-nowrap">
            {buttonText}
          </Link>
        </Button>
      </div>
    </Card>
  );
}
