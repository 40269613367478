"use client";

import React, { forwardRef, useEffect, useState } from "react";
import { cn } from "../utils";
import { CardContent, CardHeader, CardTitle } from "./Card";
import { Checkbox, CheckedState } from "./Checkbox";

interface CheckboxCardProps extends React.HTMLAttributes<HTMLDivElement> {
  checked?: boolean;
  onCheckedChange?: (checked: CheckedState) => void;
  disabled?: boolean;
}

export const CheckboxCard = forwardRef<HTMLDivElement, CheckboxCardProps>(
  (
    { children, checked, onCheckedChange, disabled, className, ...props },
    ref,
  ) => {
    const [checkedState, setCheckedState] = useState<CheckedState>(
      checked ?? false,
    );

    useEffect(() => {
      setCheckedState(checked ?? false);
    }, [checked]);

    return (
      <div
        ref={ref}
        className={cn(
          "border-brand-beige-light cursor-pointer rounded-[4px] border bg-transparent p-4 shadow-[0px_2px_2px_0px_rgba(0,0,0,0.05)]",
          className,
        )}
        onClick={(e) => {
          if (!disabled) {
            setCheckedState(!checkedState);
            onCheckedChange?.(!checkedState);
          }
        }}
        {...props}
      >
        <div className="flex flex-row gap-4">
          <Checkbox
            checked={checkedState}
            disabled={disabled}
            className="mt-1"
          />
          <div className="flex flex-col gap-[2px]">{children}</div>
        </div>
      </div>
    );
  },
);

CheckboxCard.displayName = "CheckboxCard";

export const CheckboxCardHeader = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <CardHeader ref={ref} className={cn("space-y-0 p-0", className)} {...props} />
));

CheckboxCardHeader.displayName = "CheckboxCardHeader";

export const CheckboxCardTitle = forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <CardTitle
    ref={ref}
    className={cn("text-brand-primary-dark font-display text-lg", className)}
    {...props}
  />
));

CheckboxCardTitle.displayName = "CheckboxCardTitle";

export const CheckboxCardContent = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <CardContent
    ref={ref}
    className={cn(
      "text-brand-secondary-dark font-body p-0 text-sm opacity-50",
      className,
    )}
    {...props}
  />
));

CheckboxCardContent.displayName = "CheckboxCardContent";
