import { IsNotEmpty, IsString } from "class-validator";

export class FindConversationDto {
  @IsString()
  @IsNotEmpty()
  contractorId!: string;

  @IsString()
  @IsNotEmpty()
  userProfileId!: string;
}
