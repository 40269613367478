export enum ContractorAttribute {
  SUPER_PRO = "SUPER_PRO",
  LICENSED = "LICENSED",
  BONDED = "BONDED",
  INSURED = "INSURED",
  BOOKING_FAST = "BOOKING_FAST",
  RESPONSIVE_COMMUNICATION = "RESPONSIVE_COMMUNICATION",
  PREMIUM_EXPERIENCE = "PREMIUM_EXPERIENCE",
  VALUE_PRO = "VALUE_PRO",
  YARDZEN_APPROVED = "YARDZEN_APPROVED",
  YEARS_IN_BUSINESS = "YEARS_IN_BUSINESS",
  FREE_ESTIMATES = "FREE_ESTIMATES",
  ECO_FRIENDLY = "ECO_FRIENDLY",
  FEMALE_FOUNDED = "FEMALE_FOUNDED",
  BIPOC_OWNED = "BIPOC_OWNED",
  VETERAN_OWNED = "VETERAN_OWNED",
  OWNER_OPERATED = "OWNER_OPERATED",
  FAMILY_OWNED = "FAMILY_OWNED",
}
