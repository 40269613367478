import { Expose, plainToInstance } from "class-transformer";
import { IsNotEmpty, IsString, validateSync } from "class-validator";

export class V2TokenEntity {
  public static from(token: V2TokenEntity): V2TokenEntity {
    const instance = plainToInstance(V2TokenEntity, token, {
      excludeExtraneousValues: true,
      enableCircularCheck: true,
    });

    const errors = validateSync(instance);
    if (errors.length > 0) {
      throw new Error(
        `Validation failed: ${errors.map((error) => Object.values(error.constraints || {})).join(", ")}`,
      );
    }

    return instance;
  }

  @Expose()
  @IsString()
  @IsNotEmpty()
  v2Token!: string;
}
