import { Expose } from "class-transformer";
import { IsOptional, IsString } from "class-validator";

export class CustomerLeadInput {
  @Expose()
  @IsString()
  @IsOptional()
  email?: string;

  @Expose()
  @IsString()
  @IsOptional()
  firstName?: string;

  @Expose()
  @IsString()
  @IsOptional()
  lastName?: string;

  @Expose()
  @IsString()
  @IsOptional()
  leadSource?: string;

  @Expose()
  @IsString()
  @IsOptional()
  segmentId?: string;
}
