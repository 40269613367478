// Design Quiz icons
import AlreadyHiredBuilderIcon from "./already-hired-builder.svg";
import BackyardHouseIcon from "./backyard.svg";
import BeautifulSpaceIcon from "./beautiful-space.svg";
import CactusIcon from "./cactus.svg";
import CalendarAlertIcon from "./calendar-alert.svg";
import CalendarIcon from "./calendar.svg";
import ChallengeYardIcon from "./challenge-yard.svg";
import ColdPlungeIcon from "./cold-plunge.svg";
import DeckIcon from "./deck.svg";
import DiningIcon from "./dining.svg";
import DreamDeckIcon from "./dream-deck.svg";
import DreamingIcon from "./dreaming.svg";
import ExteriorRedesignIcon from "./exterior-redesign.svg";
import ExteriorSurfaceIcon from "./exterior-surface.svg";
import FixIcon from "./fix.svg";
import FrontYardHouseIcon from "./front-yard.svg";
import FunctionalYardIcon from "./functional-yard.svg";
import GarageDoorIcon from "./garage-door.svg";
import GardenIcon from "./garden.svg";
import GuestsIcon from "./guests.svg";
import HardscapingIcon from "./hardscaping.svg";
import HardwareIcon from "./hardware.svg";
import HeritageTreesIcon from "./heritage-trees.svg";
import HotTubIcon from "./hot-tub.svg";
import HousePaintIcon from "./house-paint.svg";
import IrrigationIcon from "./irrigation.svg";
import KidsIcon from "./kids.svg";
import KindaSlopedIcon from "./kinda-sloped.svg";
import KitchenIcon from "./kitchen.svg";
import LacksPrivacyIcon from "./lacks-privacy.svg";
import LandscapingIcon from "./landscaping.svg";
import LifeIntoDateYardIcon from "./life-into-dated-yard.svg";
import LightChallengeIcon from "./light-challenge.svg";
import LightingIcon from "./lighting.svg";
import LittleSlopeIcon from "./little-slope.svg";
import LotOfTreesIcon from "./lot-of-trees.svg";
import MyselfIcon from "./myself.svg";
import NewSpaceYardIcon from "./new-space-yard.svg";
import NoIcon from "./no.svg";
import PatioIcon from "./patio.svg";
import PetDreamYardIcon from "./pet-dream-yard.svg";
import PetsFocusIcon from "./pets-focus.svg";
import PetsNoFocusIcon from "./pets-no-focus.svg";
import PetsIcon from "./pets.svg";
import PlainTerrainIcon from "./plain-terrain.svg";
import PlantsIcon from "./plants.svg";
import PlaygroundIcon from "./playground.svg";
import PrivacyIcon from "./privacy.svg";
import ReadyNowIcon from "./ready-now.svg";
import ReadySoonIcon from "./ready-soon.svg";
import RetainingWallsIcon from "./retaining-walls.svg";
import ReviveIcon from "./revive.svg";
import RoadNoiseIcon from "./road-noise.svg";
import SaunaIcon from "./sauna.svg";
import SeasonalInterestIcon from "./seasonal-interest.svg";
import SideYardIcon from "./side-yard.svg";
import SlopedIcon from "./sloped.svg";
import SpringIcon from "./spring.svg";
import StyleIcon from "./style.svg";
import SwimmingPoolIcon from "./swimming-pool.svg";
// import TreesIcon from "./trees.svg";
import CookingIcon from "./cooking.svg";
import DoorWindowIcon from "./doorswindows.svg";
import EnjoyViewIcon from "./enjoy-view.svg";
import EntertainingIcon from "./entertaining.svg";
import FailingInfraIcon from "./failing-infra.svg";
import FixHardscapeIcon from "./fix-hardscape.svg";
import FromScratchIcon from "./from-scratch.svg";
import LocationIcon from "./locationpin.svg";
import NewBuildIcon from "./new-build.svg";
import PetsSomeFocusIcon from "./pets-some-focus.svg";
import PlayingIcon from "./playing.svg";
import RelaxingIcon from "./relaxing.svg";
import SlopeChallengeIcon from "./slope-challenge.svg";
import SomethingElseChallengeIcon from "./something-else-challenge.svg";
import VerySlopedIcon from "./very-sloped.svg";
import WaterConsumptionIcon from "./water-consumption.svg";
import WaterDrainageIcon from "./water-drainage.svg";
import WellnessIcon from "./wellnes.svg";
import WholeYardIcon from "./whole-yard.svg";
import WithTreesIcon from "./with-trees.svg";
import YardMaintenanceIcon from "./yard-maintenance.svg";
import YesIcon from "./yes.svg";
import YesterdayIcon from "./yesterday.svg";

export {
  AlreadyHiredBuilderIcon,
  BackyardHouseIcon,
  BeautifulSpaceIcon,
  CactusIcon,
  CalendarAlertIcon,
  CalendarIcon,
  ChallengeYardIcon,
  ColdPlungeIcon,
  CookingIcon,
  DeckIcon,
  DiningIcon,
  DoorWindowIcon,
  DreamDeckIcon,
  DreamingIcon,
  EnjoyViewIcon,
  EntertainingIcon,
  ExteriorRedesignIcon,
  ExteriorSurfaceIcon,
  FailingInfraIcon,
  FixHardscapeIcon,
  FixIcon,
  FromScratchIcon,
  FrontYardHouseIcon,
  FunctionalYardIcon,
  GarageDoorIcon,
  GardenIcon,
  GuestsIcon,
  HardscapingIcon,
  HardwareIcon,
  HeritageTreesIcon,
  HotTubIcon,
  HousePaintIcon,
  IrrigationIcon,
  KidsIcon,
  KindaSlopedIcon,
  KitchenIcon,
  LacksPrivacyIcon,
  LandscapingIcon,
  LifeIntoDateYardIcon,
  LightChallengeIcon,
  LightingIcon,
  LittleSlopeIcon,
  LocationIcon,
  LotOfTreesIcon,
  MyselfIcon,
  NewBuildIcon,
  NewSpaceYardIcon,
  NoIcon,
  PatioIcon,
  PetDreamYardIcon,
  PetsFocusIcon,
  PetsIcon,
  PetsNoFocusIcon,
  PetsSomeFocusIcon,
  PlainTerrainIcon,
  PlantsIcon,
  PlaygroundIcon,
  PlayingIcon,
  PrivacyIcon,
  ReadyNowIcon,
  ReadySoonIcon,
  RelaxingIcon,
  RetainingWallsIcon,
  ReviveIcon,
  RoadNoiseIcon,
  SaunaIcon,
  SeasonalInterestIcon,
  SideYardIcon,
  SlopeChallengeIcon,
  SlopedIcon,
  SomethingElseChallengeIcon,
  SpringIcon,
  StyleIcon,
  SwimmingPoolIcon,
  VerySlopedIcon,
  WaterConsumptionIcon,
  WaterDrainageIcon,
  WellnessIcon,
  WholeYardIcon,
  WithTreesIcon,
  YardMaintenanceIcon,
  YesIcon,
  YesterdayIcon,
};
