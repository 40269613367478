"use client";

import { analytics } from "@yardzen/next-segment";
import { useEffect } from "react";

interface PageTrackerProps {
  trackingData: {
    page_name: string;
  };
}

export function PageTracker({ trackingData }: PageTrackerProps) {
  useEffect(() => {
    analytics.page({
      category: "page",
      name: trackingData.page_name,
      properties: {
        pathname: window.location.pathname,
        url: window.location.href,
      },
    });
  }, [trackingData]);

  return null;
}
