"use client";

import * as React from "react";
import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons/faChevronDown";
import { faX } from "@fortawesome/pro-light-svg-icons/faX";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { cn } from "../utils";
import { Button } from "./Button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./Command";

import { Popover, PopoverContent, PopoverTrigger } from "./Popover";

export type ComboboxItem = {
  label: string;
  value: string;
};

export interface ComboboxProps {
  items: ComboboxItem[];
  inputValue?: string;
  selection: string;
  onSelectionChange: (value: string) => void;
  onInputChange?: (value: string) => void;
  placeholder: string;
  className?: string;
}

export function Combobox(props: ComboboxProps) {
  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("justify-between", props.className)}
        >
          {props.selection
            ? props.items.find((item) => item.value === props.selection)?.label
            : props.placeholder}
          <div className="flex flex-shrink flex-row gap-1">
            {!props.selection && (
              <FontAwesomeIcon icon={faChevronDown} className="opacity-50" />
            )}
            {props.selection && (
              <FontAwesomeIcon
                icon={faX}
                className="opacity-50"
                onClick={() => {
                  props.onInputChange?.("");
                  props.onSelectionChange("");
                }}
              />
            )}
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className={cn("bg-white p-0", props.className)}>
        <Command>
          <CommandInput
            placeholder={props.placeholder}
            value={props.inputValue || undefined}
            onValueChange={props.onInputChange}
          />
          <CommandList>
            <CommandEmpty>Not Found</CommandEmpty>
            <CommandGroup>
              {props.items.map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  onSelect={(currentSelection) => {
                    props.onSelectionChange(
                      currentSelection === props.selection
                        ? ""
                        : currentSelection,
                    );
                    setOpen(false);
                  }}
                >
                  {item.label}
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={cn(
                      "ml-auto",
                      props.selection === item.value
                        ? "opacity-100"
                        : "opacity-0",
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
