import React from "react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { forwardRef } from "react";
import { cn } from "../utils";

interface SpinnerProps extends Omit<FontAwesomeIconProps, "icon"> {
  displayMsg?: string;
}

export const Spinner = forwardRef<SVGSVGElement, SpinnerProps>(
  ({ displayMsg, ...props }, ref) => {
    return (
      <div className="flex flex-col items-center justify-center gap-[10px] md:flex-row">
        <FontAwesomeIcon
          ref={ref}
          icon={faSpinner}
          className={cn(
            "text-brand-secondary-regular p-2 opacity-75 transition-all",
            props.className,
          )}
          spin
          size={props.size ?? "2x"}
          {...props}
        />
        {displayMsg && (
          <span className="text-system-grayscale-100 text-center text-base opacity-75">
            {displayMsg}
          </span>
        )}
      </div>
    );
  },
);

Spinner.displayName = "Spinner";
