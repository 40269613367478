import { Transform } from "class-transformer";
import { IsBoolean, IsOptional } from "class-validator";

export class FindOneBuildProjectDto {
  @IsBoolean()
  @IsOptional()
  @Transform(({ value }) => value === "true")
  includeDesignElements?: boolean | null;

  @IsBoolean()
  @IsOptional()
  @Transform(({ value }) => value === "true")
  // Orphan in that they were not assigned to any of the user's build projects yet
  orphanDesignElementsOnly?: boolean | null;

  @IsBoolean()
  @IsOptional()
  @Transform(({ value }) => value === "true")
  includeOverviewImg?: boolean | null;

  @IsBoolean()
  @IsOptional()
  @Transform(({ value }) => value === "true")
  includeAccountManager?: boolean | null;
}
