enum DesignAssignmentStatus {
  NotStarted = "notStarted",
  AssignmentCreated = "assignmentCreated",
  V1InProgress = "v1InProgress",
  V1Delivered = "postV1Hold",
  CorrectionInProgress = "correctionInProgress",
  CorrectionDelivered = "postCorrectionHold",
  V2InProgress = "v2InProgress",
  V2Delivered = "completed",
  V3InProgress = "v3InProgress",
  V3Delivered = "postV3Hold",
  ExtraInProgress = "extraInProgress",
  ExtraDelivered = "postExtraHold",
  Unknown = "unknown",
}

export const DesignAssignmentStatuses = {
  [DesignAssignmentStatus.NotStarted]: "Initial Design Not Started",
  [DesignAssignmentStatus.AssignmentCreated]: "Initial Design in Progress",
  [DesignAssignmentStatus.V1InProgress]: "Initial Design in Progress",
  [DesignAssignmentStatus.V1Delivered]: "Initial Design Delivered",
  [DesignAssignmentStatus.CorrectionInProgress]: "Initial Design in Progress",
  [DesignAssignmentStatus.CorrectionDelivered]: "Initial Design Delivered",
  [DesignAssignmentStatus.V2InProgress]: "Revised Design in Progress",
  [DesignAssignmentStatus.V2Delivered]: "Revised Design Delivered",
  [DesignAssignmentStatus.V3InProgress]: "V3 Design in Progress",
  [DesignAssignmentStatus.V3Delivered]: "V3 Design Delivered",
  [DesignAssignmentStatus.ExtraInProgress]: "V3+ Design In Progress",
  [DesignAssignmentStatus.ExtraDelivered]: "V3+ Design Delivered",
  [DesignAssignmentStatus.Unknown]: "Unknown",
};

export const DesignAssignmentStatusInfo = {
  "Initial Design Not Started": "Waiting for Client to submit materials",
  "Initial Design in Progress":
    "Designer is creating the Client's Initial Design (V1) Renderings",
  "Initial Design Delivered":
    "Client has received their Initial Design (V1) Renderings",
  "Revised Design in Progress":
    "Designer is creating the Client’s Revised Design (V2) Renderings and CAD Plans",
  "Revised Design Delivered":
    "Client has received their Revised Design (V2) Renderings and CAD Plans",
  "V3 Design in Progress":
    "Designer is creating additional Revised Design (V3) Renderings and CAD Plans",
  "V3 Design Delivered":
    "Client has received their Revised Design (V3) Renderings and CAD Plans",
  "V3+ Design In Progress":
    "Designer is creating additional Revised Design (V3+) Renderings and CAD Plans",
  "V3+ Design Delivered":
    "Client has received their Revised Design (V3+) Renderings and CAD Plans",
};

export interface DesignPackageConstants {
  [key: string]: string;
}
export const DesignPackages: DesignPackageConstants = {
  "back yard": "Backyard",
  "front yard": "Front Yard",
  "full yard": "Full Yard",
  "whole home": "Outdoor Transformation",
  "curb appeal": "Curb Appeal",
  "small space": "Small Space",
  botanical: "Botanical",
  "uber premium": "Premium",
  "entire yard starter": "Entire Yard Starter",
  "front or backyard starter": "Front or Backyard Starter",
  "partial yard": "Front or Backyard (Partial yard)",
};

export const formatProductDisplayNameForCheckoutUrl = (productName: string) => {
  const packageNameMap = {
    Package: "",
    "Whole Home": "outdoor-transformation",
    "Uber Premium": "premium",
    "Starter - Full Yard": "entire-yard-starter",
    "Starter - Front or Backyard": "front-or-backyard-starter",
  };
  return Object.entries(packageNameMap)
    .reduce((name, [key, value]) => name.replace(key, value), productName)
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-");
};
