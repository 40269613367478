import { TypeLinkCardSkeleton } from "@contentful/types";
import {
  faArrowRightFromBracket,
  faComment,
  faPhone,
  faUser,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SegmentFlows } from "@yardzen/next-client-util";
import { Entry } from "contentful";
import { ComponentProps, forwardRef } from "react";
import { cn } from "../../utils";
import { Button } from "../Button";
import { Link } from "../Link";

const IconLink: React.FC<{
  href: string;
  title: string;
  icon: ComponentProps<typeof FontAwesomeIcon>["icon"];
  badge?: number;
  className?: string;
  target?: string;
}> = ({ href, title, icon, badge, className, target }) => (
  <Button
    variant="outline"
    size="icon"
    className="relative !h-7 !w-7 !p-0"
    asChild
  >
    <Link
      href={href}
      title={title}
      className={cn(className)}
      tracking={{
        button_name: title,
        button_content: title,
        flow_name: SegmentFlows.TOP_NAV,
      }}
      target={target ? target : undefined}
    >
      <FontAwesomeIcon icon={icon} />
      {badge !== undefined && badge > 0 && (
        <span className="bg-brand-primary-light absolute -right-1 -top-1 flex h-5 w-5 items-center justify-center rounded-full text-xs text-white">
          {badge > 99 ? "99+" : badge}
        </span>
      )}
    </Link>
  </Button>
);

const SignInIconLink: React.FC<{ className?: string; href?: string }> = ({
  className,
  href,
}) => (
  <IconLink
    href={href || "https://project.yardzen.com/login"}
    title="Log In"
    icon={faUser}
    className={className}
  />
);

const SignOutIconLink: React.FC<{ className?: string }> = ({ className }) => (
  <IconLink
    href="https://project.yardzen.com/api/auth/signout"
    title="Log Out"
    icon={faArrowRightFromBracket}
    className={className}
  />
);

export const UserActions = forwardRef<
  HTMLDivElement,
  {
    loggedIn: boolean;
    className?: string;
    unreadMessageCount?: number;
    contentfulCtaButton?: Entry<
      TypeLinkCardSkeleton,
      "WITHOUT_UNRESOLVABLE_LINKS",
      string
    >;
    contentfulLoginButton?: Entry<
      TypeLinkCardSkeleton,
      "WITHOUT_UNRESOLVABLE_LINKS",
      string
    >;
    isDesignConsultation?: boolean;
  }
>(
  (
    {
      loggedIn,
      className,
      unreadMessageCount,
      contentfulCtaButton,
      contentfulLoginButton,
      isDesignConsultation,
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={cn("flex shrink items-center justify-end gap-3", className)}
      >
        {!loggedIn && (
          <IconLink
            className="hidden md:inline-flex"
            href="https://calendly.com/d/cqks-48t-ckd/project-consultation-with-yardzen"
            title="Schedule A Call"
            icon={faPhone}
            target="_blank"
          />
        )}
        {loggedIn && (
          <IconLink
            href="https://project.yardzen.com/messages"
            title="Messages"
            icon={faComment}
            badge={unreadMessageCount}
          />
        )}
        {loggedIn ? (
          <SignOutIconLink />
        ) : (
          <SignInIconLink
            className="hidden md:inline-flex"
            href={contentfulLoginButton?.fields.url}
          />
        )}

        {!loggedIn && !isDesignConsultation && (
          <Button size="sm" asChild>
            <Link
              className="hidden whitespace-nowrap text-xs font-medium max-[429px]:py-3 max-[390px]:px-3 max-[365px]:whitespace-normal max-[365px]:px-6 max-[365px]:leading-3 min-[360px]:inline-flex"
              href={
                contentfulCtaButton?.fields.url ||
                "https://dashboard.yardzen.com/design-profile/"
              }
              tracking={{
                button_name: "Nav Bar Start a Project",
                button_content:
                  contentfulCtaButton?.fields.title || "Start a Project",
                flow_name: SegmentFlows.TOP_NAV,
              }}
            >
              {contentfulCtaButton?.fields.title || "Start a Project"}
            </Link>
          </Button>
        )}
      </div>
    );
  },
);

UserActions.displayName = "UserActions";

export const UserActionsFloating = forwardRef<
  HTMLDivElement,
  {
    loggedIn: boolean;
    className?: string;
    unreadMessageCount?: number;
    contentfulCtaButton?: Entry<
      TypeLinkCardSkeleton,
      "WITHOUT_UNRESOLVABLE_LINKS",
      string
    >;
    contentfulLoginButton?: Entry<
      TypeLinkCardSkeleton,
      "WITHOUT_UNRESOLVABLE_LINKS",
      string
    >;
    isDesignConsultation?: boolean;
    isDesignProfileQuizEnabled?: boolean;
  }
>(
  (
    {
      loggedIn,
      className,
      unreadMessageCount,
      contentfulCtaButton,
      contentfulLoginButton,
      isDesignConsultation,
      isDesignProfileQuizEnabled,
    },
    ref,
  ) => {
    const designQuizUrl = isDesignProfileQuizEnabled
      ? "https://project.yardzen.com/design-consultation"
      : "https://dashboard.yardzen.com/design-profile/find-your-package";

    return (
      <div
        ref={ref}
        className={cn("flex shrink items-center justify-end gap-3", className)}
      >
        {loggedIn ? (
          <>
            <Button size="default" variant="secondary" asChild>
              <Link
                href="https://dashboard.yardzen.com/"
                tracking={{
                  button_name: "My Account",
                  button_content: "My Account",
                  flow_name: SegmentFlows.TOP_NAV,
                }}
              >
                My Account
              </Link>
            </Button>
            <Button size="default" variant="secondary" asChild>
              <Link
                href="https://project.yardzen.com/api/auth/signout"
                tracking={{
                  button_name: "Log Out",
                  button_content: "Log Out",
                  flow_name: SegmentFlows.TOP_NAV,
                }}
              >
                Logout
              </Link>
            </Button>
          </>
        ) : (
          <Button
            size="default"
            variant="secondary"
            className="hidden sm:inline-flex"
            asChild
          >
            <Link
              href={
                contentfulLoginButton?.fields.url ||
                "https://project.yardzen.com/login"
              }
              tracking={{
                button_name: "Log In",
                button_content: "Log In",
                flow_name: SegmentFlows.TOP_NAV,
              }}
            >
              {contentfulLoginButton?.fields.title || "Login"}
            </Link>
          </Button>
        )}

        {!loggedIn && !isDesignConsultation && (
          <Button size="default" variant="zest" asChild>
            <Link
              className="bg-brand-tertiary-50 text-brand-primary-darker hidden whitespace-nowrap text-center font-medium max-[600px]:text-xs max-[450px]:text-xs max-[429px]:py-3 max-[390px]:px-3 max-[365px]:px-6 max-[365px]:leading-3 min-[365px]:inline-flex sm:text-base"
              href={designQuizUrl}
              tracking={{
                button_name: "Nav Bar Start A Consultation",
                button_content:
                  contentfulCtaButton?.fields.title || "Start A Consultation",
                flow_name: SegmentFlows.TOP_NAV,
              }}
            >
              {contentfulCtaButton?.fields.title || "Start A Consultation"}
            </Link>
          </Button>
        )}
      </div>
    );
  },
);

UserActionsFloating.displayName = "UserActionsFloating";
