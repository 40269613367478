export const trendingCities: { href: string; title: string }[] = [
  {
    href: "#",
    title: "Trending Cities",
  },
  {
    href: "/phoenix-landscape-design",
    title: "Phoenix, AZ Landscape Design",
  },
  {
    href: "/tucson-landscape-design",
    title: "Tucson, AZ Landscape Design",
  },
  {
    href: "/huntington-beach-landscape-design",
    title: "Huntington Beach, CA Landscape Design",
  },
  {
    href: "/los-angeles-landscape-design",
    title: "Los Angeles, CA Landscape Design",
  },
  {
    href: "/mill-valley-landscape-design",
    title: "Mill Valley, CA Landscape Design",
  },
  {
    href: "/napa-landscape-design",
    title: "Napa Valley, CA Landscape Design",
  },
  {
    href: "/oakland-landscape",
    title: "Oakland, CA Landscape Design",
  },
  {
    href: "/palo-alto-landscape-design",
    title: "Palo Alto, CA Landscape Design",
  },
  {
    href: "/sacramento-landscape-design",
    title: "Sacramento, CA Landscape Design",
  },
  {
    href: "/san-diego-landscape-design",
    title: "San Diego, CA Landscape Design",
  },
  {
    href: "/san-jose-landscape-design",
    title: "San Jose, CA Landscape Design",
  },
  {
    href: "/landscape-design-in-san-francisco-ca",
    title: "San Francisco, CA Landscape Design",
  },
  {
    href: "/thousand-oaks-landscape-design",
    title: "Thousand Oaks, CA Landscape Design",
  },
  {
    href: "/ventura-landscape-design",
    title: "Ventura, CA Landscape Design",
  },
  {
    href: "/aurora-landscape-design",
    title: "Aurora, CO Landscape Design",
  },
  {
    href: "/denver-landscape-design",
    title: "Denver, CO Landscape Design",
  },
  {
    href: "/lakewood-landscape-design",
    title: "Lakewood, CO Landscape Design",
  },
  {
    href: "/bridgeport-landscape-design",
    title: "Bridgeport, CT Landscape Design",
  },
  {
    href: "/greenwich-landscape-design",
    title: "Greenwich, CT Landscape Design",
  },
  {
    href: "/new-canaan-landscape-design",
    title: "New Canaan, CT Landscape Design",
  },
  {
    href: "/stamford-landscape-design",
    title: "Stamford, CT Landscape Design",
  },
  {
    href: "/landscape-design-in-washington-dc",
    title: "Washington, DC Landscape Design",
  },
  {
    href: "/miami-landscape-design",
    title: "Miami, FL Landscape Design",
  },
  {
    href: "/orlando-landscape-design",
    title: "Orlando, FL Landscape Design",
  },
  {
    href: "/palm-beach-landscape-design",
    title: "Palm Beach, FL Landscape Design",
  },
  {
    href: "/tampa-landscape-design",
    title: "Tampa, FL Landscape Design",
  },
  {
    href: "/winter-park-landscape-design",
    title: "Winter Park, FL Landscape Design",
  },
  {
    href: "/atlanta-landscape-design",
    title: "Atlanta, GA Landscape Design",
  },
  {
    href: "/landscape-design-in-roswell-ga",
    title: "Roswell, GA Landscape Design",
  },
  {
    href: "/landscape-design-in-twin-falls",
    title: "Twin Falls, ID Landscape Design",
  },
  {
    href: "/landscape-design-in-joliet",
    title: "Joliet, IL Landscape Design",
  },
  {
    href: "/landscape-design-boston",
    title: "Boston, MA Landscape Design",
  },
  {
    href: "/landscape-design-in-cambridge",
    title: "Cambridge, MA Landscape Design",
  },
  {
    href: "/potomac-landscape-design",
    title: "Potomac, MD Landscape Design",
  },
  {
    href: "/landscape-design-in-portland-me",
    title: "Portland, ME Landscape Design",
  },
  {
    href: "/landscape-design-in-st-charles",
    title: "St. Charles, MO Landscape Design",
  },
  {
    href: "/landscape-design-in-burlington-nc",
    title: "Burlington, NC Landscape Design",
  },
  {
    href: "/landscape-design-in-charlotte",
    title: "Charlotte, NC Landscape Design",
  },
  {
    href: "/landscape-design-in-ocean-city",
    title: "Ocean City, NJ Landscape Design",
  },
  {
    href: "/las-vegas-landscape-design",
    title: "Las Vegas, NV Landscape Design",
  },
  {
    href: "/landscape-design-in-long-island",
    title: "Long Island, NY Landscape Design",
  },
  {
    href: "/portland-landscape-design",
    title: "Portland, OR Landscape Design",
  },
  {
    href: "/landscape-design-in-nashville",
    title: "Nashville, TN Landscape Design",
  },
  {
    href: "/landscape-design-austin",
    title: "Austin, TX Landscape Design",
  },
  {
    href: "/dallas-landscape-design",
    title: "Dallas, TX Landscape Design",
  },
  {
    href: "/houston-landscape-design",
    title: "Houston, TX Landscape Design",
  },
  {
    href: "/san-antonio-landscape-design",
    title: "San Antonio, TX Landscape Design",
  },
  {
    href: "/landscape-design-in-park-city",
    title: "Park City, UT Landscape Design",
  },
  {
    href: "/arlington-landscape-design",
    title: "Arlington, VA Landscape Design",
  },
  {
    href: "/bellevue-landscape-design",
    title: "Bellevue, WA Landscape Design",
  },
  {
    href: "/seattle-landscape-design",
    title: "Seattle, WA Landscape Design",
  },
  {
    href: "/tacoma-landscape-design",
    title: "Tacoma, WA Landscape Design",
  },
  {
    href: "/landscape-design-in-milwaukee",
    title: "Milwaukee, WI Landscape Design",
  },
  {
    href: "/landscape-design-in-cheyenne-wy",
    title: "Cheyenne, WY Landscape Design",
  },
];
