import React from "react";
import { cn } from "@yardzen/next-client-util";

export const Muted: React.FC<React.HTMLAttributes<HTMLParagraphElement>> = ({
  children,
  className,
  ...props
}) => (
  <p
    className={cn("text-muted-foreground font-body text-sm", className)}
    {...props}
  >
    {children}
  </p>
);
