import React from "react";
import { cn } from "../../utils";

export const H6: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({
  children,
  className,
  ...props
}) => (
  <h6
    className={cn(
      "font-display scroll-m-20 text-[22px] tracking-tight",
      className,
    )}
    {...props}
  >
    {children}
  </h6>
);
