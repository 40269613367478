import { FindOneMatchDto } from "@yardzen/api-dtos";
import { getMatch } from "@yardzen/rest-api-client";
import useSWR from "swr";

export const useMatch = (
  accountId: string,
  buildProjectId: string,
  contractorId: string,
  additionalOptions?: FindOneMatchDto,
) => {
  const { data, error, isLoading } = useSWR(
    accountId && buildProjectId && contractorId
      ? `/accounts/${accountId}/build-projects/${buildProjectId}/matches/${contractorId}`
      : null,
    accountId && buildProjectId && contractorId
      ? () =>
          getMatch(accountId, buildProjectId, contractorId, additionalOptions)
      : null,
  );

  return {
    match: data?.data,
    isLoading,
    isError: error,
  };
};
