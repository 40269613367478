import { Button } from "./Button";
import {
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./Card";
import { Icon, isIconName } from "./Icon";
import { Link } from "./Link";

export function PackageTeaserCard({
  segmentFlow,
  heading,
  description,
  price,
  promoPrice,
  pricePresentationClass,
  ctaUrl,
  ctaButtonText,
  icon1,
  icon2,
  displayDescription,
}: {
  segmentFlow: string;
  heading?: string;
  description?: React.ReactNode;
  price?: number;
  promoPrice?: number;
  pricePresentationClass?: string;
  ctaUrl?: string;
  ctaButtonText?: string;
  icon1?: string;
  icon2?: string;
  displayDescription?: boolean;
}) {
  return (
    <Card className="flex w-full flex-1 flex-col rounded-lg bg-white bg-opacity-50 p-3">
      <CardHeader className="h-full px-4 pb-1 pt-4">
        <div className="flex h-full flex-row md:flex-col">
          <div className="mr-3 flex shrink-0 flex-row items-start gap-2 md:mb-4 md:items-center">
            {icon1 && isIconName(icon1) && (
              <div className="bg-brand-moss-greens-10 flex w-fit shrink-0 items-center rounded-full p-2">
                <Icon
                  name={icon1}
                  className="text-brand-primary-regular h-[22px] w-[22px]"
                />
              </div>
            )}
            {icon2 && isIconName(icon2) && (
              <div className="bg-brand-greens-light relative -left-4 -mr-3 flex w-fit shrink-0 items-center rounded-full p-2">
                <Icon
                  name={icon2}
                  className="text-brand-primary-regular h-[24px] w-[24px]"
                />
              </div>
            )}
          </div>
          <div className="flex h-full flex-col">
            {heading && (
              <CardTitle className="font-display text-brand-secondary-dark text-left text-3xl font-normal">
                {heading}
              </CardTitle>
            )}
            {displayDescription && description}
            {price && (
              <CardDescription className={pricePresentationClass}>
                {promoPrice ? (
                  <>
                    <span className="line-through">${price}</span>{" "}
                    <b className="text-brand-primary-darker ml-1">
                      ${promoPrice}
                    </b>
                  </>
                ) : (
                  <b className="text-brand-primary-darker">${price}</b>
                )}
              </CardDescription>
            )}
          </div>
        </div>
      </CardHeader>
      <CardFooter className="flex flex-1 items-end px-0 pb-2 md:pt-2">
        <Button
          className="mx-2 w-full"
          asChild
          tracking={{
            button_name: "Package Teaser CTA",
            button_content: ctaButtonText,
            flow_name: segmentFlow,
          }}
        >
          <Link
            className="hidden whitespace-nowrap font-medium max-[429px]:py-2 max-[390px]:px-3 max-[365px]:whitespace-normal max-[365px]:px-6 max-[365px]:leading-3 min-[360px]:inline-flex"
            href={ctaUrl}
          >
            {ctaButtonText}
          </Link>
        </Button>
      </CardFooter>
    </Card>
  );
}
