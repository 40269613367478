import React from "react";
import { cn } from "../utils";
import { cva, type VariantProps } from "class-variance-authority";

const pageVariants = cva("px-4", {
  variants: {
    bg: {
      white: "bg-white",
      transparent: "bg-transparent",
      "beige-lighter": "bg-brand-beige-lighter",
      primary: "bg-brand-primary-dark",
    },
  },
  defaultVariants: {
    bg: "transparent",
  },
});

export interface PageProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof pageVariants> {}

export const Page = React.forwardRef<HTMLDivElement, PageProps>(
  ({ children, className, bg, ...props }, ref) => (
    <div ref={ref} className={cn(pageVariants({ bg }), className)} {...props}>
      {children}
    </div>
  ),
);

Page.displayName = "Page";
