import React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "../utils";

const sectionVariants = cva("", {
  variants: {
    bg: {
      white: "bg-white",
      transparent: "bg-transparent",
      "beige-lighter": "bg-brand-beige-lighter",
      primary: "bg-brand-primary-dark",
    },
    padding: {
      none: "p-0",
      sm: "p-4",
      md: "p-6",
      lg: "p-8",
      xl: "p-12",
    },
  },
  defaultVariants: {
    bg: "transparent",
    padding: "none",
  },
});

export interface SectionProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof sectionVariants> {}

export const Section = React.forwardRef<HTMLElement, SectionProps>(
  ({ className, bg, padding, ...props }, ref) => {
    return (
      <section
        ref={ref}
        className={cn(sectionVariants({ bg, padding }), className)}
        {...props}
      />
    );
  },
);

Section.displayName = "Section";
