import * as faIcons from "@fortawesome/pro-light-svg-icons";
import {
  IconName as FontAwesomeIconName,
  IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cva, type VariantProps } from "class-variance-authority";
import React, { forwardRef } from "react";
import { kebabToCamel, uppercaseFirst } from "../utils";
import {
  faIconsSet,
  pngIcons,
  pngIconsSet,
  reactIcons,
  reactIconsSet,
  svgIcons,
  svgIconsSet,
} from "./IconComponents";

// Export all available icon names for external reference
export const availableIcons = [
  ...svgIconsSet,
  ...faIconsSet,
  ...reactIconsSet,
  ...pngIconsSet,
] as const;

export type IconName =
  | keyof typeof svgIcons
  | keyof typeof reactIcons
  | keyof typeof pngIcons
  | FontAwesomeIconName;

export function isIconName(name: string): name is IconName {
  if (
    svgIconsSet.has(name) ||
    faIconsSet.has(name) ||
    reactIconsSet.has(name) ||
    pngIconsSet.has(name)
  ) {
    return true;
  }
  return false;
}

const iconVariants = cva("inline-block", {
  variants: {
    size: {
      sm: "w-4 h-4",
      md: "w-6 h-6",
      lg: "w-8 h-8",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

export interface IconProps extends VariantProps<typeof iconVariants> {
  name: IconName;
  className?: string;
}

function getFaIconName(icon: IconName) {
  return `fa${uppercaseFirst(kebabToCamel(icon))}`;
}

type IconType = "svg" | "fa" | "react" | "png";

const getIconType = (name: IconName): IconType | null => {
  if (svgIconsSet.has(name)) return "svg";
  if (faIconsSet.has(name)) return "fa";
  if (reactIconsSet.has(name)) return "react";
  if (pngIconsSet.has(name)) return "png";
  console.warn(`Icon ${name} not found`);

  return null;
};

const getIconSet = (
  type: IconType,
): Record<string, string | { src: string }> | null => {
  switch (type) {
    case "svg":
      return svgIcons as Record<string, string>;
    case "png":
      return pngIcons;
    default:
      return null;
  }
};

export const Icon = forwardRef<SVGSVGElement | HTMLImageElement, IconProps>(
  ({ name, size, className }: IconProps, ref) => {
    const type = getIconType(name);
    if (!type) return null;

    const set = getIconSet(type);
    const classes = iconVariants({ size, className });

    if (type === "fa") {
      return (
        <FontAwesomeIcon
          icon={
            (faIcons as unknown as Record<string, IconDefinition>)[
              getFaIconName(name as IconName)
            ]
          }
          ref={ref as React.Ref<SVGSVGElement>}
          className={classes}
        />
      );
    }

    if (type === "react") {
      const ReactIcon = reactIcons[name as keyof typeof reactIcons];
      return <ReactIcon className={classes} />;
    }

    return (
      <img
        src={typeof set?.[name] === "string" ? set?.[name] : set?.[name]?.src}
        alt={name}
        ref={ref as React.Ref<HTMLImageElement>}
        className={classes}
      />
    );
  },
);
