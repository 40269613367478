import * as React from "react";
import * as SeparatorPrimitive from "@radix-ui/react-separator";
import { cn } from "../utils";

interface LineDividerProps
  extends React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root> {
  variant?: "green" | "dotted";
}

const LineDivider = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  LineDividerProps
>(
  (
    {
      className,
      orientation = "horizontal",
      decorative = true,
      variant = "green",
      ...props
    },
    ref,
  ) => (
    <SeparatorPrimitive.Root
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      className={cn(
        orientation === "horizontal" ? "h-[1px] w-full" : "h-full w-[1px]",
        variant === "green" && "bg-brand-moss-greens-10/50",
        variant === "dotted" &&
          "border-system-opacity-20 border-0 border-dashed",
        variant === "dotted" && orientation === "horizontal" && "border-b",
        variant === "dotted" && orientation === "vertical" && "border-l",
        className,
      )}
      {...props}
    />
  ),
);
LineDivider.displayName = SeparatorPrimitive.Root.displayName;

export { LineDivider };
