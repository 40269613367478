import { Expose, Type } from "class-transformer";
import {
  IsArray,
  IsNotEmpty,
  IsOptional,
  IsString,
  ValidateNested,
} from "class-validator";
import { SurveyQuestionResponse } from "./survey-question-response.entity";

export class UpsertSurveyQuestionResponsesOutput {
  @Expose()
  @IsArray()
  @IsNotEmpty()
  @ValidateNested({ each: true })
  @Type(() => SurveyQuestionResponse)
  responses: SurveyQuestionResponse[] = [];

  @Expose()
  @IsString()
  @IsOptional()
  nextQuestionKey?: string;
}
