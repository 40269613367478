import React, { forwardRef, SVGProps } from "react";

export const PartyPopper = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(
  (props, ref) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width="86"
        height="87"
        viewBox="0 0 86 87"
        fill="none"
        {...props}
      >
        <g clipPath="url(#clip0_1984_12903)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.693 58.0548L24.2508 64.6668C23.526 64.8536 22.7565 64.6435 22.2272 64.1142C21.698 63.585 21.4879 62.8155 21.6747 62.0907L28.2866 38.6484L47.693 58.0548Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.1641 19.8369L40.6148 19.9657C40.305 19.9658 40.0223 19.7889 39.8867 19.5102C39.7512 19.2316 39.7866 18.9 39.978 18.6562L42.182 15.6794C42.3466 15.4604 42.3971 15.1761 42.3179 14.9138L41.173 11.4647C41.0827 11.165 41.1645 10.8399 41.3859 10.6185C41.6072 10.3972 41.9323 10.3154 42.232 10.4056L45.6167 11.4862C45.8739 11.573 46.1577 11.5217 46.3681 11.3502L49.2805 9.11045C49.5235 8.92109 49.8544 8.8904 50.1281 9.03185C50.4018 9.1733 50.5681 9.46103 50.5542 9.76878L50.4325 13.4397C50.421 13.7092 50.5444 13.9668 50.7617 14.1266L53.6383 16.2018C53.8838 16.3953 54.0033 16.7079 53.9494 17.0157C53.8956 17.3236 53.6772 17.5771 53.3807 17.6759L49.9531 18.8137C49.6964 18.8993 49.4949 19.1007 49.4093 19.3575L48.2858 22.871C48.2012 23.1682 47.9548 23.3914 47.6506 23.4461C47.3464 23.5009 47.0376 23.3777 46.8547 23.1286L44.8439 20.1589C44.6888 19.9406 44.4313 19.8186 44.1641 19.8369Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.8661 44.0962L58.9966 41.9567C58.7524 41.7633 58.6407 41.4471 58.7094 41.1433C58.7781 40.8395 59.015 40.6021 59.3186 40.5327L62.8893 39.5666C63.1513 39.4829 63.3566 39.2776 63.4403 39.0156L64.7713 35.6667C64.8836 35.3736 65.1482 35.1662 65.4597 35.1273C65.7712 35.0884 66.0787 35.2242 66.2597 35.4807L68.1774 38.4718C68.3292 38.6943 68.5807 38.8281 68.8501 38.8296L72.521 38.8797C72.8308 38.8796 73.1121 39.0608 73.2401 39.343C73.368 39.6252 73.3191 39.9561 73.1149 40.1892L70.7392 42.9942C70.561 43.1951 70.4988 43.4737 70.5746 43.7313L71.5406 47.1446C71.6138 47.45 71.5126 47.7709 71.2775 47.9791C71.0423 48.1873 70.7115 48.2489 70.4172 48.1392L67.0182 46.9013C66.7596 46.8077 66.4716 46.845 66.2454 47.0015L63.1756 49.0552C62.9217 49.2362 62.5872 49.2575 62.3124 49.1103C62.0377 48.963 61.8702 48.6726 61.8804 48.3611L62.1738 44.776C62.1954 44.5115 62.079 44.2546 61.8661 44.0962Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.2861 38.6486L21.6742 62.0909C21.5188 62.7288 21.6656 63.4028 22.0722 63.9183C22.4788 64.4339 23.0999 64.7337 23.7565 64.7314C23.7536 64.5645 23.7753 64.3981 23.8209 64.2376L30.4328 40.7954L31.1055 38.4053C31.3379 37.75 31.8906 37.2599 32.569 37.1074C33.2474 36.9548 33.9566 37.1612 34.4472 37.654L32.3005 35.5073C31.8099 35.0145 31.1006 34.8081 30.4222 34.9606C29.7438 35.1132 29.1912 35.6033 28.9587 36.2586L28.2861 38.6486Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.1732 11.4649L42.2537 14.9211C42.333 15.1835 42.2825 15.4678 42.1178 15.6868L39.9138 18.6636C39.7225 18.9073 39.687 19.2389 39.8225 19.5176C39.9581 19.7963 40.2408 19.9731 40.5507 19.9731L42.726 19.9159L44.2645 17.8335C44.4292 17.6145 44.4797 17.3302 44.4005 17.0679L43.3199 13.6116C43.2297 13.3119 43.3115 12.9868 43.5328 12.7655C43.7542 12.5441 44.0793 12.4623 44.379 12.5526L47.735 13.676C48.0082 13.7624 48.3068 13.6939 48.515 13.4972L50.4829 11.9873L50.5544 9.769C50.5684 9.46126 50.402 9.17352 50.1283 9.03207C49.8546 8.89063 49.5237 8.92132 49.2807 9.11068L46.3683 11.3504C46.1579 11.5219 45.8741 11.5733 45.6169 11.4864L42.2609 10.3629C41.9479 10.2476 41.5963 10.326 41.3619 10.5634C41.1276 10.8008 41.0538 11.1534 41.1732 11.4649Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.9175 53.9839L24.8653 54.532L22.6728 62.3232L20.725 61.7751L22.9175 53.9839Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.0891 58.9001C21.6939 58.5049 21.6939 57.8642 22.0891 57.469C22.4843 57.0738 23.1251 57.0738 23.5203 57.469C23.9155 57.8642 23.9155 58.5049 23.5203 58.9001C23.1251 59.2953 22.4843 59.2953 22.0891 58.9001Z"
            fill="#6E8656"
          />
          <path
            d="M30.0525 33.9634C29.0537 34.231 28.2736 35.0112 28.006 36.0099L22.9039 53.9923C22.7557 54.5357 23.0761 55.0964 23.6195 55.2446C24.1629 55.3928 24.7235 55.0724 24.8717 54.529L28.8074 40.5753L45.7665 57.5344L23.9987 63.6741C23.6291 63.7418 23.2497 63.6236 22.984 63.3579C22.7182 63.0921 22.6 62.7128 22.6678 62.3431C22.816 61.7997 22.4956 61.2391 21.9522 61.0909C21.4088 60.9427 20.8481 61.263 20.6999 61.8064C20.414 62.8866 20.7242 64.0374 21.5143 64.8275C22.3044 65.6177 23.4552 65.9279 24.5354 65.6419L50.3748 58.4074C51.3736 58.1398 52.1538 57.3597 52.4214 56.3609C52.7149 55.2836 52.3934 54.132 51.5842 53.3626L33.015 34.7935C32.2512 33.9999 31.1175 33.6822 30.0525 33.9634ZM50.4321 55.7741C50.3545 56.0802 50.1156 56.3191 49.8095 56.3967L48.0063 56.9405L29.4013 38.3356L29.9165 36.518C29.9941 36.212 30.2331 35.973 30.5391 35.8954C30.9106 35.8109 31.2992 35.9277 31.5624 36.2031L50.153 54.7938C50.4035 55.0566 50.5055 55.4273 50.4249 55.7813L50.4321 55.7741Z"
            fill="#6E8656"
          />
          <path
            d="M54.9256 17.1045C55.0366 16.4303 54.7567 15.7513 54.2028 15.3513L51.4765 13.3978L51.5624 9.8056C51.5989 9.1038 51.2171 8.44678 50.5892 8.13115C49.9682 7.80709 49.2149 7.87709 48.6643 8.31005L45.8163 10.4711L42.5819 9.41203C41.9338 9.19372 41.2181 9.35677 40.7286 9.83422C40.2195 10.3287 40.0339 11.0686 40.2491 11.7448L41.301 15.1152L39.1471 18.0276C38.7156 18.5962 38.6514 19.363 38.9825 19.9954C39.3001 20.6042 39.9425 20.9729 40.6284 20.94L44.0417 20.8326L45.9737 23.6949C46.3558 24.2658 47.0335 24.5642 47.7126 24.4606C48.4166 24.3583 48.9988 23.8597 49.2081 23.1797L50.3459 19.7521L53.7019 18.6286C54.3695 18.3988 54.8455 17.8059 54.9256 17.1045ZM45.6374 19.5804C45.2954 19.0747 44.716 18.7823 44.1061 18.8075L40.9718 18.8934L42.9397 16.2243C43.2923 15.7357 43.3882 15.1071 43.1973 14.5356L42.267 11.43L45.2868 12.4318C45.8636 12.6384 46.506 12.5358 46.9898 12.1598L49.5301 10.2063L49.43 13.4121C49.41 14.0181 49.688 14.5954 50.1741 14.9577L52.7717 16.8254L49.6232 17.8558C49.0609 18.047 48.6194 18.4886 48.4281 19.0508L47.4192 22.2065L45.6374 19.5804Z"
            fill="#6E8656"
          />
          <path
            d="M57.7092 40.9975C57.5701 41.669 57.8358 42.3598 58.389 42.765L61.1511 44.8258L60.9293 48.3107C60.8728 48.9905 61.2163 49.6411 61.8095 49.978C62.4225 50.346 63.1937 50.3237 63.7845 49.9207L66.797 47.8957L70.1244 49.105C70.7738 49.3487 71.5048 49.2092 72.0187 48.7435C72.5327 48.2778 72.7433 47.564 72.5646 46.8938L71.5985 43.5664L73.9098 40.8401C74.3663 40.306 74.4749 39.5569 74.1889 38.9152C73.8978 38.2788 73.2644 37.8686 72.5646 37.8633L68.9581 37.8204L67.1333 34.9509C66.7549 34.3802 66.0896 34.0683 65.4088 34.1423C64.7074 34.2148 64.1053 34.6732 63.8489 35.3302L62.5751 38.5932L59.0688 39.5377C58.3699 39.7178 57.8392 40.2875 57.7092 40.9975ZM64.4142 39.4161L65.6163 36.3248L67.3265 39.0511C67.6608 39.5559 68.2239 39.8616 68.8293 39.8669L72.0422 39.917L69.967 42.3643C69.5748 42.8271 69.4356 43.4533 69.5949 44.0387L70.4679 47.1014L67.3337 45.9421C66.775 45.7543 66.1606 45.8391 65.6736 46.1711L62.9186 48.0101L63.1691 44.8974C63.2194 44.2877 62.9516 43.6952 62.4606 43.3303L59.9418 41.4555L63.1261 40.5753C63.7159 40.4167 64.1945 39.986 64.4142 39.4161Z"
            fill="#6E8656"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M74.8967 44.7761C74.8956 45.046 75.0023 45.3051 75.1931 45.4959C75.3839 45.6867 75.643 45.7934 75.9128 45.7922L76.335 45.7851L76.3279 46.2073C76.3268 46.4771 76.4335 46.7362 76.6243 46.927C76.8151 47.1178 77.0741 47.2245 77.344 47.2234C77.6138 47.2245 77.8729 47.1178 78.0637 46.927C78.2545 46.7362 78.3612 46.4771 78.3601 46.2073L78.3529 45.7851L78.7751 45.7922C79.1382 45.7922 79.4736 45.5986 79.6551 45.2842C79.8366 44.9698 79.8366 44.5825 79.6551 44.2681C79.4736 43.9537 79.1382 43.76 78.7751 43.76L78.3529 43.7672L78.3601 43.345C78.3601 42.7838 77.9052 42.3289 77.344 42.3289C76.7828 42.3289 76.3279 42.7838 76.3279 43.345L76.335 43.7672L75.9128 43.76C75.643 43.7589 75.3839 43.8656 75.1931 44.0564C75.0023 44.2472 74.8956 44.5063 74.8967 44.7761Z"
            fill="#6E8656"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.8765 27.6013C46.8776 27.3315 46.7709 27.0724 46.5801 26.8816C46.3893 26.6908 46.1302 26.5841 45.8604 26.5852L45.4382 26.5924L45.4453 26.1702C45.4453 25.8071 45.2516 25.4717 44.9373 25.2902C44.6229 25.1087 44.2355 25.1087 43.9211 25.2902C43.6068 25.4717 43.4131 25.8071 43.4131 26.1702L43.4202 26.5924L42.998 26.5852C42.7282 26.5841 42.4691 26.6908 42.2783 26.8816C42.0875 27.0724 41.9808 27.3315 41.9819 27.6013C41.9808 27.8711 42.0875 28.1302 42.2783 28.321C42.4691 28.5118 42.7282 28.6185 42.998 28.6174L43.4202 28.6103L43.4131 29.0325C43.412 29.3023 43.5187 29.5614 43.7095 29.7522C43.9003 29.943 44.1594 30.0497 44.4292 30.0486C44.699 30.0497 44.9581 29.943 45.1489 29.7522C45.3397 29.5614 45.4464 29.3023 45.4453 29.0325L45.4382 28.6103L45.8604 28.6174C46.1302 28.6185 46.3893 28.5118 46.5801 28.321C46.7709 28.1302 46.8776 27.8711 46.8765 27.6013Z"
            fill="#6E8656"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M69.8884 32.6106C69.8873 32.8804 69.994 33.1395 70.1848 33.3303C70.3756 33.5211 70.6347 33.6278 70.9045 33.6267L71.3267 33.6196L71.3196 34.0418C71.3185 34.3116 71.4252 34.5707 71.616 34.7615C71.8068 34.9523 72.0658 35.059 72.3357 35.0579C72.6055 35.059 72.8646 34.9523 73.0554 34.7615C73.2462 34.5707 73.3529 34.3116 73.3518 34.0418L73.3446 33.6196L73.7668 33.6267C74.1299 33.6267 74.4653 33.4331 74.6468 33.1187C74.8283 32.8043 74.8283 32.4169 74.6468 32.1025C74.4653 31.7882 74.1299 31.5945 73.7668 31.5945L73.3446 31.6016L73.3518 31.1795C73.3518 30.6183 72.8969 30.1633 72.3357 30.1633C71.7745 30.1633 71.3196 30.6183 71.3196 31.1795L71.3267 31.6016L70.9045 31.5945C70.6347 31.5934 70.3756 31.7001 70.1848 31.8909C69.994 32.0817 69.8873 32.3408 69.8884 32.6106Z"
            fill="#6E8656"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.8611 23.3086C54.86 23.5784 54.9667 23.8375 55.1575 24.0283C55.3483 24.2191 55.6074 24.3258 55.8772 24.3247L56.2994 24.3175L56.2922 24.7397C56.2911 25.0096 56.3978 25.2687 56.5886 25.4595C56.7794 25.6503 57.0385 25.757 57.3083 25.7559C57.5782 25.757 57.8373 25.6503 58.0281 25.4595C58.2189 25.2687 58.3256 25.0096 58.3245 24.7397L58.3173 24.3175L58.7395 24.3247C59.3007 24.3247 59.7556 23.8698 59.7556 23.3086C59.7556 22.7474 59.3007 22.2925 58.7395 22.2925L58.3173 22.2996L58.3245 21.8774C58.3245 21.3163 57.8695 20.8613 57.3084 20.8613C56.7472 20.8613 56.2922 21.3163 56.2922 21.8774L56.2994 22.2996L55.8772 22.2925C55.6074 22.2914 55.3483 22.3981 55.1575 22.5889C54.9667 22.7797 54.86 23.0388 54.8611 23.3086Z"
            fill="#6E8656"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.5505 4.70263C40.5494 4.97246 40.6561 5.23156 40.8469 5.42236C41.0377 5.61315 41.2968 5.71985 41.5667 5.71875L41.9888 5.71159L41.9817 6.13378C41.9806 6.40361 42.0873 6.66271 42.2781 6.85351C42.4689 7.04431 42.728 7.15101 42.9978 7.1499C43.2676 7.15101 43.5267 7.04431 43.7175 6.85351C43.9083 6.66271 44.015 6.40361 44.0139 6.13378L44.0068 5.71159L44.429 5.71875C44.9901 5.71875 45.4451 5.26382 45.4451 4.70263C45.4451 4.14144 44.9901 3.68651 44.429 3.68651L44.0068 3.69367L44.0139 3.27148C44.0139 2.71029 43.559 2.25536 42.9978 2.25536C42.4366 2.25536 41.9817 2.71029 41.9817 3.27148L41.9888 3.69367L41.5667 3.68651C41.2968 3.68541 41.0377 3.79211 40.8469 3.98291C40.6561 4.17371 40.5494 4.4328 40.5505 4.70263Z"
            fill="#6E8656"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.7956 39.4959C42.4138 39.537 42.0881 39.7907 41.9549 40.1509C41.8216 40.511 41.9037 40.9156 42.1668 41.1953C42.4299 41.4751 42.8287 41.5818 43.1963 41.4709L46.7742 40.7553C49.342 40.2501 51.3496 38.2425 51.8548 35.6747C52.2069 33.9044 53.5908 32.5206 55.3611 32.1684L58.939 31.4528C59.4829 31.34 59.8342 30.8098 59.7261 30.265C59.6133 29.721 59.0831 29.3697 58.5382 29.4778L54.9604 30.1934C52.3925 30.6986 50.385 32.7062 49.8798 35.274C49.5276 37.0443 48.1438 38.4282 46.3735 38.7803L42.7956 39.4959Z"
            fill="#6E8656"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M74.1246 53.1337L70.79 51.86C68.3474 50.9028 65.5685 51.5162 63.7559 53.4128C62.5051 54.71 60.5969 55.1278 58.9186 54.4718L55.5267 53.1408C55.0051 52.9353 54.4156 53.1916 54.2101 53.7133C54.0046 54.235 54.2609 54.8244 54.7826 55.03L58.1815 56.3538C60.6247 57.316 63.4072 56.7052 65.2228 54.8081C66.4707 53.5059 68.3822 53.0874 70.0601 53.7491L73.409 55.0228C73.9197 55.181 74.4654 54.9146 74.6548 54.4146C74.8442 53.9146 74.6119 53.3535 74.1246 53.1337Z"
            fill="#6E8656"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.9064 11.6298C31.3855 11.8283 31.1231 12.4105 31.3196 12.9322L32.5933 16.2811C33.255 17.959 32.8365 19.8704 31.5343 21.1184C29.6372 22.9339 29.0264 25.7164 29.9886 28.1596L31.3124 31.5586C31.518 32.0803 32.1074 32.3366 32.6291 32.1311C33.1508 31.9256 33.4071 31.3361 33.2016 30.8144L31.8706 27.4226C31.2146 25.7442 31.6324 23.836 32.9296 22.5853C34.8312 20.7755 35.4454 17.9933 34.4824 15.5512L33.2087 12.2166C33.0103 11.6957 32.428 11.4334 31.9064 11.6298Z"
            fill="#6E8656"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.0442 35.9587C37.2932 36.4606 37.9019 36.6656 38.4038 36.4166C38.9057 36.1677 39.1108 35.559 38.8618 35.057C38.1898 33.221 38.4627 31.172 39.5917 29.5757C39.7954 29.2658 39.8133 28.8693 39.6383 28.5423C39.4633 28.2153 39.1234 28.0102 38.7525 28.0079C38.3816 28.0055 38.0392 28.2062 37.86 28.531C36.3748 30.719 36.0694 33.5005 37.0442 35.9587Z"
            fill="#6E8656"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.9596 47.9024C49.8394 48.1433 49.8201 48.4222 49.9061 48.6773C49.992 48.9325 50.1761 49.1429 50.4176 49.262C52.8651 50.2624 55.6491 49.9864 57.8524 48.5249C58.1694 48.3306 58.3587 47.982 58.349 47.6103C58.3392 47.2386 58.1319 46.9004 57.8051 46.723C57.4784 46.5456 57.0818 46.556 56.7648 46.7503C55.1603 47.8503 53.121 48.1012 51.2978 47.4229C50.7957 47.1899 50.1995 47.4035 49.9596 47.9024Z"
            fill="#6E8656"
          />
          <path
            d="M33.6946 51.2158C34.8802 50.0302 34.8802 48.108 33.6946 46.9224C32.509 45.7368 30.5867 45.7368 29.4011 46.9224C28.2155 48.108 28.2155 50.0302 29.4011 51.2158C30.5867 52.4014 32.509 52.4014 33.6946 51.2158ZM30.8323 48.3535C31.2275 47.9583 31.8682 47.9583 32.2634 48.3535C32.6586 48.7487 32.6586 49.3895 32.2634 49.7847C31.8682 50.1799 31.2275 50.1799 30.8323 49.7847C30.4371 49.3895 30.4371 48.7487 30.8323 48.3535Z"
            fill="#6E8656"
          />
          <path
            d="M39.4192 56.9404C40.6048 55.7548 40.6048 53.8326 39.4192 52.647C38.2336 51.4614 36.3113 51.4614 35.1257 52.647C33.9401 53.8326 33.9401 55.7548 35.1257 56.9404C36.3113 58.126 38.2336 58.126 39.4192 56.9404ZM36.5569 54.0781C36.9521 53.6829 37.5928 53.6829 37.988 54.0781C38.3832 54.4733 38.3832 55.1141 37.988 55.5093C37.5928 55.9045 36.9521 55.9045 36.5569 55.5093C36.1617 55.1141 36.1617 54.4733 36.5569 54.0781Z"
            fill="#6E8656"
          />
        </g>
        <defs>
          <clipPath id="clip0_1984_12903">
            <rect
              width="60.7186"
              height="60.7186"
              fill="white"
              transform="translate(43 0.406494) rotate(45)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

PartyPopper.displayName = "PartyPopper";
