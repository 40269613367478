import React from "react";
import { cn } from "@yardzen/next-client-util";

export const H1: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({
  children,
  className,
  ...props
}) => (
  <h1
    className={cn(
      "font-display scroll-m-20 text-4xl tracking-tight lg:text-5xl",
      className,
    )}
    {...props}
  >
    {children}
  </h1>
);
