import React from "react";
import { SegmentFlows } from "@yardzen/next-client-util";
import { analytics } from "@yardzen/next-segment";
import { Amenity, AmenityCard } from "./AmenityCard";
import { Button } from "./Button";
import { Card } from "./Card";
import { ContractorCardHeader } from "./ContractorCardHeader";
export interface ContractorCardProps {
  contractorThumbnailUrl: string;
  contractorHeadshotUrl: string;
  contractorBusinessName: string;
  contractorFirstName?: string;
  contractorLastName?: string;
  customerCity?: string;
  customerState?: string;
  isSuperPro?: boolean;
  contractorRating?: number;
  contractorReviewLength?: number;
  contractorGalleryImages?: Array<{ id: string; uri: string }>;
  whyYardzenLovesSummary?: string;
  amenities?: Amenity[];
  secondaryCtaOnClick: () => void;
  primaryCtaOnClick: () => void;
}

export function ContractorCard({
  contractorThumbnailUrl,
  contractorHeadshotUrl,
  contractorBusinessName,
  contractorFirstName,
  contractorLastName,
  customerCity,
  customerState,
  isSuperPro,
  contractorRating,
  contractorReviewLength,
  contractorGalleryImages,
  whyYardzenLovesSummary,
  amenities,
  secondaryCtaOnClick,
  primaryCtaOnClick,
}: ContractorCardProps) {
  return (
    <div className="flex flex-col items-center">
      <div className="w-full max-w-[550px]">
        <Card className="mb-8 p-4">
          <ContractorCardHeader
            contractorThumbnailUrl={contractorThumbnailUrl}
            contractorHeadshotUrl={contractorHeadshotUrl}
            contractorBusinessName={contractorBusinessName}
            customerCity={customerCity}
            customerState={customerState}
            isSuperPro={isSuperPro}
            contractorRating={contractorRating}
            contractorReviewLength={contractorReviewLength}
            contractorFirstName={contractorFirstName}
            contractorLastName={contractorLastName}
          />

          <div className="mt-5 flex flex-row gap-2">
            {contractorGalleryImages?.slice(0, 4).map((image) => (
              <div key={image.id} className="relative">
                <img
                  src={image.uri}
                  alt="previous work image"
                  style={{ objectFit: "cover" }}
                  className="object cover h-[50px] w-[75px] rounded-lg"
                />
              </div>
            ))}
          </div>

          {(whyYardzenLovesSummary || (amenities && amenities.length > 0)) && (
            <AmenityCard
              title="Why Yardzen Loves This Pro"
              amenities={amenities}
              className="mt-4 min-h-[160px]"
            >
              {whyYardzenLovesSummary}
            </AmenityCard>
          )}

          <div className="mt-4 flex flex-row justify-center gap-2">
            <Button
              variant="outline"
              className="basis-1/2 text-xs font-semibold"
              onClick={secondaryCtaOnClick}
            >
              View Profile
            </Button>
            <Button
              variant="default"
              className="basis-1/2 text-xs font-semibold"
              aria-label="Schedule Visit"
              onClick={async () => {
                await analytics.trackFlowStarted({
                  flow_name: SegmentFlows.BUILD_STUDIO_SITE_WALK,
                });
                primaryCtaOnClick();
              }}
              tracking={{
                button_content: "Schedule Visit",
                flow_name: SegmentFlows.BUILD_STUDIO_SITE_WALK,
                button_name: "Build Studio Site Walk",
              }}
            >
              Schedule Visit
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
}
