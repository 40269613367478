import { Expose, plainToInstance, Type } from "class-transformer";
import { IsBoolean, IsNotEmpty, IsOptional, IsString } from "class-validator";
import { ConversationParticipant } from "./conversation-participant.entity";

export class Conversation {
  public static from(conversation: Conversation) {
    return plainToInstance(Conversation, conversation, {
      excludeExtraneousValues: true,
      enableImplicitConversion: true,
      enableCircularCheck: true,
    });
  }
  @Expose()
  @IsString()
  @IsNotEmpty()
  id!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  createdTs!: Date;

  @Expose()
  @IsString()
  @IsNotEmpty()
  updatedTs!: Date;

  @Expose()
  @IsBoolean()
  @IsOptional()
  archived?: boolean;

  @Expose()
  @IsString()
  @IsOptional()
  archivedTs?: Date;

  @Expose()
  @IsOptional()
  @Type(() => ConversationParticipant)
  conversationParticipants?: ConversationParticipant[];
}
