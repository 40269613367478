import { IsNotEmpty, IsOptional, IsString } from "class-validator";

export class CreateMessageDto {
  @IsString()
  @IsNotEmpty()
  body!: string;

  @IsString()
  @IsOptional()
  author?: string | null;
}
