import { Expose, Transform, Type } from "class-transformer";
import {
  IsDate,
  IsDateString,
  IsEnum,
  IsNotEmpty,
  IsOptional,
  IsString,
} from "class-validator";
import { SiteWalkSource } from "./site-walk.entity";

export class CreateSiteWalkDto {
  @Expose()
  @IsString()
  @IsNotEmpty()
  contractorId: string;

  @Expose()
  @IsOptional()
  @IsEnum(SiteWalkSource)
  @IsNotEmpty()
  @Transform(({ value }) => value ?? SiteWalkSource.PLATFORM_MESSAGE, {
    toClassOnly: true,
  })
  siteWalkSource: SiteWalkSource;

  @Expose()
  @IsDateString()
  @IsNotEmpty()
  startTime: string;

  @Expose()
  @IsDateString()
  @IsNotEmpty()
  endTime: string;

  @Expose()
  @IsDate()
  @Type(() => Date)
  @Transform(({ obj }) => new Date(obj.startTime))
  _startTime?: Date;

  @Expose()
  @IsDate()
  @Type(() => Date)
  @Transform(({ obj }) => new Date(obj.endTime))
  _endTime?: Date;
}
