import { FindOneMatchDto } from "@yardzen/api-dtos";
import { getUserMatches } from "@yardzen/rest-api-client";
import useSWR from "swr";

export const useUserMatches = (
  accountId: string,
  buildProjectId: string,
  additionalOptions?: FindOneMatchDto,
) => {
  const key =
    accountId && buildProjectId
      ? `/accounts/${accountId}/build-projects/${buildProjectId}/matches`
      : null;

  const { data, error, isLoading, mutate } = useSWR(
    key,
    accountId && buildProjectId
      ? () => getUserMatches(accountId, buildProjectId, additionalOptions)
      : null,
  );

  return {
    matches: data?.data,
    isLoading,
    isError: error,
    refetch: () => mutate(),
  };
};
