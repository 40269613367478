import React from "react";
import type { BadgeProps } from "./Badge";
import { Badge } from "./Badge";

export function Eyebrow({ text, ...props }: { text: string } & BadgeProps) {
  return (
    <Badge variant="zest" className="my-4 w-fit" {...props}>
      {text}
    </Badge>
  );
}
