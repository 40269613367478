"use client";
import { faHeart } from "@fortawesome/pro-light-svg-icons/faHeart";
import { faHeart as faHeartSolid } from "@fortawesome/pro-solid-svg-icons/faHeart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import React from "react";
import { cn } from "../utils";
import { Card, CardFooter } from "./Card";

export function StyleCard({
  imageUrl,
  tags = [],
  style,
  isLiked,
  externalId,
  onLike,
  className,
}: {
  imageUrl: string;
  tags?: string[];
  style?: string;
  isLiked: boolean;
  externalId: number;
  onLike: (id: number) => void;
  className?: string;
}) {
  const [imageError, setImageError] = useState(false);

  return (
    <Card
      className={cn(
        "font-body hover:bg-brand-moss-greens-20 overflow-hidden",
        className,
      )}
      onClick={() => {
        onLike(externalId);
      }}
      aria-label={isLiked ? "Unlike design" : "Like design"}
    >
      <div className="relative pb-[66.67%]">
        {!imageError ? (
          <img
            src={imageUrl}
            alt={`Design render for ${tags.join(", ")}`}
            className="absolute inset-0 h-full w-full object-cover"
            onError={() => setImageError(true)}
          />
        ) : (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-200 text-gray-500">
            Image not available
          </div>
        )}
      </div>
      <CardFooter className="flex flex-col gap-2 px-4 py-2">
        <div className="flex w-full items-center justify-between">
          <div className="text-sm capitalize text-gray-600">{style}</div>
          <FontAwesomeIcon
            icon={isLiked ? faHeartSolid : faHeart}
            className={cn("h-5 w-5", isLiked ? "text-black-elderberry" : "")}
          />
        </div>
      </CardFooter>
    </Card>
  );
}
