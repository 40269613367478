import { Expose } from "class-transformer";
import { IsBoolean, IsOptional, IsString } from "class-validator";

export class CreateBuildProjectDto {
  @Expose()
  @IsOptional()
  @IsString()
  accountManagerId?: string | null;

  @Expose()
  @IsOptional()
  @IsBoolean()
  pullLatestDesignAssignment?: boolean | null;

  @Expose()
  @IsOptional()
  @IsString()
  firebaseProjectId?: string | null;
}
