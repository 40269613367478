import React from "react";
import { cn } from "../../utils";

export const B: React.FC<
  React.HTMLAttributes<HTMLElement> & {
    noSpacing?: boolean;
  }
> = ({ children, className, ...props }) => (
  <b {...props} className={cn("font-bold", className)}>
    {children}
  </b>
);
