import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "../utils";

const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-brand-primary-regular text-brand-secondary-lighter",
        outline: "text-brand-primary-regular border-brand-primary-regular",
        secondary:
          "border-transparent bg-brand-secondary-light text-brand-primary-regular",
        ghost: "text-brand-primary-regular bg-transparent",
        destructive:
          "border-transparent bg-system-detrimental-regular text-brand-secondary-lighter",
        success:
          "border-transparent bg-system-success-regular text-brand-secondary-lighter",
        warning:
          "border-transparent bg-system-warning-regular text-brand-secondary-lighter",
        zest: "border-transparent bg-brand-tertiary-50 text-black-elderberry",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

const hoverStyles = {
  default: "hover:bg-brand-primary-dark",
  outline: "hover:bg-brand-primary-light/20",
  secondary: "hover:bg-brand-moss-greens-30",
  ghost: "hover:bg-brand-primary-light/20",
  destructive: "hover:bg-system-detrimental-dark",
  success: "hover:bg-system-success-dark",
  warning: "hover:bg-system-warning-dark",
  zest: "hover:bg-brand-tertiary-100",
};

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  hover?: boolean;
}

function Badge({
  className,
  variant = "default",
  hover = true,
  ...props
}: BadgeProps) {
  return (
    <div
      className={cn(
        badgeVariants({ variant }),
        hover && hoverStyles[variant as keyof typeof hoverStyles],
        className,
      )}
      {...props}
    />
  );
}

export { Badge, badgeVariants };
