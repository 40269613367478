import { CreateMatchInput, FindOneMatchDto, Match } from "@yardzen/api-dtos";
import { AxiosResponse } from "axios";
import { apiClient } from "../base-client";

const baseUrl = "accounts";

export async function createMatches(
  accountId: string,
  buildProjectId: string,
  body: CreateMatchInput,
): Promise<AxiosResponse<Match[]>> {
  return apiClient.post(
    `${baseUrl}/${accountId}/build-projects/${buildProjectId}/matches/create-matches`,
    body,
  );
}

export async function findOrCreateInitialMatch(
  accountId: string,
  buildProjectId: string,
  body: CreateMatchInput,
): Promise<AxiosResponse<Match[]>> {
  return apiClient.post(
    `${baseUrl}/${accountId}/build-projects/${buildProjectId}/matches/find-or-create-initial-match`,
    body,
  );
}

export async function getMatch(
  accountId: string,
  buildProjectId: string,
  contractorId: string,
  additionalOptions?: FindOneMatchDto,
): Promise<AxiosResponse<Match>> {
  return apiClient.get(
    `${baseUrl}/${accountId}/build-projects/${buildProjectId}/matches/${contractorId}`,
    { params: additionalOptions },
  );
}

export async function getUserMatches(
  accountId: string,
  buildProjectId: string,
  additionalOptions?: FindOneMatchDto,
): Promise<AxiosResponse<Match[]>> {
  return apiClient.get(
    `${baseUrl}/${accountId}/build-projects/${buildProjectId}/matches`,
    { params: additionalOptions },
  );
}
