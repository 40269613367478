"use client";
import React, { forwardRef, useRef, useState } from "react";
import { cn } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";

interface VideoPlayerProps
  extends Omit<
    React.HTMLAttributes<HTMLDivElement>,
    keyof React.VideoHTMLAttributes<HTMLVideoElement>
  > {
  src: string;
  poster?: string;
  autoPlay?: boolean;
  muted?: boolean;
  controls?: boolean;
  loop?: boolean;
  className?: string;
  videoClassName?: string;
  showCustomPlayButton?: boolean;
  disabled?: boolean;
}

export const VideoPlayer = forwardRef<HTMLVideoElement, VideoPlayerProps>(
  (
    {
      src,
      poster,
      autoPlay = false,
      muted = false,
      controls = true,
      loop = false,
      showCustomPlayButton = true,
      className,
      videoClassName,
      disabled = false,
      ...props
    },
    ref,
  ) => {
    const localVideoRef = useRef<HTMLVideoElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [hasStartedPlaying, setHasStartedPlaying] = useState(false);

    // Use provided ref if available, otherwise use local ref
    const videoRef =
      (ref as React.RefObject<HTMLVideoElement | null>) || localVideoRef;

    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (disabled) return;

      const video = videoRef.current;
      if (!video) return;

      switch (e.key) {
        case "ArrowLeft":
          e.preventDefault();
          video.currentTime = Math.max(0, video.currentTime - 5);
          break;
        case "ArrowRight":
          e.preventDefault();
          video.currentTime = Math.min(video.duration, video.currentTime + 5);
          break;
        case "ArrowUp":
          e.preventDefault();
          video.volume = Math.min(1, video.volume + 0.1);
          break;
        case "ArrowDown":
          e.preventDefault();
          video.volume = Math.max(0, video.volume - 0.1);
          break;
      }
    };

    const togglePlay = () => {
      if (disabled) {
        console.warn("video player is disabled");
        return;
      }
      if (videoRef.current) {
        if (isPlaying) {
          videoRef.current.pause();
        } else {
          videoRef.current.play();
          setHasStartedPlaying(true);
        }
        setIsPlaying(!isPlaying);
      }
    };

    return (
      <div
        className={cn("relative overflow-hidden rounded-lg", className)}
        onKeyDown={handleKeyDown}
        tabIndex={disabled ? -1 : 0}
        role="application"
        aria-label="Video player"
        {...props}
      >
        {showCustomPlayButton && !hasStartedPlaying && (
          <PlayButton onClick={() => togglePlay()} />
        )}
        <video
          ref={videoRef}
          className={cn("h-full w-full object-cover", videoClassName)}
          src={src}
          poster={poster}
          autoPlay={autoPlay}
          muted={muted}
          controls={controls}
          loop={loop}
          onPlay={() => {
            setIsPlaying(true);
            setHasStartedPlaying(true);
          }}
          onPause={() => setIsPlaying(false)}
        />
      </div>
    );
  },
);

const PlayButton = ({
  onClick,
}: {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) => {
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      onClick?.(e as any);
    }
  };

  return (
    <div
      className="absolute z-10 flex h-full w-full cursor-pointer items-center justify-center"
      onClick={onClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      aria-label="Play video"
    >
      <FontAwesomeIcon
        icon={faPlay}
        className="bg-brand-primary-light z-10 aspect-square rounded-full p-[10px_8px_10px_10px] text-4xl text-white"
      />
    </div>
  );
};
