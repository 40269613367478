import { AxiosResponse } from "axios";
import { apiClient } from "../base-client";
import { V2TokenEntity, AuthUserEntity } from "@yardzen/api-dtos";

const baseUrl = "auth";

export async function getCurrentUser(): Promise<AxiosResponse<AuthUserEntity>> {
  const response = await apiClient.get(`${baseUrl}/current-user`);
  return {
    ...response,
    data: AuthUserEntity.from(response.data),
  };
}

export async function getV2Token(): Promise<AxiosResponse<V2TokenEntity>> {
  // This creates a new axios client that will also send the cookies.
  // We need to generate a v2 token from a v1 token, but the v1 token is
  // HTTP only meaning only the server can read it.
  const response = await apiClient.post(`${baseUrl}/v2-token`);
  return {
    ...response,
    data: V2TokenEntity.from(response.data),
  };
}
