import { getUserProfile } from "@yardzen/rest-api-client";
import useSWR from "swr";

export const useUserProfile = (userId: string) => {
  const { data, error, isLoading } = useSWR(
    userId ? `/users/${userId}/user-profile` : null,
    userId ? () => getUserProfile(userId) : null,
  );

  return {
    userProfile: data?.data,
    isLoading,
    isError: error,
  };
};
