"use client";

import { analytics } from "@yardzen/next-segment";
import { FormFieldFilledEvent } from "@yardzen/segmentv2";
import React, { forwardRef, SelectHTMLAttributes } from "react";
import { cn } from "../utils";

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  tracking?: FormFieldFilledEvent;
  waitForTracking?: boolean;
  wrapperClassName?: string;
  label?: string;
  labelClassName?: string;
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      className,
      children,
      tracking,
      waitForTracking,
      onChange,
      wrapperClassName,
      label,
      labelClassName,
      ...props
    },
    ref,
  ) => {
    const handleChange = async (
      event: React.ChangeEvent<HTMLSelectElement>,
    ) => {
      if (tracking) {
        const promise = analytics.trackFormFieldFilled({
          ...tracking,
          text_value: event.target.value,
        });
        if (waitForTracking) {
          await promise;
        }
      }
      onChange?.(event);
    };

    return (
      <div className={cn("flex justify-center", wrapperClassName)}>
        <label className={cn(labelClassName)} htmlFor="destination-select">
          {label}
        </label>
        <select
          name="destination-select"
          id="destination-select"
          className={cn(
            "shadow-douglas-fir focus:ring-douglas-fir border-input bg-background ring-offset-background placeholder:text-muted-foreground h-10 w-full max-w-xs appearance-none rounded-full border px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            className,
          )}
          ref={ref}
          onChange={handleChange}
          {...props}
        >
          {children}
        </select>
      </div>
    );
  },
);

Select.displayName = "Select";

export const SelectItem = forwardRef<
  HTMLOptionElement,
  React.OptionHTMLAttributes<HTMLOptionElement> & {
    tracking?: FormFieldFilledEvent;
  }
>(({ className, children, ...props }, ref) => {
  return (
    <option
      ref={ref}
      className={cn(
        "relative cursor-default select-none py-1.5 pl-8 pr-2",
        className,
      )}
      {...props}
    >
      {children}
    </option>
  );
});

SelectItem.displayName = "SelectItem";
