import { Expose, Transform, plainToInstance } from "class-transformer";
import { IsDate, IsNotEmpty, IsOptional, IsString } from "class-validator";

export class Message {
  public static from(message: Message) {
    return plainToInstance(Message, message, {
      excludeExtraneousValues: true,
    });
  }

  @Expose()
  @IsString()
  @IsNotEmpty()
  id!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  conversationId!: string;

  @Expose()
  @IsString()
  body!: string;

  @Expose()
  @IsString()
  @IsOptional()
  author?: string | null;

  @Expose()
  @IsDate()
  @IsNotEmpty()
  @Transform(({ value }) => new Date(value))
  createdTs!: Date;

  @Expose()
  @IsDate()
  @IsOptional()
  @Transform(({ value }) => (value ? new Date(value) : null))
  updatedTs!: Date | null;
}
