import { cn } from "@yardzen/next-client-util";
import { PageTracker } from "./PageTracker";

interface PageContainerProps {
  children: React.ReactNode;
  className?: string;
  trackingData?: {
    page_name: string;
  };
}

/**
 * PageContainer component.
 *
 * This component serves as a wrapper for page content, providing consistent padding and optional custom styling.
 *
 * @param {PageContainerProps} props - The properties for the PageContainer component.
 * @param {React.ReactNode} props.children - The content to be rendered inside the container.
 * @param {string} [props.className] - Optional custom CSS class to override default styling.
 * @returns {JSX.Element} The PageContainer component with applied styling and children content.
 */
export function PageContainer({
  children,
  className,
  trackingData,
}: PageContainerProps) {
  const wrapperClassName = cn(
    "flex flex-col p-5 pt-24 md:pb-12 md:px-[120px]",
    className,
  );

  return (
    <div className={wrapperClassName}>
      {trackingData && <PageTracker trackingData={trackingData} />}
      {children}
    </div>
  );
}
