import useSWR from "swr";
import { getSiteWalkPublic } from "@yardzen/rest-api-client";
import { useMemo } from "react";

export const useSiteWalkPublic = (
  siteWalkId: string,
  buildProjectId: string,
) => {
  const { data, error, isLoading } = useSWR(
    siteWalkId && buildProjectId
      ? `/public/build-projects/${buildProjectId}/site-walks/${siteWalkId}`
      : null,
    siteWalkId && buildProjectId
      ? () => getSiteWalkPublic(siteWalkId, buildProjectId)
      : null,
  );

  return useMemo(
    () => ({
      siteWalk: data?.data,
      isLoading,
      isError: error,
    }),
    [data?.data, isLoading, error],
  );
};
