import React, { ComponentProps, forwardRef } from "react";
import { cn } from "../utils";

interface OrderedListProps extends ComponentProps<"ol"> {
  type: "1" | "A" | "a" | "I" | "i";
}

const mapTypeToClassName = (type: OrderedListProps["type"]) => {
  switch (type) {
    case "1":
      return "list-[auto]";
    case "A":
      return "list-[upper-alpha]";
    case "a":
      return "list-[lower-alpha]";
    case "I":
      return "list-[upper-roman]";
    case "i":
      return "list-[lower-roman]";
  }
};

const OrderedList = forwardRef<HTMLOListElement, OrderedListProps>(
  ({ children, type, ...props }, ref) => (
    <ol
      ref={ref}
      {...props}
      className={cn(mapTypeToClassName(type), "list-inside")}
    >
      {children}
    </ol>
  ),
);

OrderedList.displayName = "OrderedList";

const OrderedListItem = forwardRef<HTMLLIElement, ComponentProps<"li">>(
  ({ children, ...props }, ref) => (
    <li ref={ref} {...props}>
      {children}
    </li>
  ),
);

OrderedListItem.displayName = "OrderedListItem";

export { OrderedList, OrderedListItem };
