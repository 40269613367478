import { z } from "zod";

export const SurveyQuestionSchema = z
  .object({
    id: z.string().min(1),
    questionKey: z.string().min(1),
    text: z.string().min(1),
    section: z.number(),
    index: z.number(),
    format: z.string().min(1),
    required: z.boolean(),
    active: z.boolean().optional(),
    createdTs: z.string().transform((str) => new Date(str)),
    updatedTs: z
      .string()
      .nullable()
      .optional()
      .transform((str) => (str ? new Date(str) : null)),
    surveyId: z.string().min(1),
    description: z.string().nullable(),
  })
  .strict();

export const SurveySchema = z
  .object({
    id: z.string().min(1),
    surveyKey: z.string().min(1),
    surveyDesc: z.string().min(1),
    active: z.boolean(),
    createdTs: z.string().transform((str) => new Date(str)),
    updatedTs: z
      .string()
      .nullable()
      .optional()
      .transform((str) => (str ? new Date(str) : null)),
    surveyQuestions: z.array(SurveyQuestionSchema),
  })
  .strict();

export class InvalidSurveyResponseError extends Error {
  constructor(public errors: z.ZodError) {
    super("Invalid survey response");
    this.name = "InvalidSurveyResponseError";
  }
}

export class EmptySurveyResponseError extends Error {
  constructor() {
    super("Empty survey response");
    this.name = "EmptySurveyResponseError";
  }
}

export class InvalidSurveyQuestionError extends Error {
  constructor(public errors: z.ZodError) {
    super("Invalid survey question");
    this.name = "InvalidSurveyQuestionError";
  }
}
