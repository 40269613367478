import { getUnreadMessagesCount } from "@yardzen/rest-api-client";
import { useMemo } from "react";
import useSWR from "swr";

interface UseMessageCountOptions {
  pollInterval?: number;
}

export const useMessageCount = (
  accountId: string,
  options?: UseMessageCountOptions,
) => {
  const { data, error, isLoading } = useSWR(
    `/accounts/${accountId}/conversations/unread-messages-count`,
    () => getUnreadMessagesCount(accountId),
    {
      refreshInterval: options?.pollInterval,
      revalidateOnFocus: true,
    },
  );

  return useMemo(
    () => ({
      unreadCount: data,
      isLoading,
      isError: error,
    }),
    [data, isLoading, error],
  );
};
