import React from "react";
import { cn } from "@yardzen/next-client-util";

export const H4: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({
  children,
  className,
  ...props
}) => (
  <h4
    className={cn("font-display scroll-m-20 text-xl tracking-tight", className)}
    {...props}
  >
    {children}
  </h4>
);
