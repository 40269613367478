import { apiClient } from "../base-client";

export async function getUnreadMessagesCount(
  accountId: string,
): Promise<number> {
  if (!accountId) {
    return 0;
  }
  const response = await apiClient.get<number>(
    `/accounts/${accountId}/conversations/unread-messages-count`,
  );
  return response.data;
}
