import { cva, type VariantProps } from "class-variance-authority";
import React from "react";
import { cn } from "../../utils";

const pVariants = cva("font-body leading-7", {
  variants: {
    variant: {
      default: "",
      subdued: "text-brand-primary-darker opacity-70",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export const P: React.FC<
  React.HTMLAttributes<HTMLParagraphElement> &
    VariantProps<typeof pVariants> & {
      noSpacing?: boolean;
    }
> = ({ children, className, noSpacing, variant, ...props }) => (
  <p
    className={cn(
      pVariants({ variant }),
      !noSpacing && "[&:not(:first-child)]:mt-6",
      className,
    )}
    {...props}
  >
    {children}
  </p>
);
