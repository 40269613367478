import { Expose, Type } from "class-transformer";
import {
  IsArray,
  IsNotEmpty,
  IsOptional,
  IsString,
  ValidateNested,
} from "class-validator";
import { SurveyQuestionResponse } from "./survey-question-response.entity";

export class UpsertSurveyQuestionResponseInput
  implements
    Omit<
      SurveyQuestionResponse,
      "createdTs" | "updatedTs" | "surveyResponseId"
    >
{
  @Expose()
  @IsString()
  @IsNotEmpty()
  surveyQuestionId!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  response!: string;
}

export class UpsertSurveyQuestionResponsesInput {
  @Expose()
  @IsArray()
  @IsNotEmpty()
  @ValidateNested({ each: true })
  @Type(() => UpsertSurveyQuestionResponseInput)
  questionResponses: UpsertSurveyQuestionResponseInput[] = [];

  @Expose()
  @IsString()
  @IsOptional()
  userId?: string;
}
