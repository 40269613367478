import { TypeLinkCardSkeleton } from "@contentful/types";
import { Entry } from "contentful";
import { AnimatedLink } from "./AnimatedLink";

export function NavLinks({
  fallbackLinks,
  contentfulLinks,
}: {
  fallbackLinks: Array<{ fields: { title: string; url: string } }>;
  contentfulLinks:
    | (
        | Entry<TypeLinkCardSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>
        | undefined
      )[]
    | undefined;
}) {
  const links = contentfulLinks ? contentfulLinks : fallbackLinks;
  return (
    <nav
      aria-label="Main Navigation"
      className="flex h-full items-center justify-start gap-5 pl-4 text-base"
    >
      {links &&
        links.map(
          (link) =>
            link?.fields.url &&
            link?.fields.title && (
              <AnimatedLink
                key={link?.fields.url}
                href={link?.fields.url}
                label={link?.fields.title}
                className="h-full text-base !font-medium !leading-[22px]"
              />
            ),
        )}
    </nav>
  );
}
