import { faHeart as faHeartSolid } from "@fortawesome/pro-solid-svg-icons/faHeart";
import { faHeart as faHeartLight } from "@fortawesome/pro-light-svg-icons/faHeart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ButtonHTMLAttributes, forwardRef } from "react";
import { cn } from "../utils";
import { Button } from "./Button";

export interface LikeBtnProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLiked: boolean;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}

export const LikeBtn = forwardRef<HTMLButtonElement, LikeBtnProps>(
  ({ isLiked, onClick, className, disabled, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        variant="ghost"
        className={cn(
          "relative flex h-6 w-10 flex-shrink-0 items-center justify-center rounded-none p-0 shadow-none hover:shadow-none",
          className,
        )}
        onClick={onClick}
        disabled={disabled}
        aria-label={isLiked ? "Unlike" : "Like"}
        {...props}
      >
        <div className="relative h-5 w-5">
          <FontAwesomeIcon
            icon={faHeartLight}
            className="absolute z-10 h-5 w-5"
          />
          {isLiked && (
            <FontAwesomeIcon
              icon={faHeartSolid}
              className="text-brand-tertiary-50 absolute h-5 w-5"
            />
          )}
        </div>
      </Button>
    );
  },
);

LikeBtn.displayName = "LikeBtn";
