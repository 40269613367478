"use client";

import {
  isTypeCta,
  isTypeGenericSection,
  TypeCta,
  TypeGenericSection,
  TypeGenericSectionSkeleton,
  TypePageSkeleton,
} from "@contentful/types";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notEmpty } from "@yardzen/js-util";
import { Entry } from "contentful";
import { useActionState } from "react";
import { UseFormReturn } from "react-hook-form";
import { Button } from "./Button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "./Form";
import { Input } from "./Input";
import { Label } from "./Label";
import { Link } from "./Link";
import { Spinner } from "./Spinner";
import { H2 } from "./typography/H2";

function getGenericSection(
  page: Entry<TypePageSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>,
): TypeGenericSection<"WITHOUT_UNRESOLVABLE_LINKS"> | null {
  return (
    page.fields.sections.filter(notEmpty).filter(isTypeGenericSection)[0] ||
    null
  );
}
function getCta(
  page: Entry<TypeGenericSectionSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", string>,
): TypeCta<"WITHOUT_UNRESOLVABLE_LINKS"> | null {
  return (
    page.fields.CTAs?.filter(notEmpty)
      .filter(isTypeCta)
      .find((cta) => cta.fields.title === "Quiz Sign Up Page") || null
  );
}

export const EmailLeadCapture = ({
  form,
  segmentFlow,
  segmentForm,
  contentfulPage,
  action,
  ...formProps
}: {
  form: UseFormReturn<any>;
  segmentFlow: string;
  segmentForm: string;
  contentfulPage: Entry<
    TypePageSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    string
  > | null;
  action: (_prevState: unknown, formData: FormData) => Promise<void>;
} & Omit<React.FormHTMLAttributes<HTMLFormElement>, "action">) => {
  const [_, formAction, isPending] = useActionState(action as any, null);

  const emailCapture = contentfulPage
    ? getGenericSection(contentfulPage)
    : null;
  const captureCTA = emailCapture ? getCta(emailCapture) : null;

  const headerCopy =
    emailCapture?.fields.title || "Almost done. Let's save your results.";
  const subCopy = emailCapture?.fields.subtitle;
  const buttonCopy = captureCTA?.fields.buttonText || "Next";
  const disclaimerCopy = captureCTA?.fields.disclaimerText;

  return (
    <div className="flex max-w-[500px] flex-col flex-wrap justify-center gap-4 rounded-lg bg-white p-10 text-center md:flex-row">
      <div>
        <H2>{headerCopy}</H2>
        <p className="text-brand-moss-greens-90 mt-2">{subCopy}</p>
      </div>
      <div className="flex w-full flex-col gap-2">
        <Form {...form}>
          <form
            {...formProps}
            action={formAction}
            className="flex w-full flex-col gap-2"
          >
            <Label htmlFor="email" className="text-left text-base">
              Email
            </Label>
            <FormField
              control={form.control}
              name="email"
              rules={{ required: "Email is required" }}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder="Enter your email address"
                      type="email"
                      {...field}
                      className="w-full flex-1 py-2 text-black"
                      tracking={{
                        flow_name: segmentFlow,
                        form_name: segmentForm,
                        text_value: field.value,
                        field_name: "Email Address",
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <p className="text-brand-moss-greens-90 py-4 text-center text-xs">
              By continuing, you agree to Yardzen's{" "}
              <Link className="underline" href="/terms-and-conditions">
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link className="underline" href="/privacy-policy">
                Privacy Policy.
              </Link>
            </p>
            <Button
              type="submit"
              variant="default"
              size="lg"
              disabled={!form.formState.isValid || isPending}
              className="px-4 py-2 hover:bg-gray-100 disabled:bg-gray-200 disabled:text-gray-400"
              endIcon={
                isPending ? (
                  <Spinner className="size-4" />
                ) : (
                  <FontAwesomeIcon icon={faArrowRight} />
                )
              }
            >
              {buttonCopy}
            </Button>
          </form>
        </Form>
        <p className="text-brand-moss-greens-90 text-xxs py-4">
          {disclaimerCopy}
        </p>
      </div>
    </div>
  );
};
