import { UserProfile } from "@yardzen/api-dtos";
import { AxiosResponse } from "axios";
import { apiClient } from "../base-client";

const baseUrl = "users";

export async function getUserProfile(
  userId: string,
): Promise<AxiosResponse<UserProfile>> {
  return apiClient.get(`${baseUrl}/${userId}/user-profile`);
}
