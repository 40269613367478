import {
  CustomerLeadInput,
  CustomerLeadSchema,
  EmptyCustomerLeadError,
  InvalidCustomerLeadError,
  UpsertCustomerLeadInput,
} from "@yardzen/api-dtos";
import { AxiosError } from "axios";
import { z } from "zod";
import { apiClient } from "../base-client";

export async function findCustomerLead(segmentId: string) {
  try {
    const response = await apiClient.get(`customer-leads/${segmentId}`);
    if (response.data) {
      return validateCustomerLeadData(response.data);
    }

    return null;
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 404) {
      throw new EmptyCustomerLeadError();
    }
    throw error;
  }
}

export async function createCustomerLead(input: CustomerLeadInput) {
  try {
    const response = await apiClient.post("customer-leads", input);
    return validateCustomerLeadData(response.data);
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 404) {
      throw new EmptyCustomerLeadError();
    }
    throw error;
  }
}

export async function updateCustomerLead(id: string, input: CustomerLeadInput) {
  try {
    const response = await apiClient.put(`customer-leads/${id}`, input);
    return validateCustomerLeadData(response.data);
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 404) {
      throw new EmptyCustomerLeadError();
    }
    throw error;
  }
}

export async function upsertCustomerLead(
  segmentId: string,
  input: UpsertCustomerLeadInput,
) {
  try {
    const response = await apiClient.post(
      `customer-leads/${segmentId}/upsert`,
      input,
    );
    console.log("upsertCustomerLead.response", response.data, input);
    return validateCustomerLeadData(response.data);
  } catch (error) {
    console.error("upsertCustomerLead.error", error);
    if (error instanceof AxiosError && error.response?.status === 404) {
      throw new EmptyCustomerLeadError();
    }
    throw error;
  }
}

function validateCustomerLeadData(data: unknown) {
  try {
    return CustomerLeadSchema.parse(data);
  } catch (error) {
    if (error instanceof z.ZodError) {
      console.error(
        `Invalid customer lead data:`,
        JSON.stringify(data, null, 2),
      );
      throw new InvalidCustomerLeadError(error);
    }
    throw error;
  }
}
