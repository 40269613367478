"use client";

import React, { useEffect, useState } from "react";
import { Ellipsis } from "./Ellipsis";
import { AnimatePresence, motion } from "motion/react";

interface MessageProps {
  children: React.ReactNode;
  className?: string;
  showEllipsis?: boolean;
  ellipsisTimeout?: number;
  avatar?: React.ReactNode;
}

const MessageWithoutEllipsis = ({
  children,
  className = "",
  avatar,
}: Omit<MessageProps, "showEllipsis" | "ellipsisTimeout">) => (
  <div className="flex flex-row items-start gap-2 md:gap-[10px]">
    {avatar}
    <div
      className={`relative w-full rounded-lg bg-white p-4 shadow-sm ${className}`}
    >
      {children}
    </div>
  </div>
);

const MessageWithEllipsis = ({
  children,
  className = "",
  ellipsisTimeout = 1500,
  avatar,
}: Omit<MessageProps, "showEllipsis">) => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, ellipsisTimeout);

    return () => clearTimeout(timer);
  }, [ellipsisTimeout]);

  return (
    <div className="flex flex-row items-start gap-2 md:gap-[10px]">
      {avatar}
      <div
        className={`relative w-full rounded-lg bg-white p-4 shadow-sm ${className}`}
      >
        <AnimatePresence mode="wait">
          {!showContent ? (
            <motion.div
              key="ellipsis"
              initial={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex min-h-[24px] items-center"
            >
              <Ellipsis />
            </motion.div>
          ) : (
            <motion.div
              key="content"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

const Message = ({ showEllipsis = false, ...props }: MessageProps) => {
  if (!showEllipsis) {
    return <MessageWithoutEllipsis {...props} />;
  }
  return <MessageWithEllipsis {...props} />;
};

export { Message };
