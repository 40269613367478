import React from "react";
import { cn } from "../utils";
import { Toggle, type ToggleProps } from "./Toggle";

type BaseToggleCardProps = Omit<ToggleProps, "asChild" | "className"> & {
  className?: string;
  icons?: React.ReactNode[];
  contentClassName?: string;
};

type ToggleCardPropsWithTitle = BaseToggleCardProps & {
  title: string;
  children?: never;
};

type ToggleCardPropsWithChildren = BaseToggleCardProps & {
  title?: never;
  children: React.ReactNode;
};

type ToggleCardProps = ToggleCardPropsWithTitle | ToggleCardPropsWithChildren;

export const ToggleCard = React.forwardRef<HTMLButtonElement, ToggleCardProps>(
  (
    {
      title,
      pressed,
      icons = [],
      className,
      children,
      contentClassName,
      ...props
    },
    ref,
  ) => {
    return (
      <Toggle
        ref={ref}
        pressed={pressed}
        className={cn(
          "h-auto w-[162px]",
          "flex flex-col items-center justify-center gap-2 p-6",
          "transition-colors",
          "border-system-opacity-10 rounded-lg border-2",
          "shadow-[0px_2px_2px_0px_rgba(0,0,0,0.1)]",
          "focus-visible:outline-none focus-visible:ring-2",
          "data-[state=on]:border-brand-primary-dark data-[state=on]:bg-brand-moss-greens-10",
          className,
        )}
        {...props}
      >
        {icons.map((icon, index) => (
          <div
            key={index}
            className="text-brand-secondary-dark [&_svg]:h-5 [&_svg]:w-5"
          >
            {icon}
          </div>
        ))}
        <div
          className={cn(
            "text-brand-secondary-dark text-center text-sm font-medium",
            contentClassName,
          )}
        >
          {title ?? children}
        </div>
      </Toggle>
    );
  },
);

ToggleCard.displayName = "ToggleCard";
