import useSWR from "swr";
import { getCurrentUser } from "@yardzen/rest-api-client";
import { useMemo } from "react";

export const useCurrentUser = () => {
  const { data, error, isLoading } = useSWR("/current-user", getCurrentUser, {
    revalidateOnFocus: false,
  });

  return useMemo(
    () => ({
      user: data?.data,
      isLoading,
      isError: error,
    }),
    [data?.data, isLoading, error],
  );
};
