import { IsOptional, IsString } from "class-validator";

export class FindSurveyResponseInput {
  @IsString()
  @IsOptional()
  id?: string | null;

  @IsString()
  @IsOptional()
  buildProjectId?: string | null;

  @IsString()
  @IsOptional()
  surveyId?: string | null;
}
