import React from "react";
import { cn } from "@yardzen/next-client-util";

export const Blockquote: React.FC<React.HTMLAttributes<HTMLQuoteElement>> = ({
  children,
  className,
  ...props
}) => (
  <blockquote
    className={cn("mt-6 border-l-2 pl-6 italic", className)}
    {...props}
  >
    {children}
  </blockquote>
);
