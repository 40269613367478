import { faHome, faRotateLeft } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageContainer } from "@yardzen/components/page-container";
import { Button } from "../components/Button";
import { H1 } from "../components/typography/H1";
import { P } from "../components/typography/P";
import { cn } from "../utils";
import { Link } from "./Link";

export function PageError({
  title,
  description,
  className,
  displayContactInfo,
  allowRefreshButton,
}: {
  title: string;
  description: string;
  className?: string;
  displayContactInfo?: boolean;
  allowRefreshButton?: boolean;
}) {
  return (
    <PageContainer
      className={cn("flex flex-col items-center justify-center", className)}
    >
      <H1 className="pt-8">{title}</H1>
      <P>{description}</P>

      <P>
        {allowRefreshButton ? (
          <Button onClick={() => window.location.reload()}>
            <FontAwesomeIcon icon={faRotateLeft} className="h-5 w-5" />
            Refresh Page
          </Button>
        ) : (
          <Link href="/" className="inline-flex items-center gap-2">
            <FontAwesomeIcon icon={faHome} className="h-5 w-5" />
            Return Home
          </Link>
        )}
      </P>

      {displayContactInfo && (
        <P>
          Contact us:{" "}
          <Link href="mailto:hello@yardzen.com">hello@yardzen.com</Link>{" "}
          <Link href="tel:8889273936">(888) 927-3936</Link>
        </P>
      )}
    </PageContainer>
  );
}
