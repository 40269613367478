import { z } from "zod";

export const SurveyQuestionResponseSchema = z
  .object({
    surveyResponseId: z.string().min(1),
    surveyQuestionId: z.string().min(1),
    response: z.string(),
    createdTs: z.string().transform((str) => new Date(str)),
    updatedTs: z
      .string()
      .nullable()
      .optional()
      .transform((str) => (str ? new Date(str) : null)),
  })
  .strict();

export class InvalidSurveyQuestionResponseError extends Error {
  constructor(public errors: z.ZodError) {
    super("Invalid survey question response");
    this.name = "InvalidSurveyQuestionResponseError";
  }
}

export class EmptySurveyQuestionResponseError extends Error {
  constructor() {
    super("Empty survey question response");
    this.name = "EmptySurveyQuestionResponseError";
  }
}
