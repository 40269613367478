import React from "react";
import { forwardRef } from "react";
import { cn } from "../utils";
import { AvatarRoot, AvatarImage, AvatarFallback } from "./Avatar";
import { Button } from "./Button";

interface NeedHelpBtnProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  avatarUrl: string;
  fallback: string;
  className?: string;
}

export const NeedHelpBtn = forwardRef<HTMLButtonElement, NeedHelpBtnProps>(
  ({ avatarUrl, fallback, className, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        className={cn(
          "group flex items-center gap-2 border-none bg-transparent p-0 shadow-none hover:bg-transparent hover:shadow-none focus-visible:ring-0 active:bg-transparent",
          className,
        )}
        variant="ghost"
        {...props}
      >
        <div className="bg-brand-greens-regular hover:bg-brand-greens-light text-brand-secondary-dark rounded-full px-6 py-4 text-lg transition-colors duration-300">
          Need Help?
        </div>
        <AvatarRoot className="h-[53px] w-[53px]">
          <AvatarImage
            src={avatarUrl}
            alt="Support agent"
            className="h-full w-full rounded-full"
          />
          <AvatarFallback className="flex h-full w-full items-center justify-center rounded-full bg-white text-lg shadow-md">
            {fallback}
          </AvatarFallback>
        </AvatarRoot>
      </Button>
    );
  },
);

NeedHelpBtn.displayName = "NeedHelpBtn";
