import { Expose, plainToInstance, Transform, Type } from "class-transformer";
import {
  IsArray,
  IsNotEmpty,
  IsOptional,
  IsString,
  ValidateNested,
} from "class-validator";
import { TimeToBuild } from "../utils/Time.entity";
import { ProjectElement } from "../project-element/project-element.entity";
import { AccountManager } from "../account-manager";
import { DesignProjectElement } from "../project-element/design-project-element.entity";

export class BuildProject {
  public static from(buildProject: BuildProject): BuildProject {
    return plainToInstance(BuildProject, buildProject, {
      excludeExtraneousValues: true,
      enableImplicitConversion: true,
      enableCircularCheck: true,
    });
  }

  @Expose()
  @IsNotEmpty()
  @IsString()
  id!: string;

  @Expose()
  @IsOptional()
  @IsString()
  proFacingNotes?: string | null;

  @Expose()
  @IsArray()
  @IsOptional()
  @Type(() => ProjectElement)
  @Transform(
    ({ obj }) =>
      obj?.buildProjectElements?.map((bpe: any) =>
        ProjectElement.from(bpe.projectElement),
      ) ?? [],
  )
  projectElements?: ProjectElement[] | null;

  @Expose()
  @IsArray()
  @IsOptional()
  @Type(() => DesignProjectElement)
  designProjectElements?: DesignProjectElement[] | null;

  @Expose()
  @IsOptional()
  @IsString()
  overviewImg?: string | null;

  @Expose()
  @IsOptional()
  @ValidateNested()
  @Type(() => AccountManager)
  accountManager?: AccountManager | null;

  @Expose()
  @IsOptional()
  @ValidateNested()
  @Type(() => TimeToBuild)
  timeToBuild?: TimeToBuild | null;

  @Expose()
  @IsOptional()
  @IsString()
  firebaseProjectId?: string | null;

  @Expose()
  @IsOptional()
  @IsString()
  accountManagerId?: string | null;
}
