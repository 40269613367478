import { Expose, plainToInstance, Type } from "class-transformer";
import {
  IsArray,
  IsBoolean,
  IsDate,
  IsNotEmpty,
  IsOptional,
  IsString,
  ValidateNested,
} from "class-validator";
import { SurveyQuestionResponse } from "./survey-question-response.entity";

export class SurveyResponse {
  public static from(surveyResponse: SurveyResponse) {
    return plainToInstance(SurveyResponse, surveyResponse, {
      excludeExtraneousValues: true,
    });
  }

  @Expose()
  @IsString()
  @IsNotEmpty()
  id!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  surveyId!: string;

  @Expose()
  @IsString()
  @IsOptional()
  buildProjectId?: string;

  @Expose()
  @IsBoolean()
  @IsNotEmpty()
  completed!: boolean;

  @Expose()
  @IsString()
  @IsOptional()
  nextQuestionId?: string;

  @Expose()
  @IsString()
  @IsOptional()
  segmentId?: string;

  @Expose()
  @IsDate()
  createdTs!: Date;

  @Expose()
  @IsDate()
  updatedTs?: Date | null;

  @Expose()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => SurveyQuestionResponse)
  surveyQuestionResponses!: SurveyQuestionResponse[];
}
