import { IconName } from "../components/Icon";
export const elementIcons: Record<string, IconName> = {
  "BBQ Area": "outdoor-kitchen",
  Deck: "deck",
  "Doors Replacement": "doors-replacement",
  Driveway: "driveway",
  Fencing: "fences",
  "Fire Pit": "fire-pit",
  Fountain: "fountain",
  Gates: "gates",
  "Hot Tub": "hot-tub",
  "Lawn Installation": "lawn-installation",
  "Lawn Removal": "lawn-removal",
  "Outdoor Kitchen": "outdoor-kitchen",
  "Outdoor Lighting": "outdoor-lighting",
  "Outdoor Play Area": "outdoor-play-area",
  "Outdoor Shower": "outdoor-shower",
  Painting: "painting",
  Pathways: "pathways",
  Patio: "patio",
  Pergola: "pergola",
  Planting: "planting",
  Pool: "pool",
  "Retaining Wall": "retaining-wall",
  Roofing: "roofing",
  "Siding Replacement": "siding-replacement",
  Trees: "trees",
  "Turf Installation": "turf-installation",
  "Windows Replacement": "windows-replacement",
};
