import { Expose } from "class-transformer";
import { IsNotEmpty, IsNumber, IsOptional, IsString } from "class-validator";

export class CreateMatchInput {
  @Expose()
  @IsString()
  @IsNotEmpty()
  userId!: string;

  @Expose()
  @IsNumber()
  @IsOptional()
  matchLimit?: number;
}
