import { z } from "zod";

export const SurveyResponseQuestionSchema = z
  .object({
    id: z.string().min(1),
    questionId: z.string().min(1),
    response: z.string().nullable(),
    createdTs: z.string().transform((str) => new Date(str)),
    updatedTs: z
      .string()
      .nullable()
      .optional()
      .transform((str) => (str ? new Date(str) : null)),
    surveyResponseId: z.string().min(1),
  })
  .strict();

export const SurveyResponseSchema = z
  .object({
    id: z.string().min(1),
    surveyId: z.string().min(1),
    userId: z.string().nullable().optional(),
    segmentId: z.string().nullable().optional(),
    buildProjectId: z.string().nullable().optional(),
    completed: z.boolean().nullable().optional(),
    nextQuestionId: z.string().nullable().optional(),
    createdTs: z.string().transform((str) => new Date(str)),
    updatedTs: z
      .string()
      .nullable()
      .optional()
      .transform((str) => (str ? new Date(str) : null)),
    surveyQuestionResponses: z.array(SurveyResponseQuestionSchema),
  })
  .strict();

export class InvalidSurveyResponseResponseError extends Error {
  constructor(public errors: z.ZodError) {
    super("Invalid SurveyResponse response");
    this.name = "InvalidSurveyResponseResponseError";
  }
}

export class EmptySurveyResponseResponseError extends Error {
  constructor() {
    super("Empty SurveyResponse response");
    this.name = "EmptySurveyResponseResponseError";
  }
}
