import {
  EmptySurveyResponseError,
  InvalidSurveyResponseError,
  Survey,
} from "@yardzen/api-dtos";
import { getSurvey } from "@yardzen/rest-api-client";
import useSWR from "swr";

const fetcher = async (
  key: string | null,
  surveyKey: string,
  parameters?: Record<string, string>,
): Promise<Survey | null> => {
  if (!key) return null;
  try {
    return await getSurvey(surveyKey, parameters);
  } catch (error) {
    if (
      error instanceof EmptySurveyResponseError ||
      error instanceof InvalidSurveyResponseError
    ) {
      return null;
    }
    throw error;
  }
};

export const useSurvey = (
  surveyKey: string,
  parameters?: Record<string, string>,
): { survey: Survey | null; isLoading: boolean; isError: Error | null } => {
  const queryString = parameters
    ? "?" +
      Object.entries(parameters)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")
    : "";

  const key = surveyKey ? `survey/${surveyKey}${queryString}` : null;
  const { data, error, isLoading } = useSWR(
    [key, surveyKey, parameters],
    ([currentKey, currentSurveyKey, currentParameters]) =>
      fetcher(currentKey, currentSurveyKey, currentParameters),
  );

  if (!surveyKey) {
    return {
      survey: null,
      isLoading: false,
      isError: null,
    };
  }

  return {
    survey: data ?? null,
    isLoading,
    isError: error ?? null,
  };
};
