import { Transform } from "class-transformer";
import { IsBoolean, IsOptional } from "class-validator";

export class FindOneContractorProfileDto {
  @IsBoolean()
  @IsOptional()
  @Transform(({ value }) => value === "true")
  includeAdditionalMedia?: boolean | null;

  @IsBoolean()
  @IsOptional()
  @Transform(({ value }) => value === "true")
  includeDocuments?: boolean | null;
}
