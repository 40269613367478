import { Expose, plainToInstance } from "class-transformer";
import {
  IsEnum,
  IsNotEmpty,
  IsObject,
  IsOptional,
  IsString,
  IsBoolean,
} from "class-validator";

export enum ErrorCode {
  NOT_FOUND = "NOT_FOUND",
  CONFLICT = "CONFLICT",
  BAD_REQUEST = "BAD_REQUEST",
  FORBIDDEN = "FORBIDDEN",
}

class ErrorObject {
  @Expose()
  @IsNotEmpty()
  @IsEnum(ErrorCode)
  errorCode!: ErrorCode;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  message?: string | null;
}

export class StatusObject {
  public static from(obj: StatusObject) {
    return plainToInstance(StatusObject, obj, {
      excludeExtraneousValues: true,
    });
  }

  @Expose()
  @IsBoolean()
  ok!: boolean;

  @Expose()
  @IsOptional()
  @IsObject()
  error?: ErrorObject | null;
}
