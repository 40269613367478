"use client";

import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { FormFieldFilledEvent } from "@yardzen/segmentv2";
import { InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { cn } from "../../utils";
import { Input } from "../Input";
import "./address-autocomplete.css";

type AddressAutocompleteProps = InputHTMLAttributes<HTMLInputElement> & {
  tracking?: FormFieldFilledEvent;
  waitForTracking?: boolean;
  supressValueTracking?: boolean;
  error?: boolean;
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
  parentInputRef?: React.RefObject<HTMLInputElement | null>;
  onPlaceSelectError?: (error: string) => void;
  placeholder?: string;
  withLocation?: boolean;
  initialValue?: string;
};

const AddressAutocomplete = ({
  placeholder,
  onPlaceSelect,
  onPlaceSelectError,
  className,
  withLocation,
  parentInputRef,
  initialValue,
  ...props
}: AddressAutocompleteProps) => {
  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);

  const internalRef = useRef<HTMLInputElement>(null);
  const inputRef = parentInputRef || internalRef;
  const places = useMapsLibrary("places");
  const geocoder = useMapsLibrary("geocoding");

  const getCurrentLocation = () => {
    if (!geocoder) return;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const geocoderInstance = new geocoder.Geocoder();
          const result = await geocoderInstance.geocode({
            location: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            region: "us",
          });

          if (result.results[0]) {
            const addressComponents = result.results[0].address_components;
            const countryComponent = addressComponents.find((component) =>
              component.types.includes("country"),
            );

            if (countryComponent && countryComponent.short_name === "US") {
              const place: google.maps.places.PlaceResult = {
                geometry: {
                  location: result.results[0].geometry.location,
                },
                formatted_address: result.results[0].formatted_address,
                name: result.results[0].formatted_address,
                place_id: result.results[0].place_id,
                address_components: result.results[0].address_components,
              };
              onPlaceSelect(place);
              if (inputRef.current) {
                inputRef.current.value = result.results[0].formatted_address;
              }
            } else {
              if (onPlaceSelectError) {
                onPlaceSelectError(
                  "Sorry, we don't support addresses outside of the United States yet!",
                );
              }
            }
          }
        },
        (error) => {
          if (!onPlaceSelectError) return;

          switch (error.code) {
            case error.PERMISSION_DENIED:
              onPlaceSelectError(
                "Please enable location access in your device settings to use this feature",
              );
              break;
            case error.POSITION_UNAVAILABLE:
              onPlaceSelectError(
                "Location information is unavailable. Please try again or enter your address manually.",
              );
              break;
            case error.TIMEOUT:
              onPlaceSelectError(
                "Unable to get your location. Please try again or enter your address manually.",
              );
              break;
            default:
              onPlaceSelectError(
                "An unknown error occurred. Please try again or enter your address manually.",
              );
              break;
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        },
      );
    }

    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options: google.maps.places.AutocompleteOptions = {
      fields: [
        "geometry.location",
        "name",
        "formatted_address",
        "place_id",
        "address_components",
      ],
      componentRestrictions: { country: "us" },
      types: ["address"],
    };

    const autocomplete = new places.Autocomplete(inputRef.current, options);
    setPlaceAutocomplete(autocomplete);
  }, [places]);

  useEffect(() => {
    if (
      !withLocation ||
      !placeAutocomplete ||
      !inputRef.current ||
      !navigator.geolocation
    )
      return;

    const handleInputChange = () => {
      const inputVal = inputRef.current?.value;
      if (inputVal && inputVal.trim() !== "") {
        const pacContainers = document.querySelectorAll(".pac-container");
        pacContainers.forEach((pacContainer) => {
          if (
            pacContainer &&
            !pacContainer.querySelector(".custom-location-item")
          ) {
            const locationButton = document.createElement("div");
            locationButton.className = "pac-item custom-location-item";
            locationButton.innerHTML = `
              <span class="pac-arrow-icon"></span>
              <span class="pac-arrow-item">Use current location</span>
            `;
            // Prevent event propagation to avoid autocomplete handling
            locationButton.addEventListener("mousedown", (e) => {
              e.preventDefault();
              e.stopPropagation();
              getCurrentLocation();
            });
            pacContainer.insertBefore(locationButton, pacContainer.firstChild);
          }
        });
      }
    };

    inputRef.current.addEventListener("input", handleInputChange);

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener("input", handleInputChange);
      }
    };
  }, [placeAutocomplete, geocoder, withLocation]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      const place = placeAutocomplete.getPlace();
      onPlaceSelect(place);
    });
  }, [onPlaceSelect, placeAutocomplete]);

  useEffect(() => {
    if (!geocoder || !initialValue) return;

    // Only geocode if value is different from current input value
    if (inputRef.current) {
      const geocoderInstance = new geocoder.Geocoder();
      geocoderInstance.geocode({ address: initialValue }).then((result) => {
        if (result.results[0]) {
          onPlaceSelect(result.results[0]);
        }
      });
    }
  }, [initialValue]);

  return (
    <Input
      className={cn(
        "bg-brand-secondary-lighter w-full rounded-md p-4",
        className,
      )}
      placeholder={placeholder}
      ref={inputRef}
      defaultValue={initialValue || ""}
      {...props}
    />
  );
};

AddressAutocomplete.displayName = "AddressAutocomplete";

export { AddressAutocomplete };
