import { Expose, plainToInstance } from "class-transformer";
import {
  IsDate,
  IsLatitude,
  IsLongitude,
  IsNotEmpty,
  IsOptional,
  IsString,
} from "class-validator";

export class Address {
  public static from(address: Address) {
    return plainToInstance(Address, address, {
      excludeExtraneousValues: true,
    });
  }

  public static formatAddress = (address: Address) => {
    return `${address.addressLine1}, ${
      address.addressLine2 ? `${address.addressLine2}, ` : ""
    }${address.city}, ${address.state}, ${address.zipcode}`;
  };

  @Expose()
  @IsString()
  @IsNotEmpty()
  id!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  businessName?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  addressLine1!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  addressLine2?: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  city!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  state!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  zipcode!: string;

  @Expose()
  @IsString()
  @IsNotEmpty()
  @IsOptional()
  phone?: string;

  @Expose()
  @IsLongitude()
  @IsNotEmpty()
  longitude!: number;

  @Expose()
  @IsLatitude()
  @IsNotEmpty()
  latitude!: number;

  @Expose()
  @IsDate()
  createdTs!: Date;

  @Expose()
  @IsDate()
  updatedTs!: Date;
}
