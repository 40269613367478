import {
  ContractorProfile,
  FindOneContractorProfileDto,
} from "@yardzen/api-dtos";
import { AxiosResponse } from "axios";
import { apiClient } from "../base-client";

const baseUrl = "contractor-profiles";

export async function getContractorProfile(
  contractorId: string,
  additionalOptions?: FindOneContractorProfileDto,
): Promise<AxiosResponse<ContractorProfile>> {
  const response = await apiClient.get(`${baseUrl}/${contractorId}`, {
    params: additionalOptions,
  });

  return {
    ...response,
    data: ContractorProfile.from(response.data),
  };
}
