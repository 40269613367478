import { IsArray, IsNotEmpty, IsString } from "class-validator";
import { Expose } from "class-transformer";

export class RemoveProjectElementsFromBuildProjectDto {
  @Expose()
  @IsArray()
  @IsString({ each: true })
  @IsNotEmpty({ each: true })
  projectElementIds!: string[];
}
