import React from "react";
import { cn } from "../utils";
import { Card, CardFooter } from "./Card";
import { Skeleton } from "./Skeleton";

interface DesignCardSkeletonProps {
  className?: string;
}

export const DesignCardSkeleton = React.forwardRef<
  HTMLDivElement,
  DesignCardSkeletonProps
>(({ className }, ref) => {
  return (
    <Card ref={ref} className={cn("font-body overflow-hidden", className)}>
      <div className="relative pb-[66.67%]">
        <Skeleton className="absolute inset-0 h-full w-full rounded-none" />
      </div>
      <CardFooter className="flex flex-col gap-2 p-4">
        <div className="mb-4 flex w-full items-center justify-between">
          <Skeleton className="h-4 w-24" />
          <Skeleton className="h-10 w-10 rounded-full" />
        </div>
      </CardFooter>
    </Card>
  );
});

DesignCardSkeleton.displayName = "DesignCardSkeleton";
